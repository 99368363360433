import { Header, HeaderTitle } from '~components/@local-ui/header';
import {
  InformationCard,
  InformationCardTitle
} from '~components/information-card';
import useDashboard from '~features/vet/hooks/queries/use-dashboard';

import { TodayScheduleCard } from '../components/today-schedule-card';

const Home = () => {
  const { data: dashboard } = useDashboard();

  return (
    <main>
      <Header className='border-b border-solid border-gray-200'>
        <HeaderTitle>Home</HeaderTitle>
      </Header>
      <section className='flex flex-col gap-5 p-9'>
        <div className='flex items-center gap-5'>
          <InformationCard className='w-1/2'>
            <InformationCardTitle>Waiting Consultations</InformationCardTitle>
            <div className='px-6 pb-6 pt-3 text-gray-900 text-display-3'>
              {dashboard?.waiting_consultation_count}
            </div>
          </InformationCard>
          <InformationCard className='w-1/2'>
            <InformationCardTitle>
              Live Consultation Requests
            </InformationCardTitle>
            <div className='px-6 pb-6 pt-3 text-gray-900 text-display-3'>
              {dashboard?.live_consultation_count}
            </div>
          </InformationCard>
        </div>

        <TodayScheduleCard
          todayScheduleList={dashboard?.today_scheduled_live_consultations}
        />
      </section>
    </main>
  );
};

export default Home;
