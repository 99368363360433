import Ably from 'ably';
import { useChannel } from 'ably/react';
import { useCallback } from 'react';
import useCaptureError from '~hooks/use-capture-error';
import { MessageType } from '~twilio/hooks/use-ably/types';
import { useAblyMessageHandler } from '~twilio/hooks/use-ably/use-ably-message';

interface AblyEventProviderProps {
  clientId: string;
  children: JSX.Element;
}
const AblyEventProvider = ({
  clientId,
  children
}: Readonly<AblyEventProviderProps>) => {
  const { handleJsonMessage } = useAblyMessageHandler();

  const onMessage = useCallback(
    (message: Ably.Types.Message) => {
      handleJsonMessage(message as MessageType);
    },
    [handleJsonMessage]
  );

  const { connectionError, channelError, channel } = useChannel(
    `private:${clientId}`,
    onMessage
  );
  void channel.presence.enter();
  const {
    connectionError: globalConnectionError,
    channelError: globalChannelError
  } = useChannel(`global:vet`, onMessage);

  useCaptureError(channelError, 'ably');
  useCaptureError(connectionError, 'ably');
  useCaptureError(globalChannelError, 'ably');
  useCaptureError(globalConnectionError, 'ably');

  return children;
};

export default AblyEventProvider;
