import clsx from 'clsx';
import Icon from '~components/@ui/icon';
import useLocalAudioToggle from '~twilio/hooks/use-local-audio-toggle';
import useVideoContext from '~twilio/hooks/use-video-context';

export const ToggleAudioButton = () => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.find((track) => track.kind === 'audio');
  const disabled = !!(isAudioEnabled && hasAudioTrack);
  const iconName = disabled ? 'mic_solid' : 'mic_mute_solid';

  const onPress = () => {
    toggleAudioEnabled();
  };

  return (
    <div
      className={clsx(
        'h-14 w-14 overflow-hidden rounded-full',
        !disabled
          ? 'border-none bg-red-500'
          : 'border border-white bg-transparent'
      )}
    >
      <button
        className='flex h-full w-full items-center justify-center rounded-full'
        aria-label='Toggle Audio'
        onClick={onPress}
      >
        <Icon name={iconName} className='h-6 w-6 fill-white' />
      </button>
    </div>
  );
};
