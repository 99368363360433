import { MutationObserverOptions, useMutation } from '@tanstack/react-query';
import { api } from '~utils/api';

import { toast } from '~components/@ui/toast/controller';
import { captureExceptionWithScope } from '~utils/sentry';
import { UpdateNoShowTime } from '~features/no-shows/types';

interface UpdateNoShowTimesParams {
  updatedTimes: UpdateNoShowTime[];
}

const updateNoShowTimes = async ({
  updatedTimes
}: Readonly<UpdateNoShowTimesParams>) => {
  return (
    await api.patch<string[]>('/no_show_times/', updatedTimes, {
      baseURL: api.defaults.baseURL?.replace('vapp', 'tips')
    })
  ).data;
};

const useUpdateNoShowTimes = (
  options?: MutationObserverOptions<string[], Error, UpdateNoShowTimesParams>
) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNoShowTimes,
    onSuccess: (res, variables, context) => {
      options?.onSuccess?.(res, variables, context);
      // queryClient.setQueryData(liveConsultationKeys.availableTimes(), res);
    },
    onError: (err, variables, context) => {
      options?.onError?.(err, variables, context);
      toast.open({
        message: 'Failed to update no show times',
        type: 'error'
      });
      captureExceptionWithScope({
        error: err,
        tag: 'updateNoShowTimes'
      });
    },
    ...options
  });
};

export default useUpdateNoShowTimes;
