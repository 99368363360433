import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Header,
  HeaderStatusSwitch,
  HeaderTitle
} from '~components/@local-ui/header';
import { TabItem } from '~components/@ui/tab/tab-bar/types';
import { notificationBadgeAtom } from '~features/app/atoms/notification';
import { useLiveConsultationTickets } from '~features/live-consultation/hooks/queries/use-live-consultation-tickets';
import { TicketStatus } from '~features/live-consultation/types';

import { ConsultationHistoryTab } from './consultation_history';
import { ConsultationRequestTab } from './consultation_requests';
import { ScheduleTab } from './schedule';
import AvailabilitySettingsTab from './availability_settings';
import PrivateInvitationSettingsTab from './private_invitation_settings';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';

const enum Tab {
  ConsultationRequests = 'consultation_requests',
  Schedule = 'schedule',
  ConsultationHistory = 'consultation_history',
  AvailabilitySettings = 'availability_settings',
  PrivateInvitationSettings = 'private_invitation_settings'
}

const Pages = () => {
  const [searchParams] = useSearchParams();
  const tabParams = searchParams.get('tab') as Tab;
  const { data: lcts, refetch } = useLiveConsultationTickets();
  useEffect(() => {
    if (tabParams === Tab.ConsultationRequests) {
      // TODO: socketMessage VET_BOOK_LCQ_PUSH 추가되면 제거 예정
      void refetch();
    }
  }, [refetch, tabParams]);

  const consultation_requests = useMemo(
    () => lcts?.filter((item) => item.ticket_status !== TicketStatus.Booked),
    [lcts]
  );

  switch (tabParams) {
    case Tab.ConsultationHistory:
      return <ConsultationHistoryTab />;
    case Tab.Schedule:
      return <ScheduleTab />;
    case Tab.AvailabilitySettings:
      return <AvailabilitySettingsTab />;
    case Tab.PrivateInvitationSettings:
      return <PrivateInvitationSettingsTab />;
    default:
      return <ConsultationRequestTab waitList={consultation_requests} />;
  }
};

const LiveConsultation = () => {
  const { data: myProfile } = useMyProfile();
  const { live_consultation_request: isLCIndicatorActive } = useAtomValue(
    notificationBadgeAtom
  );

  const tabs: TabItem[] = useMemo(
    () =>
      myProfile?.vet.role === 20
        ? [
            {
              name: 'Consultation Requests',
              showNotification: isLCIndicatorActive
            },
            {
              name: 'Schedule'
            },
            {
              name: 'Consultation History'
            },
            {
              name: 'Availability Settings'
            },
            {
              name: 'Private Invitation Settings'
            }
          ]
        : [
            {
              name: 'Consultation Requests',
              showNotification: isLCIndicatorActive
            },
            {
              name: 'Schedule'
            },
            {
              name: 'Consultation History'
            },
            {
              name: 'Availability Settings'
            }
          ],
    [isLCIndicatorActive, myProfile?.vet.role]
  );

  return (
    <div className='min-h-screen w-full'>
      <Header tabs={tabs}>
        <HeaderTitle>Live Consultation</HeaderTitle>
        <HeaderStatusSwitch />
      </Header>
      <div className='flex flex-row p-9'>
        <Pages />
      </div>
    </div>
  );
};

export default LiveConsultation;
