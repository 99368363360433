import { useEffect, useRef } from 'react';
import { IVideoTrack } from 'src/types';
import { Track } from 'twilio-video';
import useMediaStreamTrack from '~twilio/hooks/use-media-stream-track';
import useVideoTrackDimensions from '~twilio/hooks/use-video-track-dimensions';

interface Props {
  track: IVideoTrack;
  isLocal?: boolean;
  paused?: boolean;
  priority?: Track.Priority | null;
}

export const VideoTrack = ({
  track,
  isLocal,
  priority,
  paused
}: Readonly<Props>) => {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) >= (dimensions?.width ?? 0);

  useEffect(() => {
    const videoElement = ref.current;
    if (paused && !videoElement.paused) {
      videoElement.pause();
    }

    if (!paused && videoElement.paused) {
      void videoElement.play();
    }
  }, [paused]);

  useEffect(() => {
    const videoElement = ref.current;
    videoElement.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(videoElement);
    return () => {
      track.detach(videoElement);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      videoElement.srcObject = null;

      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = {
    // scaleX(-1) is used to mirror the video element.
    transform: isLocal && isFrontFacing ? 'scaleX(-1)' : '',
    objectFit:
      isPortrait || track.name.includes('screen')
        ? ('contain' as const)
        : ('cover' as const)
  };

  return (
    <video className='h-full w-full' ref={ref} style={style}>
      <track kind='captions' />
    </video>
  );
};
