import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { api } from '~utils/api';

import { liveConsultationKeys } from './query-keys';

const fetchLiveConsultationHistoryDetail = async (id: number) =>
  (await api.get<LiveConsultation.Level2>(`/lcs/${id}/`)).data;

const useLiveConsultationDetail = (id: number) => {
  return useQuery<LiveConsultation.Level2, AxiosError>({
    queryKey: liveConsultationKeys.historyDetail(id),
    queryFn: async () => await fetchLiveConsultationHistoryDetail(id),
    enabled: !!id && id > 0
  });
};

export default useLiveConsultationDetail;
