import { useEffect, useRef } from 'react';
import { AudioTrack as IAudioTrack } from 'twilio-video';
import { useTwilioRoomState } from '~twilio/state';

interface AudioTrackProps {
  track: IAudioTrack;
}

export const AudioTrack = ({ track }: AudioTrackProps) => {
  const { activeSinkId } = useTwilioRoomState();
  const audioElement = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioElement.current = track.attach();
    audioElement.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioElement.current);
    return () => {
      track.detach().forEach((mediaElement) => {
        mediaElement.remove();

        // eslint-disable-next-line no-param-reassign
        mediaElement.srcObject = null;
      });
    };
  }, [track]);

  useEffect(() => {
    void audioElement.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return null;
};
