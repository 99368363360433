import dayjs from 'dayjs';

type TimeFormat = Readonly<
  'default' | 'short' | 'absoluteLong' | 'absoluteShort' | 'absolute'
>;

/**
 * @see https://www.notion.so/drtail/b9018d2f8c484837864f3b9888eff05c
 * @param date ex) "2023-11-01T04:18:00.695597Z"
 * @param format 'default' | 'short | 'long' | 'absolute' | 'absoluteLong' | 'absoluteShort' |
 * @returns ex) '1 day ago' | '1d' | '06/27/2022, 08:35:01' | 'Wed, Nov 01, 2023' | 'Fri, Nov 24, 02:46' | 'Nov 24'
 */
export const getDateFormat = (date: string, format: TimeFormat = 'default') => {
  const dateObject = new Date(date);
  const now = new Date();

  if (!date.length || dateObject > now) {
    return ''; // date가 없거나, 현재 날짜보다 미래의 날짜가 삽입되었을 때에 대한 방어코드
  }

  const diffInMilliseconds = now.getTime() - dateObject.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'always' });

  const timeFormats = {
    default: {
      now: 'Now',
      minute: rtf.format(-diffInMinutes, 'minute'),
      hour: rtf.format(-diffInHours, 'hour'),
      day: rtf.format(-diffInDays, 'day'),
      week: dateObject.toLocaleDateString('en', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    },
    short: {
      now: 'Now',
      minute: `${diffInMinutes}m`,
      hour: `${diffInHours}h`,
      day: `${diffInDays}d`,
      week: `${Math.floor(diffInDays / 7)}w`
    },
    absolute: new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }).format(dateObject),
    absoluteLong: new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }).format(dateObject),
    absoluteShort: new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit'
    }).format(dateObject)
  };

  if (
    format === 'absolute' ||
    format === 'absoluteLong' ||
    format === 'absoluteShort'
  ) {
    return timeFormats[format];
  }

  switch (true) {
    case diffInMinutes < 1: // 1분 미만
      return timeFormats[format].now;
    case diffInMinutes < 60: // 1분 ~ 59분
      return timeFormats[format].minute;
    case diffInHours < 24: // 1시간 ~ 23시간
      return timeFormats[format].hour;
    case diffInDays >= 1 && diffInDays < 7: // 1일 ~ 6일
      return timeFormats[format].day;
    default: // 7일 ~
      return timeFormats[format].week;
  }
};

export const getDateTimeDiff = (date: Date): string => {
  const years = dayjs().diff(date, 'year');
  const months = dayjs().diff(date, 'month');
  const days = dayjs().diff(date, 'day');
  const hours = dayjs().diff(date, 'hours');
  const minutes = dayjs().diff(date, 'minutes');
  const seconds = dayjs().diff(date, 'seconds');

  if (years >= 1) {
    return `${years} ${years > 1 ? 'years' : 'year'}`;
  }
  if (months >= 1) {
    return `${months} ${months > 1 ? 'months' : 'month'}`;
  }
  if (days >= 1) {
    return `${days} ${days > 1 ? 'days' : 'day'}`;
  }
  if (hours >= 1) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  }
  if (minutes >= 1) {
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
  }
  return `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`;
};

export const getLocalTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
