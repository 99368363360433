import { useEffect } from 'react';

type Props = Readonly<{
  callback?: () => void;
}>;

const useEscape = ({ callback }: Props) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback?.();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]);
};

export default useEscape;
