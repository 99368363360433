import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import App from '~features/app/models';
import { api } from '~utils/api';

import { appKeys } from './query-keys';

enum InboxMessageType {
  Consultation = 'consultation',
  LiveConsultation = 'live_consultation'
}

type InboxMessageData = Readonly<{
  icon: App.Message.Icon;
  deeplink: string;
}>;

/**
 * @todo 애즈먼 Push API들 레거시 정리되면 Message 스킴 싱크 맞춰야 함
 */
export type InboxMessage = Readonly<{
  id: number;
  message_type: InboxMessageType;
  title: string;
  created_at: string;
  data: InboxMessageData;
  content?: string;
}>;

const fetchInbox = async (params: PaginationParams) => {
  return (
    await api.get<InfiniteScroll<InboxMessage>>('/vet/inbox/', {
      params
    })
  ).data;
};

const useInbox = () => {
  return useSuspenseInfiniteQuery<InfiniteScroll<InboxMessage>, Error>({
    queryKey: appKeys.inbox(),
    queryFn: async ({ pageParam }) =>
      await fetchInbox({
        cursor:
          typeof pageParam === 'string' && pageParam.length
            ? decodeURIComponent(pageParam)
            : pageParam
      }),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) =>
      lastPage.next ? lastPage.next.split('cursor=')[1].split('&')[0] : null
  });
};

export default useInbox;
