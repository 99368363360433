import { snake } from 'radash';
import { useSearchParams } from 'react-router-dom';

import { TabItem } from './types';

export const TAB = 'tab';

export const useTabBarIndex = (items: TabItem[]) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(TAB);
  const defaultTabIndex = tab
    ? items.findIndex(({ name }) => {
        return snake(name) === tab;
      })
    : 0;
  return {
    defaultTabIndex
  };
};
