/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react';
import { getDeviceInfo } from '~utils/twilio';

// This returns the type of the value that is returned by a promise resolution
type ThenArg<T> = T extends PromiseLike<infer U> ? U : never;

export default function useDevices() {
  const [deviceInfo, setDeviceInfo] = useState<
    ThenArg<ReturnType<typeof getDeviceInfo>>
  >({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false
  });

  useEffect(() => {
    const getDevices = async () => {
      const devices = await getDeviceInfo();
      setDeviceInfo(devices);
    };
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    void getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  return deviceInfo;
}
