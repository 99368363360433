import { produce } from 'immer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { liveConsultationKeys } from '~features/live-consultation/hooks/queries/query-keys';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { api } from '~utils/api';

const denyLiveConsultationTicket = async (ticketId: number) => {
  return await api.post<null>(`/lcts/${ticketId}/deny/`);
};

export const useDeleteLiveConsultationTicket = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ ticketId }: { ticketId: number }) =>
      await denyLiveConsultationTicket(ticketId),
    onSuccess: (_, req) => {
      void queryClient.setQueryData<LiveConsultationTicket[]>(
        liveConsultationKeys.tickets(),
        produce((draft) => draft?.filter((item) => item.id !== req.ticketId))
      );
    },
    onError: (_, req) => {
      void queryClient.setQueryData<LiveConsultationTicket[]>(
        liveConsultationKeys.tickets(),
        produce((draft) => draft?.filter((item) => item.id !== req.ticketId))
      );
    }
  });
};
