import dayjs from 'dayjs';

/**
 * @TODO 노션 시간 표기정책(https://www.notion.so/drtail/b9018d2f8c484837864f3b9888eff05c?pvs=4) 문서에 포맷들이 정리되면 utils/date.ts에 있는 getDateFormat 함수로 대체 예정
 * @param ex) "2023-11-01T04:18:00.695597Z"
 * @param format  'short' | 'long' | 'longWithTime' | 'full'
 * @returns ex) '04:18' | 'Nov 01, 2023' | 'Wed, Nov 01, 04:18' | '01/11/2023, 04:18'
 *  */
type TimeFormat = Readonly<'short' | 'long' | 'longWithTime' | 'full'>;
export const getDayjsFormat = (
  date: Date | null,
  format: TimeFormat = 'long'
) => {
  switch (format) {
    case 'short':
      return dayjs(date).format('HH:mm');
    case 'long':
      return dayjs(date).format('MMM DD, YYYY');
    case 'longWithTime':
      return dayjs(date).format('ddd, MMM DD, HH:mm');
    case 'full':
      return dayjs(date).format('DD/MM/YYYY, HH:mm');
    default:
      return dayjs(date).format('MMM DD, YYYY');
  }
};

export const getReservationEndTime = (date: Date) => {
  return dayjs(date).add(15, 'minute').format('HH:mm');
};
