import { ReactNode } from 'react';

interface LocalVideoInfoProps {
  children: ReactNode;
}

export const LocalVideoInfo = ({ children }: Readonly<LocalVideoInfoProps>) => {
  return (
    <div className='relative isolate z-0 flex h-full w-full items-center justify-center bg-black'>
      {children}
    </div>
  );
};
