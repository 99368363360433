import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { api } from '~utils/api';

import { liveConsultationKeys, LiveConsultationParams } from './query-keys';

const fetchBookedLiveConsultations = async (params: LiveConsultationParams) =>
  (
    await api.get<Pagination<LiveConsultationTicket>>(`/vet/lcts/booked/`, {
      params
    })
  ).data;

const useBookedLiveConsultations = (params: LiveConsultationParams) => {
  return useQuery<Pagination<LiveConsultationTicket>, AxiosError>({
    queryKey: liveConsultationKeys.scheduledTicketsWithParams(params),
    queryFn: async () => await fetchBookedLiveConsultations(params),
    enabled: !!params.page
  });
};

export default useBookedLiveConsultations;
