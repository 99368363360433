import { ButtonHTMLAttributes, useMemo, useState } from 'react';
import { cn } from '~utils/tailwind';

export interface InnerTabBarItemProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  index: number;
  label: string;
  isSelected?: boolean;
}
const InnerTabBarItem = ({
  index,
  label,
  isSelected,
  ...buttonAttrs
}: Readonly<InnerTabBarItemProps>) => {
  return (
    <button
      className={cn(
        'flex items-center justify-center p-4',
        isSelected
          ? 'border-b-[1px] border-white bg-white'
          : 'border-b-[1px] border-gray-300',
        index > 0 && 'border-l-[1px] border-l-gray-300'
      )}
      {...buttonAttrs}
    >
      <span
        className={cn(
          'text-title-3',
          isSelected ? 'text-gray-900' : 'text-gray-400'
        )}
      >
        {label}
      </span>
    </button>
  );
};

export type InnerTabItemType<T> = { item: T; index: number };

interface InnerTabBarProps<T> {
  data: Record<string | number | symbol, T>;
  renderItem: (props: Readonly<InnerTabItemType<T>>) => React.ReactNode;
  labelExtractor?: (key: string) => string;
  footer?: React.ReactNode;
}
const InnerTabBar = <T,>({
  labelExtractor,
  data,
  renderItem,
  footer
}: InnerTabBarProps<T>) => {
  const [focusIndex, setFocusIndex] = useState(0);
  const dataKeys = useMemo(() => Object.keys(data), [data]);
  const currentItem = useMemo(
    () => data[dataKeys[focusIndex]],
    [data, dataKeys, focusIndex]
  );
  const labels = useMemo(() => {
    return labelExtractor ? dataKeys.map(labelExtractor) : dataKeys;
  }, [dataKeys, labelExtractor]);
  const Slot = useMemo(() => {
    return () => renderItem({ item: currentItem, index: focusIndex });
  }, [currentItem, focusIndex, renderItem]);

  const onTabBarItemClick = (index: number) => {
    setFocusIndex(index);
  };

  if (!currentItem) {
    return null;
  }

  return (
    <div className='flex w-full flex-col'>
      <div className='z-10 mb-[-1px] flex flex-row self-start overflow-hidden rounded-tl-[16px] rounded-tr-[16px] border-[1px] border-b-0 border-gray-300 bg-gray-50'>
        {labels.map((label, index) => (
          <InnerTabBarItem
            key={dataKeys[index]}
            index={index}
            label={label}
            isSelected={focusIndex === index}
            onClick={() => {
              onTabBarItemClick(index);
            }}
          />
        ))}
      </div>
      <div className='border-tr-[16px] z-0 flex w-full flex-col gap-y-8 rounded-bl-[16px] rounded-br-[16px] rounded-tr-[16px] border-[1px] border-b-gray-300 border-l-gray-300 border-r-gray-300 p-6'>
        <Slot />
        {footer}
      </div>
    </div>
  );
};

export default InnerTabBar;
