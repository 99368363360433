import { ComponentProps, memo } from 'react';
import { cn } from '~utils/tailwind';

type PickedButtonProps = Pick<ComponentProps<'button'>, 'children' | 'onClick'>;

type RadioChipProps = Readonly<
  PickedButtonProps & {
    isSelected?: boolean;
  }
>;

/**
 * @version v1.0.0
 * @author Danny
 */
const RadioChip = ({ children, isSelected, onClick }: RadioChipProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'rounded-xl border border-solid outline-primary-500',
        'px-4 py-2',
        'text-title-3',
        {
          'border-gray-900 bg-gray-900 text-white': isSelected,
          'border-gray-500 bg-white text-gray-500': !isSelected
        }
      )}
    >
      {children}
    </button>
  );
};

export default memo(RadioChip);
