import { AxiosError, isAxiosError } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { toast } from '~components/@ui/toast/controller';

const handleRetryQueries = (_: any, error: Error) => {
  if (!isAxiosError(error)) {
    return true;
  }

  if (error.response?.status === 404) {
    return false;
  }

  return true;
};

const throwError = (err: Error) => {
  if (err instanceof AxiosError) {
    const errorMessage = err?.response?.data?.detail;
    const defaultErrorMessage =
      'Something went wrong temporarily. Please try again in a few second.';

    toast.open({
      message: errorMessage || defaultErrorMessage,
      type: 'caution'
    });
  }
};

const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: handleRetryQueries
      },
      mutations: {
        onError: throwError
      }
    }
  });
};

export default getQueryClient;
