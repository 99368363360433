import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Header, HeaderTitle } from '~components/@local-ui/header';
import Pagination from '~components/@local-ui/pagination';
import SideSheet from '~components/@local-ui/side-sheet/component';
import { SIDE_SHEET_PAGE_ID } from '~components/@local-ui/side-sheet/constant';
import { useSideSheet } from '~components/@local-ui/side-sheet/hook';
import Dialog from '~components/@ui/dialog';
import TextButton from '~components/@ui/dialog/text-button';
import { Label } from '~components/@ui/label/component';
import Spinner from '~components/@ui/loading-indicator/spinner';
import { TabItem } from '~components/@ui/tab/tab-bar/types';
import AsyncBoundary from '~components/async-boundary';
import ErrorFallback from '~components/error-fallback';
import Table from '~components/table';
import ConsultationDetail from '~features/consultation/components/consultation-detail';
import useAIReference from '~features/consultation/hooks/queries/use-ai-reference';
import useConsultationDetail from '~features/consultation/hooks/queries/use-consultation-detail';
import useConsultations from '~features/consultation/hooks/queries/use-consultations';
import useMyConsultations from '~features/consultation/hooks/queries/use-my-consultations';
import useOwnersConsultations from '~features/consultation/hooks/queries/use-owners-consultations';
import Consultation from '~features/consultation/models';
import { getGender } from '~features/pet/utils';
import usePagination from '~hooks/use-pagination';
import { labelColor } from '~utils/consultation';
import { getDateFormat } from '~utils/date';
import { cn } from '~utils/tailwind';

const MY_CONSULTATION_QUERY_TYPE = 'my_consultations';

const TABS = [
  { name: 'All Consultations' },
  { name: 'My Consultations' }
] as TabItem[];

const QUESTION_DIALOG_HEADER_LIST = [
  'ISSUE',
  'CONCERN',
  'SPECIES/BREED',
  'DATE'
];

const QUESTION_HEADER_LIST = [
  'ISSUE DETAIL',
  'CONCERN',
  'STATUS',
  'SPECIES/BREED',
  'AGE',
  'GENDER',
  'DATE'
];

interface AIAnswerDialogProps {
  content: string;
  open: boolean;
  onClose: () => void;
}

const AIAnswerDialog = ({ open, onClose, content }: AIAnswerDialogProps) => {
  const copyContentText = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <Dialog open={open} onClose={onClose} type='prompt' className='lg:p-0'>
      <Dialog.CloseIcon onClose={onClose} />
      <Dialog.Title>AI answers</Dialog.Title>
      <Dialog.Content>{content}</Dialog.Content>
      <Dialog.Action>
        <Dialog.TextButton onClick={onClose}>Close</Dialog.TextButton>
        <Dialog.Button
          onClick={() => {
            void copyContentText(content);
          }}
        >
          Copy to clipboard
        </Dialog.Button>
      </Dialog.Action>
    </Dialog>
  );
};

interface OtherQuestionsDialogProps {
  ownerId: number;
  open: boolean;
  onClose: () => void;
}

const OwnerConsultationsDialog = ({
  ownerId,
  open,
  onClose
}: OtherQuestionsDialogProps) => {
  const { data: ownerConsultations } = useOwnersConsultations(ownerId);
  const [selectedConsultationId, setSelectedConsultationId] = useState<
    number | null
  >(null);

  return (
    <Dialog
      open={open}
      onTransitionEnd={() => {
        setSelectedConsultationId(null);
      }}
      onClose={onClose}
      type='prompt'
      className='p-0 lg:w-[960px] lg:p-0'
    >
      <Dialog.CloseIcon onClose={onClose} />
      <Dialog.Title>Consultations from this owner</Dialog.Title>
      {selectedConsultationId && (
        <div className='border-b border-b-gray-300 px-6'>
          <TextButton
            onClick={() => {
              setSelectedConsultationId(null);
            }}
            trailingIcon='arrow_left'
          >
            Back to list
          </TextButton>
        </div>
      )}
      <Dialog.Content
        className={cn(
          'h-[505px] border-b border-solid border-b-gray-300',
          selectedConsultationId && 'h-[400px] px-0 py-0'
        )}
      >
        {selectedConsultationId ? (
          <div className='h-full w-[960px]'>
            <ConsultationDetail isDialog id={selectedConsultationId} />
          </div>
        ) : (
          <div className='h-full overflow-y-scroll'>
            <Table titleList={QUESTION_DIALOG_HEADER_LIST}>
              {ownerConsultations?.results?.map((data) => (
                <tr
                  onClick={() => {
                    setSelectedConsultationId(data.id);
                  }}
                  key={data.id}
                  className='cursor-pointer border-b border-gray-100'
                >
                  <td className='p-4'>
                    <p className='line-clamp-1 max-w-[360px] whitespace-pre-wrap break-words text-left text-body-1'>
                      {data.details}
                    </p>
                  </td>
                  <td className='p-4 text-body-1'>{data.main_concern}</td>
                  <td className='whitespace-nowrap p-4 text-body-1'>
                    {data.pet?.kind
                      ? `${data.pet.kind?.species}/${data.pet.kind?.breed}`
                      : '-'}
                  </td>
                  <td className='whitespace-nowrap p-4 text-body-1'>
                    {getDateFormat(data.created_at, 'absoluteLong')}
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        )}
      </Dialog.Content>
      <Dialog.Action>
        <Dialog.TextButton onClick={onClose}>Close</Dialog.TextButton>
      </Dialog.Action>
    </Dialog>
  );
};

interface ConsultationsProps {
  onConsultationItemClick: (id: number) => void;
  isMyConsultationTab?: boolean;
}

const Consultations = ({
  onConsultationItemClick,
  isMyConsultationTab
}: ConsultationsProps) => {
  const [searchParams] = useSearchParams();
  const selectedQuestionId = searchParams.get(SIDE_SHEET_PAGE_ID);
  const page = Number(searchParams.get('page')) || 1;
  const { data: consultations } = useConsultations({ page });
  const { data: myConsultations } = useMyConsultations(
    isMyConsultationTab ? page : -1
  );

  const listData = isMyConsultationTab ? myConsultations : consultations;

  const { pages, onPrevClick, onNextClick, onPageNumClick } = usePagination({
    currentPage: listData?.current_page,
    totalPageNum: listData?.total_page_num
  });

  return (
    <div className='min-w-fit rounded-2xl border border-gray-300 p-6'>
      <Table titleList={QUESTION_HEADER_LIST}>
        {listData?.results?.length ? (
          listData?.results?.map((consultation) => (
            <tr
              onClick={() => {
                onConsultationItemClick(consultation.id);
              }}
              key={consultation.id}
              className={cn(
                Number(selectedQuestionId) === consultation.id &&
                  'bg-primary-50',
                'cursor-pointer border-b border-gray-100'
              )}
            >
              <td className='p-4'>
                <div className='line-clamp-2 w-[450px] whitespace-pre-wrap break-words text-left text-body-1'>
                  {consultation.details}
                </div>
              </td>
              <td className='p-4 text-body-1'>{consultation.main_concern}</td>
              <td className='p-4 text-body-1'>
                <Label color={labelColor[consultation.status]}>
                  {consultation.status === Consultation.State.Pending
                    ? 'WAITING'
                    : Consultation.State[consultation.status].toUpperCase()}
                </Label>
              </td>
              <td className='whitespace-nowrap p-4 text-body-1'>
                {consultation.pet?.kind
                  ? `${consultation.pet.kind?.species}/${consultation.pet.kind?.breed}`
                  : '-'}
              </td>
              <td className='whitespace-nowrap p-4 text-body-1'>
                {consultation.pet?.age ? consultation.pet.age : '-'}
              </td>
              <td className='p-4 text-body-1'>
                {getGender(consultation.pet.gender)}
              </td>
              <td className='whitespace-nowrap p-4 text-body-1'>
                {getDateFormat(consultation.created_at, 'absoluteLong')}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={7}
              className='px-5 py-8 text-center text-gray-700 text-headline-3'
            >
              No consultations
            </td>
          </tr>
        )}
        {}
      </Table>

      <Pagination
        currentPageNum={page || 0}
        totalPageNum={listData?.total_page_num || 0}
        pageNums={pages}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        onPageNumClick={onPageNumClick}
      />
    </div>
  );
};

const ConsultationsLoading = () => {
  return (
    <div className='flex items-center justify-center px-5 py-20'>
      <Spinner />
    </div>
  );
};

const ConsultationPage = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const isMyConsultationTab = tab && tab === MY_CONSULTATION_QUERY_TYPE;
  const selectedConsultationId = searchParams.get(SIDE_SHEET_PAGE_ID);
  const [otherConsultationsDialogOpen, setOtherConsultationsDialogOpen] =
    useState(false);
  const [isAIAnswerDialogOpen, setAIAnswerDialogOpen] = useState(false);

  const { data } = useConsultationDetail(Number(selectedConsultationId) || -1);

  const { data: aiReference } = useAIReference(
    Number(selectedConsultationId || -1)
  );

  const { openSideSheet, sideSheetProps } = useSideSheet();

  const onConsultationItemClick = (id: number) => {
    openSideSheet(id);
  };

  const onOtherQuestionsView = () => {
    setOtherConsultationsDialogOpen(true);
  };

  const onAIAnswerRequest = () => {
    setAIAnswerDialogOpen(true);
  };

  return (
    <>
      {data?.owner.id && (
        <OwnerConsultationsDialog
          ownerId={data?.owner.id}
          open={otherConsultationsDialogOpen}
          onClose={() => {
            setOtherConsultationsDialogOpen(false);
          }}
        />
      )}
      {aiReference?.content && (
        <AIAnswerDialog
          content={aiReference?.content}
          open={isAIAnswerDialogOpen}
          onClose={() => {
            setAIAnswerDialogOpen(false);
          }}
        />
      )}

      <div className='min-h-screen w-full'>
        <Header tabs={TABS}>
          <HeaderTitle>Consultation</HeaderTitle>
        </Header>
        <main className='m-9'>
          <div className='mb-5 text-headline-2'>
            {isMyConsultationTab ? 'My Consultations' : 'All Consultations'}
          </div>
          <AsyncBoundary
            pendingFallback={<ConsultationsLoading />}
            rejectedFallback={ErrorFallback}
          >
            <Consultations
              onConsultationItemClick={onConsultationItemClick}
              isMyConsultationTab={!!isMyConsultationTab}
            />
          </AsyncBoundary>
        </main>
      </div>

      <SideSheet {...sideSheetProps}>
        <AsyncBoundary
          pendingFallback={<ConsultationsLoading />}
          rejectedFallback={ErrorFallback}
        >
          <ConsultationDetail
            id={Number(selectedConsultationId) || -1}
            isExpanded={sideSheetProps.isExpanded}
            otherQuestionsViewClick={onOtherQuestionsView}
            onAIAnswerRequest={
              aiReference?.content ? onAIAnswerRequest : undefined
            }
          />
        </AsyncBoundary>
      </SideSheet>
    </>
  );
};

export default ConsultationPage;
