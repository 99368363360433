import { cn } from '~utils/tailwind';

import { buttonStyle } from './styles';
import { ButtonProps } from './types';

/**
 * @version v1.1.0
 * @author Danny
 * @description tertiary 버튼 텍스트 색상 변경
 */
const Button = ({ children, priority, size, ...props }: ButtonProps) => {
  const { className, type = 'button' } = props;

  return (
    <button
      {...props}
      type={type}
      className={cn(buttonStyle({ priority, size }), className)}
    >
      {children}
    </button>
  );
};

export default Button;
