export type ConsultationParams = {
  page: number;
};

export const consultationKeys = {
  all: ['consultation'],

  consultations: (params: ConsultationParams) => [
    ...consultationKeys.all,
    'list',
    params
  ],
  myConsultations: (page: number) => [
    ...consultationKeys.all,
    'my-list',
    'page',
    page
  ],
  ownerConsultations: (id: number) => [
    ...consultationKeys.all,
    'owner-list',
    id
  ],
  consultationDetail: (id: number) => [...consultationKeys.all, 'detail', id],
  aiReference: (id: number) => [
    ...consultationKeys.consultationDetail(id),
    'ai-reference'
  ]
} as const;
