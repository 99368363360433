import { useNavigate } from 'react-router-dom';
import Button from '~components/@ui/button/button/component';
import Icon from '~components/@ui/icon';
import { useLiveConsultation } from '~features/live-consultation/hooks/queries/use-live-consultation';
import { useSpentTime } from '~twilio/hooks/use-spent-time';

const OngoingSession = () => {
  const navigate = useNavigate();
  const { data: lc } = useLiveConsultation();
  const { spentTime } = useSpentTime();
  if (!lc?.room_access_token) {
    return null;
  }
  return (
    <div className='flex flex-row items-center justify-center gap-10 rounded-2xl bg-gray-800 p-5'>
      <div className='flex flex-row gap-5'>
        <div className='relative flex h-10 w-10 items-center justify-center rounded-xl bg-gray-900 p-2'>
          <div className='absolute left-0 top-0 rounded-full'>
            <div className='h-2 w-2 rounded-full bg-green-500' />
          </div>
          <Icon name='camera' className='fill-white' />
        </div>
        <div className='flex flex-col '>
          <p className='text-gray-300 text-label-1'>ONGOING SESSION</p>
          <p className='text-white text-title-2'>{spentTime}</p>
        </div>
      </div>
      <Button
        priority='primary'
        size='medium'
        onClick={() => {
          navigate('/live-consultation/video-chat');
        }}
      >
        Enter chat
      </Button>
    </div>
  );
};

export default OngoingSession;
