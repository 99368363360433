import dayjs from 'dayjs';

export const formatLiveConsultationDuration = (
  startedAt?: Date,
  closedAt?: Date
) => {
  if (!startedAt || !closedAt) {
    return '-';
  }
  return `${dayjs(startedAt).format('DD/MM/YYYY, HH:mm')}~${dayjs(
    closedAt
  ).format('HH:mm')}`;
};
