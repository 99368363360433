import { useRegisterSW } from 'virtual:pwa-register/react';
import Button from '~components/@ui/button/button/component';
import Icon from '~components/@ui/icon';

/**
 * @see https://vite-pwa-org.netlify.app/guide/prompt-for-update.html
 * @see https://anilsonix.com/posts/adding-pwa-update-prompt-in-vite-react/
 */
const AppUpdatePrompt = () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW();

  const close = () => {
    setNeedRefresh(false);
    document.location.reload();
  };

  if (!needRefresh) {
    return null;
  }

  return (
    <div className='absolute bottom-4 right-4 rounded-lg bg-white p-4 shadow-elevation-1'>
      <button
        aria-label='close-button'
        onClick={close}
        className='absolute right-1 top-1 p-2'
      >
        <Icon name='close' className='h-4 w-4' />
      </button>
      <p className='whitespace-pre-line pt-2 text-gray-900 text-title-3'>
        {` A new version is available.\nPlease reload the app to continue using it.`}
      </p>
      <div className='mt-3 flex items-center gap-2'>
        <Button
          onClick={() => {
            void updateServiceWorker();
          }}
          size='small'
          className='w-full'
          priority='tertiary'
        >
          Reload
        </Button>
      </div>
    </div>
  );
};

export default AppUpdatePrompt;
