import { cva, VariantProps } from 'class-variance-authority';
import { HTMLAttributes, ReactNode } from 'react';
import { cn } from '~utils/tailwind';

interface OverlayProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof overlayStyle> {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
}

const overlayStyle = cva('transition-all motion-reduce:transition-none', {
  variants: {
    open: {
      true: 'fixed inset-0 z-dimmed flex h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900/60',
      false: 'bg-gray-900/0'
    }
  }
});

/**
 * Modal 타입의 Element (Dialog, SideBar 등)를 만들 때 사용합니다.
 */
const Overlay = ({
  children,
  open = false,
  onClose,
  ...props
}: Readonly<OverlayProps>) => {
  return (
    <div
      {...props}
      tabIndex={-1}
      aria-hidden='true'
      className={cn(
        overlayStyle({ open }),
        onClose ? 'cursor-pointer' : 'cursor-default',
        props.className
      )}
      onMouseDown={(e) => {
        e.stopPropagation();
        onClose && onClose();
      }}
    >
      {children}
    </div>
  );
};

export default Overlay;
