import { createContext, useContext } from 'react';

export const LayoutContext = createContext({
  isCollapsed: false,
  handleCollapse: () => {}
});

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error(
      'useLayoutContext must be used within a LayoutContextProvider'
    );
  }

  return context;
};
