// 상담 요청(티켓)의 처리 상태로 시간에 따라 값이 변경됨
export enum TicketStatus {
  Waiting, // 대기 상태
  Booking, // 예약 신청 상태
  Booked // 예약 완료 상태
}

// 상담 요청(티켓)의 요청 방식으로 시간이 지나도 값이 변경되지 않음
export enum TicketType {
  General, // 일반 상담
  Quick, // 퀵 상담
  Scheduled // 예약 상담
}
