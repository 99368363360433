import { ComponentProps, useState } from 'react';
import Icon from '~components/@ui/icon';
import Spinner from '~components/@ui/loading-indicator/spinner';
import { getMediaType, getVideoInfo } from '~utils/media';
import { cn } from '~utils/tailwind';
import { MediaSize, MediaSizeCss } from './types';

const MEDIA_SIZE_CSS: MediaSizeCss = {
  '48': 'h-12 w-12',
  '64': 'h-16 w-16',
  '96': 'h-24 w-24'
};

type MediaProps = Readonly<
  ComponentProps<'figure'> & {
    url: string;
    alt: string;
    size?: MediaSize;
    shape?: 'square' | 'round';
    onClick?: () => void;
  }
>;

/**
 * @version v1.1.0
 * @author Maggie
 */
const Media = ({
  url,
  alt,
  size = '96',
  shape = 'round',
  className,
  onClick
}: MediaProps) => {
  const [loading, setLoading] = useState(true);

  const type = getMediaType(url);
  const _size = parseInt(size, 10);
  const _url = type === 'video' ? getVideoInfo(url).videoThumbnailUrl : url;

  return (
    <figure
      role='presentation'
      className={cn(
        'relative h-fit w-fit flex-shrink-0 cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'border border-solid',
          'overflow-hidden border border-solid',
          shape === 'round' && 'rounded-lg border-gray-200',
          MEDIA_SIZE_CSS[size]
        )}
      >
        <img
          src={_url}
          width={_size}
          height={_size}
          alt={alt}
          className={cn(
            'h-full w-full',
            type === 'video' && 'brightness-[40%]'
          )}
          style={{
            objectFit: 'cover'
          }}
          loading='lazy'
          onLoad={() => {
            setLoading(false);
          }}
        />
      </div>
      {type === 'video' && (
        <Icon
          name='play'
          className='absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 fill-white'
        />
      )}
      {loading && (
        <div className='absolute left-1/2 top-1/2 z-floated -translate-x-1/2 -translate-y-1/2'>
          <Spinner color='gray' />
        </div>
      )}
    </figure>
  );
};

export default Media;
