import { title as titleCase } from 'radash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, HeaderTitle } from '~components/@local-ui/header';
import Icon from '~components/@ui/icon';
import Spinner from '~components/@ui/loading-indicator/spinner';
import AsyncBoundary from '~components/async-boundary';
import ErrorFallback from '~components/error-fallback';
import useInbox, { InboxMessage } from '~features/app/hooks/queries/use-inbox';
import useInView from '~hooks/use-in-view';
import * as icons from '~icons';
import { getDateFormat } from '~utils/date';

const InboxMessageCard = ({
  message_type,
  title,
  created_at,
  data,
  content
}: InboxMessage) => {
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(data.deeplink);
  };

  return (
    <div
      role='presentation'
      className='flex cursor-pointer gap-5 rounded-2xl border border-solid border-gray-300 bg-white p-5'
      onClick={onNavigate}
    >
      <div className='relative h-fit w-fit rounded-xl bg-gray-100 p-2'>
        <Icon
          name={(data.icon || 'bell') as keyof typeof icons}
          className='h-6 w-6 fill-gray-900'
        />
      </div>
      <div className='flex flex-col gap-2'>
        <h3 className='text-gray-500 text-label-1'>
          {titleCase(message_type).toUpperCase()}
        </h3>
        <div>
          <p className='text-gray-900 text-title-2'>{title}</p>
          {content && <p className='text-gray-700 text-body-1'>{content}</p>}
        </div>
        {created_at && (
          <time className='text-gray-500 text-body-1'>
            {getDateFormat(created_at)}
          </time>
        )}
      </div>
    </div>
  );
};

const InboxLoading = () => {
  return (
    <div className='flex items-center justify-center px-5 py-20'>
      <Spinner />
    </div>
  );
};

const InboxMessages = () => {
  const { ref: pageEndRef, inView } = useInView();
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInbox();

  const inboxMessages = data.pages.flatMap(({ results }) => results);

  useEffect(() => {
    if (inView && hasNextPage) {
      void fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  if (!inboxMessages?.length) {
    return (
      <section className='flex flex-col items-center gap-5 px-5 py-8'>
        <Icon name='sleeping_bell' className='h-32 w-32' />
        <h3 className='text-gray-700 text-headline-3'>No new notification</h3>
      </section>
    );
  }

  return (
    <>
      <section className='flex flex-col gap-4'>
        {inboxMessages.map((message) => (
          <InboxMessageCard key={message.id} {...message} />
        ))}
      </section>
      {isFetchingNextPage && (
        <div className='mt-4 flex justify-center'>
          <Spinner />
        </div>
      )}
      <div ref={pageEndRef} />
    </>
  );
};

const Inbox = () => {
  return (
    <div>
      <Header className='border-b border-solid border-gray-200'>
        <HeaderTitle>Inbox</HeaderTitle>
      </Header>
      <main className='w-full p-9'>
        <AsyncBoundary
          pendingFallback={<InboxLoading />}
          rejectedFallback={ErrorFallback}
        >
          <InboxMessages />
        </AsyncBoundary>
      </main>
    </div>
  );
};

export default Inbox;
