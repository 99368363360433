import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { ErrorCallback } from 'src/types';
import {
  ConnectOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room
} from 'twilio-video';

import AttachVisibilityHandler from './attach-visibility-handler';
import useHandleRoomDisconnection from './use-handle-room-disconnection';
import useHandleTrackPublicationFailed from './use-handle-track-publication-failed';
import useLocalTracks from './use-local-tracks';
import useRestartAudioTrackOnDeviceChange from './use-restart-audio-track-on-device-change';
import useRoom from './use-room';
import useScreenShareToggle from './use-screen-share-toggle';
import { SelectedParticipantProvider } from './use-selected-participant';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  localTracks: Array<LocalAudioTrack | LocalVideoTrack>;
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
  isBackgroundSelectionOpen: boolean;
  setIsBackgroundSelectionOpen: (value: boolean) => void;
}

export const VideoContext = createContext<IVideoContext | null>(null);

interface VideoProviderProps {
  children: ReactNode;
  onError?: ErrorCallback;
  options?: ConnectOptions;
}

export const VideoProvider = ({
  options,
  children,
  onError = () => {}
}: VideoProviderProps) => {
  const onErrorCallback: ErrorCallback = useCallback(onError, [onError]);

  const {
    localTracks,
    getLocalVideoTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks
  } = useLocalTracks();

  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError
  );

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] =
    useState(false);

  const providerValue = useMemo(
    () => ({
      room,
      localTracks,
      isConnecting,
      onError: onErrorCallback,
      getLocalVideoTrack,
      connect,
      isAcquiringLocalTracks,
      removeLocalVideoTrack,
      isSharingScreen,
      toggleScreenShare,
      getAudioAndVideoTracks,
      isBackgroundSelectionOpen,
      setIsBackgroundSelectionOpen
    }),
    [
      connect,
      getAudioAndVideoTracks,
      getLocalVideoTrack,
      isAcquiringLocalTracks,
      isBackgroundSelectionOpen,
      isConnecting,
      isSharingScreen,
      localTracks,
      onErrorCallback,
      removeLocalVideoTrack,
      room,
      toggleScreenShare
    ]
  );

  return (
    <VideoContext.Provider value={providerValue}>
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/*
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
};
