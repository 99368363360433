import { cn } from '~utils/tailwind';

import { TextInputLabelProps } from './types';

const TextInputLabel = ({
  children,
  isFocused,
  isError,
  isDisabled
}: TextInputLabelProps) => {
  return (
    <div
      className={cn(
        'mb-1',
        'text-gray-500 text-label-1',
        'transition-colors motion-reduce:transition-none',
        {
          'text-primary-500': isFocused,
          'text-red-500': isError,
          'text-gray-300': isDisabled
        }
      )}
    >
      {children}
    </div>
  );
};

export default TextInputLabel;
