import { AxiosError } from 'axios';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import Consultation from '~features/consultation/models';
import { api } from '~utils/api';

import { ConsultationParams, consultationKeys } from './query-keys';

const fetchConsultations = async (params: ConsultationParams) =>
  (
    await api.get<Pagination<Consultation.Level1>>('/consultations/', {
      params
    })
  ).data;

const useConsultations = (params: ConsultationParams) => {
  return useQuery<Pagination<Consultation.Level1>, AxiosError>({
    queryKey: consultationKeys.consultations(params),
    queryFn: async () => await fetchConsultations(params),
    staleTime: 2 * 60 * 1000,
    enabled: !!params.page,
    placeholderData: keepPreviousData
  });
};

export default useConsultations;
