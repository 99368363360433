import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider
} from 'react-router-dom';
import Layout from '~components/layout/component';
import useLogInStatus from '~hooks/use-log-in-status';
import ConsultationPage from '~pages/consultation';
import Home from '~pages/home';
import Inbox from '~pages/inbox';
import LiveConsultation from '~pages/live-consultation/page';
import { VideoChat } from '~pages/live-consultation/video-chat/page';
import Login from '~pages/login';
import NoShows from '~pages/no-shows/page';
import Practice from '~pages/practice/page';
import TwilioRoomStateProvider from '~twilio/state';

const AuthenticatedRoutes = () => {
  const isLoggedIn = useLogInStatus();

  if (!isLoggedIn) {
    return <Navigate to='/login' replace />;
  }

  return <Outlet />;
};

const router = createBrowserRouter([
  {
    element: <AuthenticatedRoutes />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: '/',
            element: <Home />
          },
          {
            path: '/inbox',
            element: <Inbox />
          },
          {
            path: '/consultation',
            element: <ConsultationPage />
          },
          {
            path: '/live-consultation',
            element: <LiveConsultation />
          },
          {
            path: '/no-shows',
            element: <NoShows />
          },
          {
            path: '/practice',
            element: <Practice />
          }
        ]
      },
      {
        path: '/live-consultation/video-chat',
        element: (
          <TwilioRoomStateProvider>
            <VideoChat />
          </TwilioRoomStateProvider>
        )
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  }
]);

/**
 * @see https://reactrouter.com/en/main/start/tutorial
 */
const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
