import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { api } from '~utils/api';

import { liveConsultationKeys, LiveConsultationParams } from './query-keys';

const fetchLiveConsultationHistories = async (params: LiveConsultationParams) =>
  (
    await api.get<Pagination<LiveConsultation.Level1>>(`/vet/lc_histories/`, {
      params
    })
  ).data;

const useLiveConsultationHistories = (params: LiveConsultationParams) => {
  return useQuery<Pagination<LiveConsultation.Level1>, AxiosError>({
    queryKey: liveConsultationKeys.historiesWithParams(params),
    queryFn: async () => await fetchLiveConsultationHistories(params),
    staleTime: 2 * 60 * 1000,
    enabled: !!params.page
  });
};

export default useLiveConsultationHistories;
