import { useEffect } from 'react';
import { Callback } from 'src/types';
import { Room } from 'twilio-video';

export default function useHandleTrackPublicationFailed(
  room: Room | null,
  onError: Callback
) {
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', onError);
      return () => {
        room.localParticipant.off('trackPublicationFailed', onError);
      };
    }
  }, [room, onError]);
}
