import IconButton from '~components/@ui/button/icon-button/component';
import { cn } from '~utils/tailwind';

interface PaginationProps {
  currentPageNum: number;
  totalPageNum: number;
  pageNums: number[];
  onPrevClick: (firstPageNum?: number) => void;
  onNextClick: (totalPageNum?: number) => void;
  onPageNumClick: (page: number) => void;
}

const Pagination = ({
  currentPageNum,
  totalPageNum,
  pageNums,
  onPageNumClick,
  onNextClick,
  onPrevClick
}: PaginationProps) => {
  const isPrevArrowDisabled = currentPageNum === 1;
  const isNextArrowDisabled =
    currentPageNum === pageNums[pageNums.length] || totalPageNum < 10;

  return (
    <nav className='mt-5 flex w-full justify-center py-5'>
      <IconButton
        className={cn(isPrevArrowDisabled && 'opacity-0', 'mr-3')}
        name='collapse'
        onClick={() => {
          onPrevClick(1);
        }}
        disabled={isPrevArrowDisabled}
        iconStrokeColor='transparent'
      />
      <IconButton
        className={cn(isPrevArrowDisabled && 'opacity-0', 'mr-3')}
        name='arrow_left'
        onClick={() => {
          onPrevClick();
        }}
        disabled={isPrevArrowDisabled}
        iconStrokeColor='transparent'
      />

      {pageNums.map((page) => (
        <button
          className={cn(
            currentPageNum === page
              ? 'bg-gray-100 text-title-3'
              : 'text-body-1',
            'h-9 w-9 rounded'
          )}
          type='button'
          key={page}
          onClick={() => {
            onPageNumClick(page);
          }}
          aria-current={currentPageNum === page ? 'page' : undefined}
        >
          {page}
        </button>
      ))}
      <IconButton
        className={cn(isNextArrowDisabled && 'opacity-0', 'ml-3')}
        name='arrow_right'
        onClick={() => {
          onNextClick();
        }}
        disabled={isNextArrowDisabled}
        iconStrokeColor='transparent'
      />
      <IconButton
        className={cn(isNextArrowDisabled && 'opacity-0', 'ml-3')}
        name='open'
        onClick={() => {
          onNextClick(totalPageNum);
        }}
        disabled={isNextArrowDisabled}
        iconStrokeColor='transparent'
      />
    </nav>
  );
};

export default Pagination;
