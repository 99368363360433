import clsx from 'clsx';
import { memo, ReactNode, useMemo } from 'react';
import {
  LocalVideoTrack,
  Participant as IParticipant,
  RemoteVideoTrack
} from 'twilio-video';
import Icon from '~components/@ui/icon';
import useIsTrackSwitchedOff from '~twilio/hooks/use-is-track-switched-off';
import useParticipantIsReconnecting from '~twilio/hooks/use-participant-is-reconnecting';
import useParticipantNetworkQualityLevel from '~twilio/hooks/use-participant-network-quality-level';
import usePublications from '~twilio/hooks/use-publications';
import useTrack from '~twilio/hooks/use-track';
import { cn } from '~utils/tailwind';

import { ParticipantTracks } from './participant-tracks';

interface NetworkQualityLevelProps {
  networkQualityStatus: 'row' | 'middle' | 'high';
}
const NetworkQualityLevel = ({
  networkQualityStatus
}: NetworkQualityLevelProps) => {
  return (
    <div className='flex h-[10px] w-[10px] flex-row items-end gap-[1px]'>
      {networkQualityStatus === 'row' && (
        <div className='h-1/2 w-[3px] rounded-[1px] bg-white' />
      )}

      {networkQualityStatus === 'middle' && (
        <>
          <div className='h-1/2 w-[3px] rounded-[1px] bg-white' />
          <div className='h-3/4 w-[3px] rounded-[1px] bg-white' />
        </>
      )}

      {networkQualityStatus === 'high' && (
        <>
          <div className='h-1/2 w-[3px] rounded-[1px] bg-white' />
          <div className='h-3/4 w-[3px] rounded-[1px] bg-white' />
          <div className='h-full w-[3px] rounded-[1px] bg-white' />
        </>
      )}
    </div>
  );
};

interface ParticipantInfoProps {
  participant: IParticipant;
  children: ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
  className?: string;
}

const ParticipantInfo = ({
  className,
  participant,
  onClick,
  children,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker
}: Readonly<ParticipantInfoProps>) => {
  const publications = usePublications(participant);
  const videoPublication = publications.find(
    (publication) =>
      !publication.trackName.includes('screen') && publication.kind === 'video'
  );

  const videoTrack = useTrack(videoPublication);
  const isVideoEnabled = Boolean(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const isMobileParticipant = participant.identity.includes('#mobile');
  // eslint-disable-next-line no-nested-ternary
  const identity = isLocalParticipant
    ? 'Me'
    : isMobileParticipant
      ? 'Mobile'
      : participant.identity;

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  const networkQualityStatus = useMemo(() => {
    if (!networkQualityLevel || networkQualityLevel < 2) {
      return 'row';
    }

    if (networkQualityLevel < 4) {
      return 'middle';
    }

    return 'high';
  }, [networkQualityLevel]);

  const networkQualityBadgeColor = useMemo(() => {
    switch (networkQualityStatus) {
      case 'row':
        return 'bg-red-500';
      case 'middle':
        return 'bg-orange-500';
      case 'high':
        return 'bg-green-500';
      default:
        return 'bg-green-500';
    }
  }, [networkQualityStatus]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        '[&>video] :absolute relative z-50 box-border flex h-full w-full items-center justify-center overflow-hidden rounded-[18px] border-[3px] border-gray-50',
        hideParticipant && 'hidden',
        onClick && 'cursor-pointer',
        isDominantSpeaker && 'border-[4px] border-primary-500',
        className
      )}
      onClick={onClick}
    >
      <div
        className={clsx(
          'pointer-events-none absolute left-[16px] top-[16px] z-10 flex h-[36px] w-fit items-center justify-start gap-1.5 rounded-[36px] bg-black bg-opacity-80 p-3'
        )}
      >
        <div
          className={cn(
            'h-[12px] w-[12px] rounded-full',
            networkQualityBadgeColor
          )}
        />
        <p className='xl:text-label-1 text-gray-50 text-label-3'>{identity}</p>
        <NetworkQualityLevel networkQualityStatus={networkQualityStatus} />
      </div>
      {isParticipantReconnecting && (
        <div className='pointer-events-none absolute flex h-full w-full items-center justify-center bg-gray-700 bg-opacity-75'>
          <p className='text-white text-title-3'>Reconnecting...</p>
        </div>
      )}
      {(isVideoSwitchedOff || !isVideoEnabled) && (
        <div className='z-1 pointer-events-none absolute flex h-full w-full items-center justify-center'>
          <Icon
            name='video_mute_solid'
            className='xl:w-[140px] xl:h-[140px] h-[60px] w-[60px] fill-white'
          />
        </div>
      )}
      {children}
    </div>
  );
};
ParticipantInfo.displayName = 'ParticipantInfo';

interface ParticipantProps {
  participant: IParticipant;
  className?: string;
  // videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export const Participant = memo(
  ({
    className,
    participant,
    // videoOnly,
    enableScreenShare,
    onClick,
    isSelected,
    isLocalParticipant,
    hideParticipant,
    isDominantSpeaker
  }: ParticipantProps) => {
    return (
      <ParticipantInfo
        className={className}
        participant={participant}
        onClick={onClick}
        isSelected={isSelected}
        isLocalParticipant={isLocalParticipant}
        hideParticipant={hideParticipant}
        isDominantSpeaker={isDominantSpeaker}
      >
        <ParticipantTracks
          participant={participant}
          // videoOnly={videoOnly}
          enableScreenShare={enableScreenShare}
          isLocalParticipant={isLocalParticipant}
        />
      </ParticipantInfo>
    );
  }
);
Participant.displayName = 'Participant';
