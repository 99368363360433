import { getDefaultStore } from 'jotai';

import { toastAtom } from './atom';
import { ToastProps } from './types';

const DEFAULT_CLOSING_TIME = 5000;

const store = getDefaultStore();

export const toast = {
  open(props: ToastProps): void {
    store.set(toastAtom, {
      ...props,
      type: props.type ?? 'default',
      isOpen: true,
      autoClosingTime: props.autoClosingTime ?? DEFAULT_CLOSING_TIME
    });
  },
  close(): void {
    const current = store.get(toastAtom);

    store.set(toastAtom, {
      ...current,
      message: current?.message ?? '',
      isOpen: false
    });
  },
  destroy(): void {
    store.set(toastAtom, null);
  }
};
