import { ComponentProps, ReactNode, useMemo } from 'react';
import Overlay from '~components/overlay';
import useBlockScroll from '~hooks/use-block-scroll';
import useDelayedState from '~hooks/use-delayed-state';
import useEscape from '~hooks/use-escape';
import { cn } from '~utils/tailwind';

import Portal from '../../portal/component';
import DialogAction from './action';
import DialogButton from './button';
import DialogCloseIcon from './close-icon';
import DialogContent from './content';
import { DialogContext, DialogType } from './context';
import DialogIcon from './icon';
import DialogTextButton from './text-button';
import DialogTitle from './title';

type DialogProps = Readonly<{
  open: boolean;
  type?: DialogType;
  children?: ReactNode;
  className?: ComponentProps<'div'>['className'];
  onClose?: () => void;
  onTransitionEnd?: () => void;
}>;

const DIALOG_TRANSITION_DELAY = 150;

/**
 * @version v1.1.9
 * @author Danny
 * @description 사이드이펙트로 인한 Dialog 메인, 서브 컴포넌트 스타일 수정
 */
const Dialog = ({
  open,
  type = 'prompt',
  children,
  className,
  onClose,
  onTransitionEnd
}: DialogProps) => {
  const show = useDelayedState({
    active: open,
    delay: DIALOG_TRANSITION_DELAY
  });
  const context = useMemo(() => ({ type }), [type]);

  useEscape({
    callback: onClose
  });

  useBlockScroll(open);

  return (
    <Portal id='dialog'>
      <DialogContext.Provider value={context}>
        <div
          className={cn(
            'relative transition-all motion-reduce:transition-none',
            open ? 'z-dialog opacity-100' : 'z-dimmed opacity-0'
          )}
          onTransitionEnd={onTransitionEnd}
        >
          <Overlay open={show} onClose={onClose}>
            {show && (
              <div
                role='presentation'
                className='relative m-6 flex w-full cursor-pointer items-center justify-center'
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  className={cn(
                    'relative w-fit cursor-auto rounded-2xl bg-white shadow lg:w-[580px]',
                    type === 'prompt' && 'p-5 lg:p-6',
                    type === 'alert' &&
                      'w-full px-5 pb-3 pt-5 lg:px-6 lg:pb-3 lg:pt-6',
                    className
                  )}
                >
                  {children}
                </div>
              </div>
            )}
          </Overlay>
        </div>
      </DialogContext.Provider>
    </Portal>
  );
};

Dialog.Icon = DialogIcon;
Dialog.CloseIcon = DialogCloseIcon;
Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.Action = DialogAction;
Dialog.Button = DialogButton;
Dialog.TextButton = DialogTextButton;

export default Dialog;

/**
 * @usage
 * 1. type === prompt
 * <Dialog open={open} onClose={onClose} type="prompt" className="lg:p-0">
 *   <Dialog.CloseIcon onClose={onClose} />
 *   <Dialog.Icon name="circle_exclamation" className="fill-gray-500" />
 *   <Dialog.Title>Dialog Title</Dialog.Title>
 *   <Dialog.Content>{Dialog Content}</Dialog.Content>
 *   <Dialog.Action>
 *     <Dialog.TextButton onClick={onCancel}>Maybe later</Dialog.TextButton>
 *     <Dialog.Button onClick={onConfirm}>Enter information</Dialog.Button>
 *   </Dialog.Action>
 * </Dialog>
 *
 * 2. type === alert
 * - alert 다이얼로그는 반드시 확인을 받아야 하는 정보에 한해 사용하는 타입입니다.
 *   Overlay를 눌러도 닫히지 않고, 반드시 버튼을 눌러 사용자의 확인을 받아야만 닫을 수 있습니다.
 *   따라서 Dialog Container에 onClose prop을 전달하지 않습니다.
 *  <Dialog
 *    type="alert"
 *    open={open}
 *  >
 *    <Dialog.Title>Dialog Title</Dialog.Title>
 *    <Dialog.Content>hello</Dialog.Content>
 *    <Dialog.Action>
 *      <Dialog.TextButton onClick={onClose} priority="primary">
 *        Close
 *      </Dialog.TextButton>
 *    </Dialog.Action>
 *  </Dialog>
 */
