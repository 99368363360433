import { useSetAtom } from 'jotai';
import { ID } from 'src/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { closedLiveConsultationAtom } from '~features/live-consultation/atoms/closed-live-consultation';
import { liveConsultationKeys } from '~features/live-consultation/hooks/queries/query-keys';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { useSpentTime } from '~twilio/hooks/use-spent-time';
import { api } from '~utils/api';

const closeLiveConsultationRoom = async (liveConsultationId: ID) => {
  return (
    await api.post<LiveConsultation.Level2>(`/lcs/${liveConsultationId}/close/`)
  ).data;
};

export const useCloseLiveConsultationTicket = () => {
  const { resetSpentTime } = useSpentTime();
  const setClosedLiveConsultation = useSetAtom(closedLiveConsultationAtom);
  const queryClient = useQueryClient();
  return useMutation<LiveConsultation.Level2, Error, ID>({
    mutationFn: closeLiveConsultationRoom,
    onSuccess: (res) => {
      setClosedLiveConsultation(res);
      queryClient.removeQueries({
        queryKey: liveConsultationKeys.ongoingSession()
      });
      resetSpentTime();
    }
  });
};
