import { FallbackProps } from 'react-error-boundary';

import Button from './@ui/button/button/component';
import Icon from './@ui/icon';

const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  return (
    <div className='flex flex-col items-center gap-5 px-5 py-8'>
      <Icon name='bot_question_mark' className='h-32 w-32' />
      <h3 className='text-gray-700 text-headline-3'>Unable to load</h3>
      <p className='whitespace-pre-line text-center text-gray-500 text-body-1'>
        {`We could not load content due to issues on\nour end. Please try to reload.`}
      </p>
      <Button priority='tertiary' onClick={resetErrorBoundary}>
        Reload
      </Button>
    </div>
  );
};

export default ErrorFallback;
