import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { api } from '~utils/api';
import { liveConsultationKeys } from './query-keys';

export enum Weekday {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

export interface ILiveConsultationTimeBox {
  is_enabled: boolean;
  weekday: Weekday;
  start_time: string;
  end_time: string;
  has_changed?: boolean;
}

export type MyLiveConsultationTimeBoxesType = Record<
  Weekday,
  ILiveConsultationTimeBox[]
>;

const fetchLiveConsultationTimeboxes = async () =>
  (
    await api.get<ILiveConsultationTimeBox[]>('/lcs/timeboxes/')
  ).data.reduce<MyLiveConsultationTimeBoxesType>(
    (acc, timebox) => {
      const timeboxes = acc[timebox.weekday];
      return { ...acc, [timebox.weekday]: [...timeboxes, timebox] };
    },
    {
      [Weekday.Sunday]: [],
      [Weekday.Monday]: [],
      [Weekday.Tuesday]: [],
      [Weekday.Wednesday]: [],
      [Weekday.Thursday]: [],
      [Weekday.Friday]: [],
      [Weekday.Saturday]: []
    }
  );

export const useLiveConsultationTimeboxes = () => {
  const query = useQuery<MyLiveConsultationTimeBoxesType>({
    queryKey: liveConsultationKeys.timeboxes(),
    queryFn: fetchLiveConsultationTimeboxes
  });

  const { data } = query;

  const flatData = useMemo(
    () => (data ? Object.values(data).flat() : []),
    [data]
  );

  const numberOfSlots = useMemo(() => flatData.length, [flatData]);
  const numberOfSlotsByHour = useMemo(
    () => Math.floor(numberOfSlots / 168),
    [numberOfSlots]
  );

  return {
    ...query,
    flatData,
    numberOfSlots,
    numberOfSlotsByHour
  };
};
