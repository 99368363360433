import { capitalize } from 'radash';
import { useCallback } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import MediaList from '~components/@local-ui/media-list/component';
import Button from '~components/@ui/dialog/button';
import TextButton from '~components/@ui/dialog/text-button';
import Icon from '~components/@ui/icon';
import Avatar from '~components/@ui/image/avatar/component';
import { Label } from '~components/@ui/label/component';
import TextArea from '~components/@ui/text-input/text-area';
import useConsultationMessage, {
  MessageParams
} from '~features/consultation/hooks/mutations/use-consultation-message';
import useConsultationDetail from '~features/consultation/hooks/queries/use-consultation-detail';
import Consultation from '~features/consultation/models';
import { getGender } from '~features/pet/utils';
import { labelColor } from '~utils/consultation';
import { getDateFormat } from '~utils/date';
import { cn } from '~utils/tailwind';

const LAST_INFO_LIST = [
  'Vaccinations',
  'Flea & tick preventions',
  'Medical history'
];

interface ThreadCommentProps {
  name: string;
  sender: 'Owner' | 'Vet';
  createdAt: string;
  comment: string;
  profileUri?: string;
  photoUrls?: string[];
  videoUrls?: string[];
}

const ThreadComment = ({
  profileUri,
  name,
  sender,
  createdAt,
  comment,
  photoUrls,
  videoUrls
}: ThreadCommentProps) => {
  return (
    <div className='flex px-7 py-4'>
      <Avatar
        type={sender === 'Vet' ? 'vet' : 'person'}
        size='32'
        uri={profileUri}
      />
      <div className='ml-2'>
        <div className='flex items-center'>
          <span className='text-label-1'>{name}</span>
          {sender === 'Vet' && (
            <Icon name='badge' width={20} height={20} className='ml-1' />
          )}
          <div className='flex items-center justify-center px-[6px]'>
            <div className='h-[3px] w-[3px] rounded-full bg-gray-500' />
          </div>
          <span className='text-gray-500 text-body-2'>
            {getDateFormat(createdAt, 'absoluteShort')}
          </span>
        </div>
        <div className='mt-2 whitespace-pre-wrap text-body-2'>{comment}</div>
        <div className='mt-2 py-2'>
          <MediaList photoUrls={photoUrls} videoUrls={videoUrls} size='64' />
        </div>
      </div>
    </div>
  );
};

type ListItemProps = Readonly<{
  title: string;
  value: string;
  type?: 'row' | 'col';
}>;

const ListItem = ({ title, value, type = 'row' }: ListItemProps) => {
  return (
    <div
      className={cn(
        'flex border-b border-solid border-gray-200 p-1 last:border-b-0',
        {
          'flex-col gap-1': type === 'col',
          'items-center justify-between': type === 'row'
        }
      )}
    >
      <div
        className={cn(
          type === 'row' && 'mr-3 min-w-[69px]',
          'text-gray-700 text-body-3'
        )}
      >
        {title}
      </div>
      <div
        className={cn(
          'text-gray-900 text-label-2',
          type === 'row' && 'whitespace-nowrap'
        )}
      >
        {value}
      </div>
    </div>
  );
};

interface InfoTitleProps {
  children: string;
}

const InfoTitle = ({ children }: InfoTitleProps) => {
  return <div className='px-7 py-5 text-title-3'>{children}</div>;
};

interface ConsultationDetailProps {
  id: number;
  otherQuestionsViewClick?: () => void;
  onAIAnswerRequest?: () => void;
  isExpanded?: boolean;
  isDialog?: boolean;
}

const resolver: Resolver<MessageParams> = async (values) => ({
  values,
  errors: {}
});

const ConsultationDetail = ({
  id,
  isExpanded,
  isDialog,
  otherQuestionsViewClick,
  onAIAnswerRequest
}: ConsultationDetailProps) => {
  const { register, handleSubmit, setValue } = useForm<MessageParams>({
    resolver
  });
  const { data } = useConsultationDetail(id);
  const { mutate: sendMessage } = useConsultationMessage({
    onSuccess: () => {
      setValue('text', '');
    }
  });

  const onSubmit: SubmitHandler<MessageParams> = useCallback(
    (values) => {
      sendMessage({ id, text: values.text });
    },
    [id, sendMessage]
  );

  if (!data) {
    return null;
  }

  // conversation에 질문의 본문이 배열 첫 번째 항목에 포함되어 내려옴. 질문 본문이 필터링된 배열
  const conversation = data.conversation.slice(1);

  const hasMediaFiles =
    data.conversation?.[0]?.photo_urls.length > 0 ||
    data.conversation?.[0]?.video_urls.length > 0;

  const isAnswered = data.status === Consultation.State.Answered;

  if (!data) {
    return null;
  }

  return (
    <>
      <div className='flex h-full overflow-y-scroll'>
        {isExpanded && (
          <div className='h-full min-w-[380px] max-w-[380px] overflow-y-scroll border-r border-r-gray-300'>
            <InfoTitle>Consultation Information</InfoTitle>
            <div className='px-7 py-2 pb-5'>
              <div className='rounded-xl bg-gray-50 p-3'>
                <ListItem
                  title='Time'
                  value={`${getDateFormat(data.created_at)} UTC`}
                />
                <ListItem title='Owner ID' value={data.owner.id.toString()} />
                <ListItem title='Question ID' value={data.id.toString()} />
              </div>

              <div className='mt-5 gap-2'>
                <TextButton
                  onClick={otherQuestionsViewClick}
                  priority='primary'
                >
                  View questions from this owner
                </TextButton>
                <TextButton
                  disabled={!onAIAnswerRequest}
                  onClick={onAIAnswerRequest}
                  priority='primary'
                >
                  See AI answer
                </TextButton>
              </div>
            </div>
          </div>
        )}

        <div className='w-full overflow-y-scroll border-r border-r-gray-300'>
          <div className='px-7 py-5'>
            <Label color={labelColor[data.status]}>
              {data.status === Consultation.State.Pending
                ? 'WAITING'
                : Consultation.State[data.status].toUpperCase()}
            </Label>
            <h1 className='mt-5 text-title-1'>{data.generated_title}</h1>
            <div className='mt-5 whitespace-pre-wrap text-body-1'>
              {data.details}
            </div>
          </div>
          <div className='py-5'>
            {conversation.map((comment) => (
              <ThreadComment
                key={comment.created_at}
                name={
                  comment.sender === 'Vet'
                    ? data.vet.title
                    : data.owner.nickname
                }
                sender={comment.sender}
                createdAt={comment.created_at}
                comment={comment.text}
                photoUrls={comment.photo_urls}
                videoUrls={comment.video_urls}
              />
            ))}
          </div>

          {!isDialog && !isAnswered && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                void handleSubmit(onSubmit)(e);
              }}
            >
              <div className='px-7 pb-2 pt-5'>
                <TextArea
                  required
                  disabled={data.status === Consultation.State.Answered}
                  placeholder='Reply to owner'
                  isAutoResizeMode
                  {...register('text')}
                />
              </div>
              <div className='flex justify-end px-7 pb-5 pt-2'>
                <Button
                  disabled={data.status === Consultation.State.Answered}
                  type='submit'
                  aria-describedby='submit button to send message'
                >
                  Send
                </Button>
              </div>
            </form>
          )}
        </div>

        <div className={cn(isExpanded && 'min-w-[380px]', 'block')}>
          <div className='h-full max-w-[380px] overflow-y-scroll'>
            {!isExpanded && (
              <>
                <InfoTitle>Consultation Information</InfoTitle>
                <div className='px-7 py-2 pb-5'>
                  <div className='rounded-xl bg-gray-50 p-3'>
                    <ListItem
                      title='Time'
                      value={`${getDateFormat(
                        data.created_at,
                        'absolute'
                      )} UTC`}
                    />
                    <ListItem
                      title='Owner ID'
                      value={data.owner.id.toString()}
                    />
                    <ListItem
                      title='Consultation ID'
                      value={data.id.toString()}
                    />
                  </div>
                  {!isDialog && (
                    <div className='mt-5 gap-2'>
                      <TextButton
                        onClick={otherQuestionsViewClick}
                        priority='primary'
                      >
                        <p className='text-left'>
                          View questions from this owner
                        </p>
                      </TextButton>
                      <TextButton
                        disabled={!onAIAnswerRequest}
                        priority='primary'
                        onClick={onAIAnswerRequest}
                      >
                        See AI answer
                      </TextButton>
                    </div>
                  )}
                </div>
              </>
            )}

            {hasMediaFiles && (
              <>
                <InfoTitle>Attached Media</InfoTitle>
                <div className='px-7 py-2 pb-5'>
                  <MediaList
                    photoUrls={data.conversation?.[0].photo_urls}
                    videoUrls={data.conversation?.[0].video_urls}
                  />
                </div>
              </>
            )}

            {data.pet && (
              <>
                <InfoTitle>Pet Information</InfoTitle>
                <div className='px-7 py-2 pb-5'>
                  <div className='rounded-xl bg-gray-50 p-3'>
                    <ListItem title='Name' value={data.pet.name || '-'} />
                    <ListItem
                      title='Species'
                      value={data.pet.kind.species || '-'}
                    />
                    <ListItem
                      title='Breed'
                      value={data.pet?.kind.breed || '-'}
                    />
                    <ListItem title='Age' value={data.pet.age} />
                    <ListItem
                      title='Gender'
                      value={getGender(data.pet.gender)}
                    />
                    <ListItem
                      title='Status'
                      value={
                        data.pet.is_neutered ? 'Neutered or spayed' : 'Intact'
                      }
                    />
                  </div>
                </div>
              </>
            )}

            <InfoTitle>Issue Details</InfoTitle>
            <div className='px-7 py-2 pb-5'>
              <div className='rounded-xl bg-gray-50 p-3'>
                <ListItem
                  title='Main concern'
                  value={
                    data.main_concern ? capitalize(data.main_concern) : '-'
                  }
                  type='col'
                />
                {Object.entries(data.checkup_data).map(([key, value]) => {
                  if (LAST_INFO_LIST.includes(key)) {
                    return null;
                  }

                  return (
                    <ListItem key={key} title={key} value={value} type='col' />
                  );
                })}
              </div>
              <div className='mt-2 rounded-xl bg-gray-50 p-3'>
                <ListItem
                  title='Vaccinations'
                  value={data.checkup_data.Vaccinations}
                  type='col'
                />
                <ListItem
                  title='Flea & tick preventions'
                  value={data.checkup_data['Flea & tick preventions']}
                  type='col'
                />
                <ListItem
                  title='Medical history'
                  value={data.checkup_data['Medical history']}
                  type='col'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsultationDetail;
