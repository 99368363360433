import { memo } from 'react';
import { cn } from '~utils/tailwind';

import Icon, { IconProps } from '../icon';

const DialogIcon = ({ name, className, ...props }: IconProps) => {
  return (
    <Icon
      {...props}
      name={name}
      className={cn('mb-4 h-12 w-12 lg:mb-6', className)}
    />
  );
};

export default memo(DialogIcon);
