import Avatar from '~components/@ui/image/avatar/component';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { getDayjsFormat } from '~utils/format/dayjs';
import { cn } from '~utils/tailwind';

interface PetCardProps extends LiveConsultationTicket {
  isActive?: boolean;
}
export const PetCard = ({
  isActive = false,
  pet,
  category_name,
  scheduled_at
}: Partial<PetCardProps>) => {
  return (
    <div
      className={cn(
        'flex min-w-[240px] flex-row gap-5 rounded-2xl p-4',
        isActive ? 'bg-primary-50' : 'bg-gray-50'
      )}
    >
      <Avatar uri={pet?.thumbnail_url} type='pet' alt='pet-avatar' size='40' />
      <div className='flex flex-col gap-1'>
        <p className='text-gray-900 text-title-3'>{pet?.name}</p>
        <p className='text-gray-700 text-body-1'>{category_name || '-'}</p>
        {scheduled_at && (
          <p className='text-gray-700 text-body-1'>
            {getDayjsFormat(scheduled_at, 'short')}
          </p>
        )}
      </div>
    </div>
  );
};
