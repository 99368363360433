import { atom } from 'jotai';

import App from '../models';

export const foregroundMessageAtom = atom<App.Message | null>(null);
foregroundMessageAtom.debugLabel = 'foregroundMessageAtom';

export const notificationBadgeAtom = atom<{
  inbox: boolean;
  live_consultation_request: boolean;
}>({
  inbox: false,
  live_consultation_request: false
});
notificationBadgeAtom.debugLabel = 'notificationBadgeAtom';
