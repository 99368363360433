import { memo } from 'react';
import { cn } from '~utils/tailwind';

import { textButtonStyle } from './styles';
import { TextButtonProps } from './types';

/**
 * @version v1.1.0
 * @author Danny
 * @description tertiary 버튼 텍스트 색상 변경
 */
const TextButton = ({
  children,
  priority,
  size,
  ...props
}: TextButtonProps) => {
  const { className, type = 'button' } = props;

  return (
    <button
      {...props}
      type={type}
      className={cn(textButtonStyle({ priority, size }), className)}
    >
      {children}
    </button>
  );
};

export default memo(TextButton);
