import { ComponentProps, MouseEvent, useCallback } from 'react';
import { cn } from '~utils/tailwind';

import Icon from '../../icon';
import { useSelectBox } from './context';

type Props = ComponentProps<'div'> & { value?: string | null };

/**
 * @version v1.0.1
 * @author Sabo
 * @description caret_down absolute속성 제거
 */
const SelectBoxInput = ({
  placeholder = 'Select...',
  value,
  onClick,
  ...props
}: Props) => {
  const { selectedData, handleDropdown } = useSelectBox();

  const valueText = value !== undefined ? value : selectedData?.label;

  const onSelectBoxInputClick = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      handleDropdown();
      onClick && onClick(e);
    },
    [handleDropdown, onClick]
  );

  return (
    <div
      {...props}
      role='presentation'
      className='relative w-full'
      onClick={onSelectBoxInputClick}
    >
      <div
        placeholder={placeholder}
        className={cn(
          'flex w-full cursor-pointer flex-row gap-2 rounded-xl border border-solid border-gray-200 bg-white px-4 py-3 text-gray-900 outline-none text-body-1 placeholder:text-gray-400',
          valueText ?? 'text-gray-400'
        )}
      >
        {valueText ?? placeholder}
        <div className='flex h-6 w-6 cursor-pointer items-center justify-center'>
          <Icon name='caret_down' className='fill-gray-400' />
        </div>
      </div>
    </div>
  );
};

export default SelectBoxInput;
