import { createContext, useContext } from 'react';

import { SelectBoxData } from '../types';

type ContextType = {
  open: boolean;
  searchValue: string;
  selectedData: SelectBoxData | null;
  onSearch: (value: string) => void;
  onSelect: (data: SelectBoxData) => void;
  handleDropdown: () => void;
  closeDropdown: () => void;
};

const initialContext: ContextType = {
  open: false,
  searchValue: '',
  selectedData: null,
  onSearch: () => {},
  onSelect: () => {},
  handleDropdown: () => {},
  closeDropdown: () => {}
};

export const SelectBoxContext = createContext(initialContext);

export const useSelectBox = () => {
  const context = useContext(SelectBoxContext);

  if (context === undefined) {
    throw new Error('useSelectBox must be used within a <SelectBox />');
  }
  return context;
};
