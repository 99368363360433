import { useQuery } from '@tanstack/react-query';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { api } from '~utils/api';

import { vetKeys } from './query-keys';

type Response = Readonly<{
  waiting_consultation_count: number;
  live_consultation_count: number;
  today_scheduled_live_consultations: LiveConsultationTicket[];
}>;

const fetchDashboard = async () =>
  (await api.get<Response>('/vet/dashboard/')).data;

const useDashboard = () => {
  return useQuery<Response, Error>({
    queryKey: vetKeys.dashboard(),
    queryFn: fetchDashboard
  });
};

export default useDashboard;
