import { uid } from 'radash';
import { ChangeEvent, useCallback, useState } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import Button from '~components/@ui/button/button/component';
import TextButton from '~components/@ui/button/text-button/component';
import {
  CheckBox,
  CheckBoxContainer
} from '~components/@ui/control/check-box/component';
import { CheckBoxProps } from '~components/@ui/control/check-box/types';
import Icon from '~components/@ui/icon';
import TextField from '~components/@ui/text-input/text-field/component';
import useLogIn, {
  LogInParams
} from '~features/auth/hooks/mutations/use-log-in';
import useLogInStatus from '~hooks/use-log-in-status';

type CheckBoxItemProps = Readonly<
  CheckBoxProps & {
    label: string;
  }
>;

const CheckBoxItem = ({
  disabled,
  checked,
  label,
  onChange
}: CheckBoxItemProps) => {
  const id = uid(10);

  return (
    <CheckBoxContainer
      disabled={disabled}
      htmlFor={id}
      className='w-fit rounded-lg border border-solid border-transparent'
    >
      <CheckBox
        disabled={disabled}
        checked={checked}
        id={id}
        onChange={onChange}
      />
      <span className='ml-2 text-gray-900 text-body-1'>{label}</span>
    </CheckBoxContainer>
  );
};

const RememberMe = () => {
  const [checked, setChecked] = useState(true);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  return (
    <CheckBoxItem
      label='Remember me'
      checked={checked}
      onChange={onChange}
      aria-describedby='checkbox to stay logged in'
    />
  );
};

const openSupportEmail = () => window.open('mailto:support@drtail.us');

const resolver: Resolver<LogInParams> = async (values) => ({
  values,
  errors: {}
});

const Login = () => {
  const isLoggedIn = useLogInStatus();
  const { register, handleSubmit } = useForm<LogInParams>({ resolver });

  const { mutate: logIn } = useLogIn();

  const onSubmit: SubmitHandler<LogInParams> = useCallback(
    (values) => {
      logIn(values);
    },
    [logIn]
  );

  if (isLoggedIn) {
    return <Navigate to='/' replace />;
  }

  return (
    <main className='flex h-screen flex-col items-center justify-center gap-8 bg-primary-50'>
      <Icon name='drtail_vet_text_logo' />
      <form
        className='w-full max-w-[580px] rounded-lg bg-white p-8'
        onSubmit={(e) => {
          e.preventDefault();
          void handleSubmit(onSubmit)(e);
        }}
      >
        <TextField
          type='email'
          label='Email'
          placeholder='Enter your email address'
          className='mb-3 w-full'
          required
          aria-describedby='email address to login'
          {...register('email', {
            required: true
          })}
        />
        <TextField
          type='password'
          label='Password'
          className='w-full'
          required
          aria-describedby='password to login'
          {...register('password', {
            required: true
          })}
        />
        <div className='py-6'>
          <RememberMe />
        </div>
        <div className='flex flex-col gap-6'>
          <Button
            className='w-full'
            type='submit'
            aria-describedby='submit button to login'
          >
            Login
          </Button>
          <TextButton
            className='w-full'
            size='large'
            onClick={openSupportEmail}
            aria-describedby='button to contact support'
          >
            Contact Support
          </TextButton>
        </div>
      </form>
    </main>
  );
};

export default Login;
