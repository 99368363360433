import { snake } from 'radash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TabMenu from '../tab-menu';
import { TabItem } from './types';
import { useTabBarIndex } from './use-tab-bar';

type TabBarProps = Readonly<{
  items: TabItem[];
}>;
export const TAB = 'tab';

const PADDING = 36;

/**
 * @version v1.0.5
 * @author Sabo
 * @description currentTabIndex is the index of the current tab(새로고침 시 초기화 이슈)
 */
const TabBar = ({ items }: TabBarProps) => {
  const navigate = useNavigate();
  const { defaultTabIndex } = useTabBarIndex(items);

  const tabsRef = useRef<HTMLButtonElement[]>([]);

  const [currentTabIndex, setCurrentTabIndex] = useState(defaultTabIndex);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(PADDING || 0);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[currentTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => {
      window.removeEventListener('resize', setTabPosition);
    };
  }, [currentTabIndex]);

  return (
    <div className='relative block w-full overflow-hidden border-b border-solid border-gray-200'>
      <div
        style={{ paddingLeft: PADDING, paddingRight: PADDING }}
        className='flex h-full w-full gap-2'
      >
        {items.map(({ name, showNotification }, index) => {
          return (
            <TabMenu
              ref={tabsRef}
              onTabPress={() => {
                navigate({ search: `?${TAB}=${snake(name)}` });
                setCurrentTabIndex(() => index);
              }}
              isActive={currentTabIndex === index}
              index={index}
              key={name}
              showNotification={showNotification}
            >
              {name}
            </TabMenu>
          );
        })}
      </div>
      <span
        className='absolute -bottom-[1px] h-1 rounded-sm bg-gray-900 transition-all duration-300'
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
};

export default TabBar;
