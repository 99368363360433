import { MEDIA_FALLBACK_URL } from '~constants';

/**
 * video url은 thumbnail_url query string이 포함되어 내려옴.
 */
export const VIDEO_TYPE_DIVIDER = '?thumbnail_url=';
export const getMediaType = (url: string) =>
  url.includes(VIDEO_TYPE_DIVIDER) ? 'video' : 'photo';

export const getVideoInfo = (url: string) => {
  const dividedUrl = url.split(VIDEO_TYPE_DIVIDER);
  const videoUrl = dividedUrl.length > 1 ? dividedUrl[0] : '';
  const videoThumbnailUrl =
    dividedUrl.length > 1 ? dividedUrl[1] : MEDIA_FALLBACK_URL;

  return { videoUrl, videoThumbnailUrl };
};
