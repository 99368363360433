import { uid } from 'radash';
import { ComponentProps } from 'react';
import { cn } from '~utils/tailwind';

type SwitchColorCSS = Readonly<{
  [checked in string]: {
    [disabled in string]: string;
  };
}>;

const SWITCH_COLOR_CSS: SwitchColorCSS = {
  true: {
    true: 'after:border-white after:bg-white bg-primary-100 border-primary-100',
    false:
      'bg-primary-500 border-primary-500 hover:bg-primary-400 hover:border-primary-400 after:bg-white active:border-primary-600 active:bg-primary-600'
  },
  false: {
    true: 'border-gray-100 after:bg-gray-100',
    false:
      'border-gray-400 after:bg-gray-400 active:border-gray-500 active:after:bg-gray-500 hover:after:bg-gray-400'
  }
};

const getSwitchColor = (checked?: boolean, disabled?: boolean) => {
  return SWITCH_COLOR_CSS[String(!!checked)][String(!!disabled)];
};

/**
 * @version v1.0.1
 * @author Danny
 */
const Switch = ({ ...props }: ComponentProps<'input'>) => {
  const { checked, disabled } = props;
  const id = uid(10);

  return (
    <label
      className={cn(
        'relative inline-flex items-center',
        !disabled && 'cursor-pointer'
      )}
      htmlFor={id}
    >
      <input {...props} id={id} type='checkbox' className='peer sr-only' />
      <div
        className={cn(
          'h-6 w-11',
          'rounded-full border border-solid',
          'peer peer-checked:after:h-5 peer-checked:after:w-5 peer-checked:after:translate-x-[90%]',
          `after:absolute after:left-1 after:top-1/2 after:h-4 after:w-4 after:-translate-y-1/2 after:rounded-full after:transition-all after:content-['']`,
          getSwitchColor(checked, disabled)
        )}
      />
    </label>
  );
};

export default Switch;

/**
 * @usage
 *  const [checked, setChecked] = useState(false);
 *
 *  <Switch
 *   checked={checked}
 *   onChange={() => {
 * 		 setChecked((prev) => !prev);
 * 	 }}
 *  />
 */
