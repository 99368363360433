import {
  MutationObserverOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { api } from '~utils/api';
import { ILiveConsultationTimeBox } from '../queries/use-live-consultation-timeboxes';
import { liveConsultationKeys } from '../queries/query-keys';

type PatchLiveConsultationTimeboxesParams = ILiveConsultationTimeBox[];

const patchLiveConsultationTimeboxes = async (
  params: Readonly<PatchLiveConsultationTimeboxesParams>
) => {
  return (
    await api.patch<ILiveConsultationTimeBox[]>('/lcs/set-timeboxes/', params)
  ).data;
};

const usePatchLiveConsultationTimeboxes = (
  options?: MutationObserverOptions<
    ILiveConsultationTimeBox[],
    Error,
    PatchLiveConsultationTimeboxesParams
  >
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchLiveConsultationTimeboxes,
    onSuccess: (res, req, ctx) => {
      options?.onSuccess?.(res, req, ctx);
      void queryClient.invalidateQueries({
        queryKey: liveConsultationKeys.timeboxes()
      });
    },
    ...options
  });
};

export default usePatchLiveConsultationTimeboxes;
