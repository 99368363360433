import { cn } from '~utils/tailwind';

import Icon from '../../icon';
import { iconButtonStyle } from './styles';
import { IconButtonProps } from './types';

/**
 * @version v1.1.0
 * @author Danny
 * @description tertiary 버튼 텍스트 색상 변경
 */
const IconButton = ({
  name,
  priority,
  size,
  buttonType,
  iconStrokeColor,
  ...props
}: IconButtonProps) => {
  const { className } = props;

  return (
    <button
      {...props}
      type='button'
      className={cn(iconButtonStyle({ priority, size, buttonType }), className)}
    >
      <Icon name={name} stroke={iconStrokeColor} />
    </button>
  );
};

export default IconButton;
