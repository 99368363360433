import { cn } from '~utils/tailwind';

import Button from '../button/button/component';
import { ButtonProps } from '../button/button/types';

const DialogButton = ({
  type,
  className,
  disabled,
  children,
  onClick
}: ButtonProps) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      className={cn('w-full lg:w-fit', className)}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default DialogButton;
