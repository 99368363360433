import { produce } from 'immer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authKeys } from '~features/auth/hooks/queries/query-keys';
import { ProfileResponse } from '~features/auth/hooks/queries/use-my-profile';
import Vet from '~features/vet/models';
import { api } from '~utils/api';

export type PartialUpdateParams = Readonly<
  Pick<Vet.Level1, 'consultation_status'>
>;

const updateStatus = async ({ consultation_status }: PartialUpdateParams) =>
  (
    await api.patch<ProfileResponse>(`/vet/me/`, {
      consultation_status
    })
  ).data;

const useUpdateStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<ProfileResponse, Error, PartialUpdateParams>({
    mutationFn: updateStatus,
    onSuccess: (res) => {
      queryClient.setQueryData<ProfileResponse>(
        authKeys.myProfile(),
        produce((draft) => {
          if (draft?.vet) {
            draft.vet.consultation_status = res.vet.consultation_status;
          }
        })
      );
    }
  });
};

export default useUpdateStatus;
