import { cva } from 'class-variance-authority';

export const buttonPriority = cva('transition-colors', {
  variants: {
    priority: {
      primary: [
        'text-white bg-primary-500 fill-white stroke-white',
        'active:bg-primary-600',
        'disabled:bg-primary-100'
      ],
      secondary: [
        'text-primary-500 bg-primary-50 fill-primary-500 stroke-primary-500',
        'active:bg-primary-100',
        'disabled:bg-gray-100 disabled:text-gray-300 disabled:fill-gray-300 disabled:stroke-gray-300'
      ],
      tertiary: [
        'text-gray-700 bg-gray-100 fill-gray-700 stroke-gray-700',
        'active:bg-gray-200',
        'disabled:bg-white disabled:text-gray-300 disabled:fill-gray-300 disabled:stroke-gray-300'
      ]
    }
  }
});
