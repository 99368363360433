import { ForwardedRef, forwardRef } from 'react';
import { cn } from '~utils/tailwind';

import Badge from '../badge';

type TabMenuProps = Readonly<{
  index: number;
  children: string;
  isActive?: boolean;
  showNotification?: boolean;
  onTabPress?: (index: number) => void;
}>;

/**
 * @version v1.0.2
 * @author Danny
 * @description showNotification prop 추가
 */
const TabMenu = forwardRef(
  (
    {
      index,
      children,
      isActive,
      showNotification = false,
      onTabPress
    }: TabMenuProps,
    ref: ForwardedRef<HTMLButtonElement[] | null>
  ) => {
    const { current } = ref as React.MutableRefObject<HTMLButtonElement[]>;

    return (
      <button
        type='button'
        ref={(el: HTMLButtonElement) => {
          if (typeof ref !== 'function') {
            if (current !== null) {
              current[index] = el;
            }
          }
        }}
        className='relative px-3 py-4'
        onClick={() => {
          onTabPress && onTabPress(index);
        }}
      >
        <span
          className={cn(
            isActive ? 'text-gray-900' : 'text-gray-400',
            'transition-colors text-title-3'
          )}
        >
          {children}
        </span>
        {showNotification && (
          <div className='absolute right-1 top-2'>
            <Badge />
          </div>
        )}
      </button>
    );
  }
);

TabMenu.displayName = 'WebHeader';

export default TabMenu;
