import { createContext, useContext } from 'react';

export type DialogType = 'prompt' | 'alert';

interface ContextType {
  type: DialogType;
}

const initialContext: ContextType = {
  type: 'prompt'
};

export const DialogContext = createContext(initialContext);

export const useDialogContext = () => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error(
      'useDialogContext must be used within a DialogContextProvider'
    );
  }

  return context;
};
