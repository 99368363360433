import {
  ComponentProps,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { cn } from '~utils/tailwind';

import Icon from '../../icon';
import { useSelectBox } from './context';
import { SelectBoxData } from './types';

type Props = ComponentProps<'ul'> & {
  data: SelectBoxData[];
  onItemClick?: (item: SelectBoxData) => void;
};

const OPACITY_TRANSITION_TIMING = 150;

const SelectBoxDropdownItemGroup = ({
  children,
  data,
  onItemClick,
  ...props
}: Props) => {
  const { className } = props;
  const { open, selectedData, closeDropdown, searchValue, onSearch, onSelect } =
    useSelectBox();
  const dropdownRef = useRef<HTMLUListElement>(null);
  const optionRefs = useRef<Array<HTMLLIElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const onItemSelect = useCallback(
    (item: SelectBoxData) => {
      onSelect(item);
      onItemClick && onItemClick(item);
      setTimeout(() => {
        onSearch('');
      }, OPACITY_TRANSITION_TIMING);
    },
    [onItemClick, onSearch, onSelect]
  );

  const filteredData = useMemo(
    () =>
      searchValue.length
        ? data.filter((item) =>
            item.label.toLowerCase().includes(searchValue.toLowerCase())
          )
        : data,
    [data, searchValue]
  );

  const hasResult = useMemo(() => !!filteredData?.length, [filteredData]);

  const handleKeyDown = (e: KeyboardEvent<HTMLUListElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex(
        activeIndex + 1 === filteredData.length ? 0 : activeIndex + 1
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex(
        activeIndex <= 0 ? filteredData.length - 1 : activeIndex - 1
      );
    } else if (e.key === 'Enter') {
      onItemSelect(filteredData[activeIndex]);
      closeDropdown();
    }
  };

  useEffect(() => {
    if (open) {
      dropdownRef?.current?.focus();
    }
  }, [open]);

  // select 후 다시 드랍다운 열었을 때 이전에 선택한 아이템 활성화
  useEffect(() => {
    if (selectedData) {
      const _activeIndex = filteredData.findIndex(
        ({ value }) => value === selectedData.value
      );

      setActiveIndex(_activeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 드랍다운 내 키보드 이벤트에 따른 드랍다운 스크롤 위치 컨트롤
  useEffect(() => {
    if (open && optionRefs?.current[activeIndex]) {
      const selectedLi = optionRefs.current[activeIndex] as HTMLLIElement;
      const dropdownPaddingY = 8 * 2; // ul 태그 padding y 값;

      if (selectedLi && dropdownRef.current) {
        dropdownRef.current.scrollTop =
          selectedLi.offsetTop - selectedLi.clientHeight - dropdownPaddingY;
      }
    }
  }, [open, activeIndex]);

  return (
    <ul
      {...props}
      role='listbox'
      tabIndex={0}
      className={cn(
        'h-fit overflow-y-scroll p-2 outline-none',
        className,
        !hasResult && 'flex h-16 items-center justify-center'
      )}
      aria-activedescendant={filteredData[activeIndex]?.value.toString()}
      onKeyDown={handleKeyDown}
      ref={dropdownRef}
    >
      {hasResult ? (
        filteredData.map((item, index) => (
          <li
            ref={(el) => {
              optionRefs.current[index] = el;
            }}
            key={item.value.toString()}
            role='option'
            aria-selected={activeIndex === index}
            onClick={() => {
              onItemSelect(item);
            }}
            onKeyDown={() => {
              onItemSelect(item);
            }}
            className={cn(
              'relative flex w-full cursor-pointer items-center rounded-lg px-4 py-3 text-gray-900 outline-none text-body-1 hover:bg-gray-100',
              !isHovered && 'aria-selected:bg-gray-100'
            )}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            {item.icon}
            {item.label}
            {selectedData?.value === item.value && (
              <Icon
                name='check_mark'
                className='absolute right-4 top-1/2 h-6 w-6 -translate-y-1/2 fill-primary-500'
              />
            )}
          </li>
        ))
      ) : (
        <li className='text-gray-400 text-body-1'>No result</li>
      )}
    </ul>
  );
};

export default SelectBoxDropdownItemGroup;
