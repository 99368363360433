import { cn } from '~utils/tailwind';

import { labelStyle } from './styles';
import { LabelProps } from './types';

/**
 * @version v1.0.3
 * @author Danny
 * @description isShowBadge prop 제거
 */
export const Label = ({
  children,
  color,
  size,
  ...props
}: Readonly<LabelProps>) => {
  const { className } = props;

  return (
    <div
      className={cn(
        labelStyle({ color, size }),
        'flex items-center gap-2',
        className
      )}
    >
      {children}
    </div>
  );
};
