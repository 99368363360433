import { useEffect, useRef, useState } from 'react';
import { useInView as useInViewLib } from 'react-intersection-observer';

import type {
  InViewHookResponse,
  IntersectionOptions
} from 'react-intersection-observer';

/**
 * @see {@link https://github.com/thebuilder/react-intersection-observer/issues/495}
 */
const loadIntersectionObserver = async () =>
  await import('intersection-observer');
const hasNativeIntersectionObserver =
  typeof IntersectionObserver !== 'undefined';
let isIntersectionObserverAvailable = hasNativeIntersectionObserver;

/**
 * React Hooks make it easy to monitor the inView state of your components.
 * Call the useInView hook with the (optional) options you need.
 * It will return an array containing a ref, the inView status and the current entry.
 * Assign the ref to the DOM element you want to monitor, and the hook will report the status.
 *
 * With lazy polyfill
 */
const useInView: typeof useInViewLib = hasNativeIntersectionObserver
  ? useInViewLib
  : (args?: IntersectionOptions) => {
      const noopRef = useRef(null);
      const result = useInViewLib(args);
      const [isIntersectionObserverReady, setIsIntersectionObserverReady] =
        useState<boolean>(isIntersectionObserverAvailable);
      useEffect(() => {
        if (isIntersectionObserverAvailable) {
          return;
        }
        let isMounted = true;
        void loadIntersectionObserver().then(() => {
          isIntersectionObserverAvailable = true;
          if (isMounted) {
            setIsIntersectionObserverReady(true);
          }
        });
        return () => {
          isMounted = false;
        };
      }, []);
      return isIntersectionObserverReady
        ? result
        : ({
            ...result,
            ref: noopRef as any
          } satisfies InViewHookResponse);
    };

export default useInView;
