import Pet from '~features/pet/models';
import { getDayjsFormat, getReservationEndTime } from '~utils/format/dayjs';

import { TicketStatus, TicketType } from '../types';

export class LiveConsultationTicket {
  id: number;
  pet: Pet.Level1 | null; // 펫이 없는 상태로 티켓을 생성할 수 있으므로 null 허용 ※이 경우 수의사가 상담종료후 펫 생성해줌
  ticket_type: TicketType; // python 문법에서 'type'은 예약어이므로 ticket_type으로 변경
  ticket_status: TicketStatus;
  category_name: string | null;
  created_at: Date;
  scheduled_at: Date | null;
  schedule_from_to_formatted: string | null;

  constructor(props: Readonly<LiveConsultationTicket>) {
    this.id = props.id;
    this.category_name = props.category_name;
    this.pet = props.pet;
    this.created_at = props.created_at;
    this.scheduled_at = props.scheduled_at;
    this.ticket_type = props.ticket_type;
    this.ticket_status = props.ticket_status;
    this.schedule_from_to_formatted = null;

    if (this.scheduled_at) {
      const from = getDayjsFormat(this.scheduled_at, 'short');
      const to = getReservationEndTime(this.scheduled_at);
      this.schedule_from_to_formatted = `${from} ~ ${to}`;
    }
  }
}
