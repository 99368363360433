import Ably from 'ably';
import { useMemo } from 'react';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';
import { ably_api } from '~utils/api';

const useMyAblyTokenRequest = () => {
  const { data: myProfile } = useMyProfile();

  const ablyClient = useMemo(
    () =>
      myProfile?.vet.id
        ? new Ably.Realtime.Promise({
            authCallback: (_, callback) => {
              void ably_api
                .post('/account/ably_token_request/', undefined, {})
                .then((response) => {
                  const { data } = response;
                  callback(null, {
                    keyName: data.key_name,
                    clientId: data.client_id,
                    ...data
                  });
                });
            }
          })
        : undefined,
    [myProfile?.vet.id]
  );

  return {
    ablyClient
  };
};

export default useMyAblyTokenRequest;
