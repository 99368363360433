import { cn } from '~utils/tailwind';

import { AvatarProps, AvatarSize, AvatarType } from './types';
import {
  DEFAULT_THUMBNAIL_PERSON,
  DEFAULT_THUMBNAIL_PET,
  DEFAULT_THUMBNAIL_VET
} from '~constants';

const AVATAR_SIZE_CSS: { [key in AvatarSize]: string } = {
  '24': 'h-6 w-6',
  '32': 'h-8 w-8',
  '40': 'h-10 w-10',
  '48': 'h-12 w-12',
  '64': 'h-16 w-16',
  '72': 'h-[72px] w-[72px]',
  '96': 'h-24 w-24',
  '128': 'h-32 w-32'
};

const defaultUrl = (type: AvatarType) => {
  switch (type) {
    case 'person':
      return DEFAULT_THUMBNAIL_PERSON;
    case 'pet':
      return DEFAULT_THUMBNAIL_PET;
    case 'vet':
      return DEFAULT_THUMBNAIL_VET;
    default:
      return DEFAULT_THUMBNAIL_PERSON;
  }
};

/**
 * @version v1.1.2
 * @author Maggie
 * @description props 추가 - type
 */
const Avatar = ({
  uri,
  alt = 'thumbnail',
  size = '40',
  type = 'person'
}: AvatarProps) => {
  return (
    <div
      className={cn(
        'shrink-0 overflow-hidden rounded-full border border-solid border-gray-200 bg-gray-200',
        AVATAR_SIZE_CSS[size]
      )}
    >
      <img
        src={uri || defaultUrl(type)}
        alt={alt}
        width={size}
        height={size}
        className={AVATAR_SIZE_CSS[size]}
        style={{
          objectFit: 'cover'
        }}
      />
    </div>
  );
};

export default Avatar;
