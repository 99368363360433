namespace App {
  /**
   * @todo 애즈먼 Push API들 레거시 정리되면 Message 스킴 싱크 맞춰야 함
   */
  export class Message {
    id: number = 0;
    message_type_id: Message.Type = Message.Type.Consultation;
    title: string = '';
    icon: Message.Icon = 'bell';
    sent_at?: string = '';
    has_read?: boolean = false;
    body?: string;
    deeplink?: string;

    constructor(json?: Message) {
      if (json) {
        Object.assign(this, json);
      }
    }
  }

  export namespace Message {
    export enum Type {
      Consultation = '5',
      LiveConsultation = '6'
    }

    export type Icon = 'bell' | 'video' | 'calendar';
  }
}

export default App;
