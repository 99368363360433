import {
  ComponentProps,
  FocusEvent,
  useCallback,
  useMemo,
  useState
} from 'react';
import { cn } from '~utils/tailwind';

import { SelectBoxContext } from './context';
import SelectBoxDropdown from './dropdown';
import SelectBoxDropdownItemGroup from './dropdown-item-group';
import SelectBoxDropdownSearch from './dropdown-search';
import SelectBoxInput from './input';
import { SelectBoxData } from './types';

type Props = ComponentProps<'div'>;

const OPACITY_TRANSITION_TIMING = 150;

/**
 * @version v1.0.1
 * @author Danny
 */
const SelectBox = ({ children, ...props }: Props) => {
  const { className } = props;
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<SelectBoxData | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const handleDropdown = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const closeDropdown = useCallback(
    (e?: FocusEvent<HTMLDivElement, Element>) => {
      if (!e?.currentTarget.contains(e.relatedTarget)) {
        setOpen(false);
        setTimeout(() => {
          setSearchValue('');
        }, OPACITY_TRANSITION_TIMING);
      }
    },
    []
  );

  const onSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const onSelect = useCallback(
    (data: SelectBoxData) => {
      setSelectedData(data);
      closeDropdown();
    },
    [closeDropdown]
  );

  const contexts = useMemo(
    () => ({
      open,
      searchValue,
      selectedData,
      onSearch,
      onSelect,
      handleDropdown,
      closeDropdown
    }),
    [
      open,
      searchValue,
      selectedData,
      onSearch,
      onSelect,
      handleDropdown,
      closeDropdown
    ]
  );

  return (
    <SelectBoxContext.Provider value={contexts}>
      <div
        role='menu'
        tabIndex={0}
        className={cn('relative w-[312px] outline-none', className)}
        onBlur={closeDropdown}
      >
        {children}
      </div>
    </SelectBoxContext.Provider>
  );
};

SelectBox.Input = SelectBoxInput;
SelectBox.Dropdown = SelectBoxDropdown;
SelectBox.DropdownSearch = SelectBoxDropdownSearch;
SelectBox.DropdownItemGroup = SelectBoxDropdownItemGroup;

/**
 * @usage
 */
// const data = [
//   {
//     label: 'React',
//     value: 0,
//   },
//   {
//     label: 'Next.js',
//     value: 1,
//   },
//   {
//     label: 'Vue',
//     value: 2,
//   },
// ];

// <SelectBox>
//  <SelectBox.Input />
//  <SelectBox.Dropdown>
//    <SelectBox.DropdownSearch />
//    <SelectBox.DropdownItemGroup
//      data={data}
//      onItemClick={(item) => something(item)}
//      className="max-h-[200px]"
//    />
//  </SelectBox.Dropdown>
// </SelectBox>

export default SelectBox;
