import { AblyProvider } from 'ably/react';
import { useEffect, useMemo, useState } from 'react';
import useMyAblyTokenRequest from '~features/app/hooks/use-my-ably-token-request';

import AblyEventProvider from './ably-event-provider';

interface AblyProviderWithAuthorizedProps {
  children: JSX.Element;
}
const AblyProviderWithAuthorized = ({
  children
}: Readonly<AblyProviderWithAuthorizedProps>) => {
  const { ablyClient } = useMyAblyTokenRequest();
  const [clientId, setClientId] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!ablyClient) {
      return;
    }
    ablyClient.connection.on('connected', () => {
      setClientId(ablyClient.auth.clientId);
    });
  }, [ablyClient]);

  const isAblyClientConnected = useMemo(
    () => !!(ablyClient && clientId),
    [ablyClient, clientId]
  );

  return isAblyClientConnected ? (
    <AblyProvider client={ablyClient}>
      <AblyEventProvider clientId={clientId!}>{children}</AblyEventProvider>
    </AblyProvider>
  ) : (
    children
  );
};

export default AblyProviderWithAuthorized;
