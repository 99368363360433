import Owner from '~features/owner/models';
import Pet from '~features/pet/models';
import Vet from '~features/vet/models';

namespace Consultation {
  export class Level1 {
    id: number;
    details: string;
    main_concern: string;
    status: State; // PENDING(0), OCCUPIED(1), ANSWERED(2), ADDITIONAL(3)
    pet: Pet.Level1;
    created_at: string;

    constructor(props: Readonly<Level1>) {
      this.id = props.id;
      this.details = props.details;
      this.main_concern = props.main_concern;
      this.status = props.status;
      this.pet = props.pet;
      this.created_at = props.created_at;
    }
  }

  export class Level2 extends Level1 {
    owner: Owner.Level1;
    checkup_data: Record<string, string>;
    conversation: Message.Response[];
    generated_title: string;
    vet: Vet.Level1;

    constructor(props: Readonly<Level2>) {
      super(props);
      this.owner = props.owner;
      this.vet = props.vet;
      this.checkup_data = props.checkup_data;
      this.conversation = props.conversation;
      this.generated_title = props.generated_title;
    }
  }

  export enum State {
    Pending,
    Occupied,
    Answered,
    Additional
  }

  export namespace Message {
    export type Sender = 'Owner' | 'Vet';

    export class Request {
      sender: Sender;
      text: string;
      photo_urls: string[];
      video_urls: string[];

      constructor(props: Request) {
        this.sender = props.sender;
        this.text = props.text;
        this.photo_urls = props.photo_urls;
        this.video_urls = props.video_urls;
      }
    }

    export class Response extends Request {
      receiver_checked_at: string;
      created_at: string;

      constructor(props: Response) {
        super(props);
        this.receiver_checked_at = props.receiver_checked_at;
        this.created_at = props.created_at;
      }
    }
  }
}

export default Consultation;
