import { cva } from 'class-variance-authority';

export const textButtonStyle = cva(
  ['cursor-pointer', 'disabled:cursor-default'],
  {
    variants: {
      priority: {
        primary: ['text-primary-500', 'disabled:text-primary-100'],
        tertiary: ['text-gray-700', 'disabled:text-gray-300'],
        destructive: ['text-red-500', 'disabled:text-red-100']
      },
      size: {
        large: ['text-title-3 px-2 py-4'],
        small: ['text-label-1 p-2']
      }
    },
    defaultVariants: {
      priority: 'tertiary',
      size: 'small'
    }
  }
);
