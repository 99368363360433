import { produce } from 'immer';
import { useMemo, useRef, useState } from 'react';
import IconButton from '~components/@ui/button/icon-button/component';
import { cn } from '~utils/tailwind';

const PERIOD = 15;
const HOUR_GAP = 60 / PERIOD;
const NOON_GAP = 720 / PERIOD;
const COUNT = (60 * 24) / PERIOD;

if (COUNT.toString().includes('.')) {
  throw new Error('COUNT must be an integer');
}

type PeriodType = {
  time: Date;
  state: PeriodState;
  label: string;
};

interface HintProps {
  label: string;
  color: string;
}
const Hint = ({ color, label }: Readonly<HintProps>) => {
  return (
    <div className='flex gap-2'>
      <div
        className='aspect-square w-5 rounded-sm'
        style={{
          backgroundColor: color
        }}
      />
      <p className='text-gray-500 text-label-2'>{label}</p>
    </div>
  );
};

export enum PeriodState {
  // DO NOT USE 0
  BUSY = 1,
  AVAILABLE,
  DISABLED
}

interface PeriodProps {
  period: PeriodType;
  index: number;
  endAt: number;
  startAt: number;
  onClick: (triggerItem: PeriodType) => void;
}
const Period = ({
  period,
  index,
  startAt,
  endAt,
  onClick
}: Readonly<PeriodProps>) => {
  const { state, time } = period;
  const bgColor = useMemo(() => {
    switch (state) {
      case PeriodState.BUSY:
        return 'bg-red-100';
      case PeriodState.AVAILABLE:
        return 'bg-primary-100';
      case PeriodState.DISABLED:
        return 'bg-gray-200';
      default:
        return 'bg-gray-200';
    }
  }, [state]);

  return (
    <button
      className={cn(
        'w-full flex-1',
        bgColor,
        index === startAt && 'rounded-l-md',
        index === endAt - 1 && 'rounded-r-md',
        index % HOUR_GAP === HOUR_GAP - 1
          ? 'border-r-[1px] border-r-white'
          : 'border-r-[1px] border-r-gray-400'
      )}
      type='button'
      aria-label={`period-btn-${time.getTime()}`}
      onClick={
        period.state !== PeriodState.DISABLED
          ? () => {
              onClick(period);
            }
          : undefined
      }
    />
  );
};

interface PeriodSelectorProps {
  value: Record<string, { state: PeriodState; to?: PeriodState }>;
  onValueChanged: (
    value: Record<string, { state: PeriodState; to?: PeriodState }>
  ) => void;
  selectedDate: Date;
  hideDisabled?: boolean;
  fulfill?: boolean;
}
const PeriodSelector = ({
  value,
  selectedDate,
  onValueChanged,
  hideDisabled,
  fulfill
}: Readonly<PeriodSelectorProps>) => {
  const containerRef = useRef<HTMLUListElement>(null);

  const periods: PeriodType[] = useMemo(() => {
    return Array.from({ length: COUNT }, (_, index) => {
      const time = new Date(selectedDate);
      time.setHours(
        Math.floor(index / HOUR_GAP),
        (index % HOUR_GAP) * PERIOD,
        0,
        0
      );
      const valueKey = time.toJSON();
      // @see: DO NOT USE 0
      let state =
        value[valueKey]?.to || value[valueKey]?.state || PeriodState.BUSY;
      if (new Date() > time) {
        state = PeriodState.DISABLED;
      }
      const label = index % HOUR_GAP === 0 ? `${index / HOUR_GAP}:00` : '';
      return {
        time,
        state,
        label
      };
    });
  }, [value, selectedDate]);

  const startPeriodIndex = useMemo(() => {
    return hideDisabled
      ? periods.findIndex((period) => period.state !== PeriodState.DISABLED)
      : 0;
  }, [hideDisabled, periods]);

  const [isHover, setIsHover] = useState(false);

  const onMouseEnter = () => {
    setIsHover(true);
  };

  const onMouseLeave = () => {
    setIsHover(false);
  };

  const onScrollLeft = () => {
    containerRef.current?.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const onScrollRight = () => {
    containerRef.current?.scrollBy({ left: 200, behavior: 'smooth' });
  };

  const onPeriodClick = (triggerItem: PeriodType) => {
    const valueKey = triggerItem.time.toJSON();
    onValueChanged(
      produce(value, (draft) => {
        if (draft[valueKey]) {
          if (draft[valueKey].to !== undefined) {
            draft[valueKey].to = undefined;
          } else {
            draft[valueKey] = {
              ...draft[valueKey],
              to:
                draft[valueKey].state === PeriodState.BUSY
                  ? PeriodState.AVAILABLE
                  : PeriodState.BUSY
            };
          }
        } else {
          draft[valueKey] = {
            state: triggerItem.state,
            to:
              triggerItem.state === PeriodState.BUSY
                ? PeriodState.AVAILABLE
                : PeriodState.BUSY
          };
        }
      })
    );
  };

  return (
    <div
      className={cn(
        'relative flex w-full flex-col gap-4',
        !fulfill && 'max-w-md'
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <section className='relative'>
        {isHover && (
          <>
            <IconButton
              className='absolute bottom-0 left-0'
              name='arrow_left'
              priority='tertiary'
              size='small'
              onClick={onScrollLeft}
            />
            <IconButton
              className='absolute bottom-0 right-0'
              name='arrow_right'
              priority='tertiary'
              size='small'
              onClick={onScrollRight}
            />
          </>
        )}
        <ul
          className='no-scrollbar flex min-h-[72px] flex-nowrap overflow-x-auto overflow-y-hidden px-10'
          ref={containerRef}
        >
          {periods.map((period, index) =>
            !hideDisabled || period.state !== PeriodState.DISABLED ? (
              <li
                key={period.time.getTime()}
                className='flex flex-col justify-end'
              >
                <span
                  className={cn(
                    'flex flex-1 flex-col justify-end text-gray-500 text-label-3'
                  )}
                >
                  {index % NOON_GAP === 0 && (
                    <span className='text-gray-700'>
                      {index === 0 ? 'AM' : 'PM'}
                    </span>
                  )}
                  {period.label}
                </span>
                <div
                  className={cn(
                    'h-2 w-full min-w-[28px]',
                    index % HOUR_GAP === HOUR_GAP - 1 &&
                      'border-r-[1px] border-r-gray-700'
                  )}
                />
                <Period
                  period={period}
                  index={index}
                  startAt={startPeriodIndex}
                  endAt={periods.length}
                  onClick={onPeriodClick}
                />
              </li>
            ) : null
          )}
        </ul>
      </section>
      <section className='flex gap-6'>
        <Hint color='rgb(249, 193, 190)' label='Busy' />
        <Hint color='rgb(199, 219, 253)' label='Available' />
        <Hint color='rgb(229, 232, 235)' label='Disabled' />
      </section>
    </div>
  );
};

export default PeriodSelector;
