import { ComponentProps, FocusEvent, useCallback, useState } from 'react';

export type InputPrimitiveProps = ComponentProps<'input'>;
export type TextAreaPrimitiveProps = ComponentProps<'textarea'>;

type CustomizedInputProps<T> = {
  readOnly?: boolean;
  onFocus?: (e: FocusEvent<T, Element>) => void;
  onBlur?: (e: FocusEvent<T, Element>) => void;
};

type TextInputElement = HTMLInputElement | HTMLTextAreaElement;

export const useCustomizedInputProps = <
  T extends TextInputElement,
  K extends 'input' | 'textarea'
>(
  props: ComponentProps<K> & CustomizedInputProps<T>
) => {
  const { readOnly, onFocus: _onFocus, onBlur: _onBlur } = props;

  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(
    (e: FocusEvent<T, Element>) => {
      if (readOnly) {
        return;
      }

      _onFocus?.(e);
      setIsFocused(true);
    },
    [_onFocus, readOnly]
  );

  const onBlur = useCallback(
    (e: FocusEvent<T, Element>) => {
      if (readOnly) {
        return;
      }

      _onBlur?.(e);
      setIsFocused(false);
    },
    [_onBlur, readOnly]
  );

  return {
    isFocused,
    inputProps: {
      ...props,
      onFocus,
      onBlur
    }
  };
};
