import { cva } from 'class-variance-authority';

import { buttonPriority } from '../@base.styles';

export const buttonStyle = cva(
  'flex w-fit h-fit flex-row items-center justify-center',
  {
    variants: {
      priority: {
        primary: buttonPriority({ priority: 'primary' }),
        secondary: buttonPriority({ priority: 'secondary' }),
        tertiary: buttonPriority({ priority: 'tertiary' }),
        destructive: [
          'text-white bg-red-500 fill-white',
          'active:bg-red-600',
          'disabled:bg-red-100'
        ]
      },
      size: {
        large: [
          'px-5 py-4',
          'text-title-2',
          'rounded-2xl',
          '[&>svg]:w-6 [&>svg]:h-6'
        ],
        medium: [
          'px-4 py-3',
          'text-title-3',
          'rounded-xl',
          '[&>svg]:w-5 [&>svg]:h-5'
        ],
        small: ['p-2', 'text-label-1', 'rounded-lg', '[&>svg]:w-5 [&>svg]:h-5']
      }
    },
    defaultVariants: {
      priority: 'primary',
      size: 'large'
    }
  }
);
