import { RemoteAudioTrack, RemoteParticipant } from 'twilio-video';
import useParticipants from '~twilio/hooks/use-participants';
import useTracks from '~twilio/hooks/use-tracks';
import useVideoContext from '~twilio/hooks/use-video-context';

import { AudioTrack } from './audio-track';

/*
  This ParticipantAudioTracks component will render the audio track for all participants in the room.
  It is in a separate component so that the audio tracks will always be rendered, and that they will never be
  unnecessarily unmounted/mounted as the user switches between Gallery View and Speaker View.
*/

const ParticipantAudioTrack = ({
  participant
}: {
  participant: RemoteParticipant;
}) => {
  const tracks = useTracks(participant);
  const audioTrack = tracks.find((track) => track.kind === 'audio') as
    | RemoteAudioTrack
    | undefined;

  if (audioTrack?.kind === 'audio') {
    return <AudioTrack track={audioTrack} />;
  }

  return null;
};

export const ParticipantAudioTracks = () => {
  const { room } = useVideoContext();
  const participants = useParticipants();

  return (
    <>
      {participants
        .filter((participant) => participant.sid !== room?.localParticipant.sid)
        .map((participant) => (
          <ParticipantAudioTrack
            key={participant.sid}
            participant={participant}
          />
        ))}
    </>
  );
};
