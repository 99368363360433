import { memo } from 'react';
import { cn } from '~utils/tailwind';

import TextButton from '../button/text-button/component';
import { TextButtonProps } from '../button/text-button/types';
import Icon from '../icon';

const DialogTextButton = ({
  type,
  priority = 'tertiary',
  size = 'large',
  children,
  className,
  onClick,
  trailingIcon,
  disabled
}: TextButtonProps) => {
  return (
    <TextButton
      type={type}
      disabled={disabled}
      size={size}
      priority={priority}
      onClick={onClick}
      className={cn(className, 'flex')}
    >
      {trailingIcon && (
        <Icon
          name={trailingIcon}
          width={20}
          height={20}
          className='mr-2 fill-gray-500'
        />
      )}
      {children}
    </TextButton>
  );
};

export default memo(DialogTextButton);
