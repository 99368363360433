import { IVideoTrack } from 'src/types';
import {
  LocalTrackPublication,
  RemoteTrackPublication,
  Track
} from 'twilio-video';
import useTrack from '~twilio/hooks/use-track';

import { VideoTrack } from './video-track';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  // participant: Participant;
  isLocalParticipant?: boolean;
  // videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
  paused?: boolean;
}

export const Publication = ({
  publication,
  isLocalParticipant,
  videoPriority,
  paused
}: PublicationProps) => {
  const track = useTrack(publication);

  if (!track) {
    return null;
  }

  // Even though we only have one case here, let's keep this switch() in case
  // we even need to add a 'data' case for rendering DataTracks.
  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={!track.name.includes('screen') && isLocalParticipant}
          paused={paused}
        />
      );
    // All participant audio tracks are rendered in ParticipantAudioTracks.tsx
    default:
      return null;
  }
};
