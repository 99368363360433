import { ChangeEvent, FormEventHandler, ForwardedRef, forwardRef } from 'react';
import { cn } from '~utils/tailwind';

import TextInputHelper from './@base/helper/component';
import { TextInputHelperProps } from './@base/helper/types';
import { TextAreaPrimitiveProps, useCustomizedInputProps } from './@base/hooks';
import TextInputLabel from './@base/label/component';
import { TextInputLabelProps } from './@base/label/types';

type CommonProps = {
  error: boolean;
  helperMessage: TextInputHelperProps['helperMessage'];
  showHelper: boolean;
  showMaxLength: boolean;
  isAutoResizeMode: boolean;
};

type BoxTypeProps = CommonProps & {
  type: 'box';
  label: TextInputLabelProps['children'];
};

type OutlineTypeProps = CommonProps & {
  type: 'outline';
  label: never;
};

type TextAreaProps = Readonly<
  TextAreaPrimitiveProps & Partial<BoxTypeProps | OutlineTypeProps>
>;

/**
 * @version v1.1.1
 * @author Sabo
 * @summary isAutoResizeMode prop added
 */
const TextArea = forwardRef(
  (
    {
      type = 'box',
      label,
      helperMessage,
      showHelper = false,
      showMaxLength = false,
      isAutoResizeMode = false,
      error,
      ...props //
    }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const { isFocused, inputProps } = useCustomizedInputProps<
      HTMLTextAreaElement,
      'textarea'
    >(props);
    const { value, disabled, readOnly, maxLength, className, onChange } =
      inputProps;

    const valueLength = value?.toString()?.length;

    const textareaAutoResize: FormEventHandler<HTMLTextAreaElement> = (e) => {
      const element = e.target as HTMLTextAreaElement;
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    };

    const mergedOnChangeEvents = (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(e);
      isAutoResizeMode && textareaAutoResize(e);
    };

    return (
      <div className='flex flex-col'>
        {type === 'box' && label && (
          <TextInputLabel
            isFocused={isFocused}
            isError={error}
            isDisabled={disabled}
          >
            {label}
          </TextInputLabel>
        )}
        <textarea
          {...inputProps}
          ref={ref}
          className={cn(
            'h-full w-full',
            'no-scrollbar resize-none outline-none',
            'transition-colors motion-reduce:transition-none',
            'text-gray-900 text-body-1 placeholder:text-gray-400',
            type === 'box' &&
              cn(
                'rounded-xl border border-solid border-gray-100 bg-gray-50 p-4',
                {
                  'border-gray-200 bg-gray-100': isFocused,
                  'border-red-100 bg-red-50': error,
                  'border-gray-50 bg-gray-50 text-gray-300':
                    disabled ?? readOnly
                }
              ),
            type === 'outline' &&
              cn('bg-white text-gray-700', {
                'text-gray-900': isFocused,
                'text-red-500': error
              }),
            isAutoResizeMode && 'max-h-[300px] min-h-[100px]',
            className
          )}
          onChange={mergedOnChangeEvents}
          rows={isAutoResizeMode ? 1 : undefined}
        />
        {showHelper && (
          <TextInputHelper
            helperMessage={helperMessage}
            maxLength={maxLength}
            showMaxLength={showMaxLength}
            valueLength={valueLength}
            isError={error}
            isDisabled={disabled}
            readOnly={readOnly}
            className='mt-1'
          />
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
