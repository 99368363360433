import { AxiosError } from 'axios';
import { produce } from 'immer';
import { ID } from 'src/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { liveConsultationKeys } from '~features/live-consultation/hooks/queries/query-keys';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { TicketStatus, TicketType } from '~features/live-consultation/types';
import { api } from '~utils/api';

const acceptLiveConsultationTicket = async (id: ID) => {
  return (await api.post<LiveConsultation.Level2>(`/lcts/${id}/accept/`)).data;
};

const acceptScheduledLiveConsultationTicket = async (id: ID) => {
  return (await api.post<null>(`/lcts/${id}/book/`)).data;
};

// TODO: 추후 [일반,퀵]/[예약]상담 수락 프로세스를 앞단에서 분리할 예정(버튼 submit)
export const useAcceptLiveConsultationTicket = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ ticket }: { ticket: LiveConsultationTicket }) => {
      if (ticket.ticket_type === TicketType.Scheduled) {
        return await acceptScheduledLiveConsultationTicket(ticket.id);
      }
      return await acceptLiveConsultationTicket(ticket.id);
    },
    onSuccess: (res, variables) => {
      if (variables.ticket.ticket_type !== TicketType.Scheduled) {
        if (res?.room_access_token) {
          queryClient.setQueryData<LiveConsultation.Level2>(
            liveConsultationKeys.ongoingSession(),
            res
          );
        }
        void queryClient.setQueryData<LiveConsultationTicket[]>(
          liveConsultationKeys.tickets(),
          produce(
            (draft) => draft?.filter((item) => item.id !== variables.ticket.id)
          )
        );
      }
      if (variables.ticket.ticket_type === TicketType.Scheduled) {
        void queryClient.setQueryData<LiveConsultationTicket[]>(
          liveConsultationKeys.tickets(),
          produce(
            (draft) =>
              draft?.map((ticket) => {
                if (ticket.id === variables.ticket.id) {
                  return { ...ticket, ticket_status: TicketStatus.Booked };
                }
                return ticket;
              })
          )
        );
      }

      // 수락시 티켓 목록에서 제거[일반, 퀵, 예약]
    },
    onError: (error: AxiosError, variables) => {
      // 이미 제거된 티켓이면 lcts에서 제거 ※SocketMessage[VET_LCQ_OWNER_CANCELED, VET_QUICK_LCQ_REMOVED]를 못받았을 경우의 방어코드
      if (error?.response?.status === 404) {
        queryClient.setQueryData<LiveConsultationTicket[]>(
          liveConsultationKeys.tickets(),
          produce(
            (draft) => draft?.filter((item) => item.id !== variables.ticket.id)
          )
        );
      }
    }
  });
};
