import { FC, SVGProps } from 'react';
import * as icons from '~icons';

type SvgComponentProps = SVGProps<SVGElement>;

export type IconProps = Readonly<
  SvgComponentProps & {
    name: keyof typeof icons;
  }
>;

/**
 * @version v1.0.0
 * @author Danny
 * @description
 * - public/icons 폴더에 있는 svg 파일들의 name을 prop으로 받아 해당 svg를 렌더링합니다.
 * - 아이콘 파일 및 네이밍 규칙은 피그마와 동일하게 snake_case로 작성합니다.
 */
const Icon = ({ name, className, ...props }: IconProps) => {
  const SvgComponent = icons[name] as FC<SvgComponentProps>;

  return <SvgComponent {...props} name={name} className={className} />;
};

export default Icon;
