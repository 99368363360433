import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect, useMemo } from 'react';
import { closedLiveConsultationAtom } from '~features/live-consultation/atoms/closed-live-consultation';
import { useLiveConsultation } from '~features/live-consultation/hooks/queries/use-live-consultation';
import useCaptureError from '~hooks/use-capture-error';
import { ParticipantProvider } from '~twilio/components/participant-provider';
import { VideoProvider } from '~twilio/components/video-provider';
import useAudioAndVideoTracks from '~twilio/hooks/use-audio-and-video-tracks';
import useConnectionOptions from '~twilio/hooks/use-connection-options';
import useRoomState from '~twilio/hooks/use-room-state';
import { useTwilioRoomState } from '~twilio/state';

import Room from './room/component';
import StandBy from './stand-by/component';

const VideoChatContent = () => {
  useAudioAndVideoTracks();
  const roomState = useRoomState();
  const { data: lc } = useLiveConsultation();
  const [closedLiveConsultation, setClosedLiveConsultation] = useAtom(
    closedLiveConsultationAtom
  );

  useEffect(() => {
    if (lc) {
      setClosedLiveConsultation(RESET);
    }
  }, [lc, setClosedLiveConsultation]);

  const shouldShowRoomComponent = useMemo(
    () => closedLiveConsultation || roomState === 'connected',
    [closedLiveConsultation, roomState]
  );

  return (
    <div className='h-screen w-screen'>
      {shouldShowRoomComponent ? <Room /> : <StandBy />}
    </div>
  );
};

export const VideoChat = () => {
  const connectionOptions = useConnectionOptions();
  const { error, setError } = useTwilioRoomState();

  useCaptureError(error, 'twilio');

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ParticipantProvider>
        <VideoChatContent />
      </ParticipantProvider>
    </VideoProvider>
  );
};
