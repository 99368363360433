import clsx from 'clsx';
import Icon from '~components/@ui/dialog/icon';
import useLocalVideoToggle from '~twilio/hooks/use-local-video-toggle';

export const ToggleVideoButton = () => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  const iconName = isVideoEnabled ? 'video_solid' : 'video_mute_solid';

  const onPress = () => {
    toggleVideoEnabled();
  };

  return (
    <div
      className={clsx(
        'flex h-14 w-14 overflow-hidden rounded-full',
        !isVideoEnabled
          ? 'border-none bg-red-500'
          : 'border border-white bg-transparent'
      )}
    >
      <button
        className='flex h-full w-full items-center justify-center'
        onClick={onPress}
        aria-label='Toggle Video'
      >
        {/* TODO camera svg만 align이 안맞는 이슈(열받음) */}
        <Icon name={iconName} className='mt-6 h-6 w-6 fill-white' />
      </button>
    </div>
  );
};
