import { Header, HeaderTitle } from '~components/@local-ui/header';
import Table from '~components/table';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';
import useNoShowTimesPractice from '~features/no-shows/hooks/queries/use-no-show-times-practice';

const PRACTICE_TABLE_HEADER = [
  'DATE',
  'TIME',
  'PET NAME',
  'CHECKUP',
  'RECORDS',
  'SEVERITY'
];

const Practice = () => {
  const { data: myProfile } = useMyProfile();
  const { records } = useNoShowTimesPractice(myProfile?.vet.title || '');

  return (
    <main className='w-full'>
      <Header className='border-b border-solid border-gray-200'>
        <HeaderTitle>Practice</HeaderTitle>
      </Header>
      <Table titleList={PRACTICE_TABLE_HEADER}>
        {records.length > 0 ? (
          records.map(
            ({ date, time, pet_name, checkup, severity, medical_records }) => (
              <tr
                key={date + time}
                className='[&_td]:px-4 [&_td]:py-2 [&_td]:text-left [&_td]:text-gray-700 [&_td]:text-body-1'
              >
                <td>{date}</td>
                <td>{time}</td>
                <td>{pet_name}</td>
                <td>{checkup}</td>
                <td>{medical_records.join(', ')}</td>
                <td>{severity}</td>
              </tr>
            )
          )
        ) : (
          <tr>
            <td
              colSpan={PRACTICE_TABLE_HEADER.length}
              className='py-10 text-center text-gray-400'
            >
              No data
            </td>
          </tr>
        )}
      </Table>
    </main>
  );
};

export default Practice;
