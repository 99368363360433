import { PetCard } from '~components/@local-ui/live-consultation/pet-card';
import {
  InformationCard,
  InformationCardTitle
} from '~components/information-card';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { getDateFormat } from '~utils/date';

type TodayScheduleCardProps = Readonly<{
  todayScheduleList?: LiveConsultationTicket[];
}>;

export const TodayScheduleCard = ({
  todayScheduleList
}: TodayScheduleCardProps) => {
  const todayDate = getDateFormat(new Date().toISOString(), 'absolute');
  return (
    <InformationCard className='w-full'>
      <InformationCardTitle>
        Today’s Schedule
        <time className='text-gray-700 text-body-1'>{todayDate}</time>
      </InformationCardTitle>
      <div className='flex flex-row gap-3 p-6'>
        {todayScheduleList?.length ? (
          todayScheduleList.map((consultation, index) => (
            <PetCard
              key={consultation.id}
              {...consultation}
              isActive={index === 0}
            />
          ))
        ) : (
          <p className='text-gray-900 text-body-1'>
            No scheduled consultations today.
          </p>
        )}
      </div>
    </InformationCard>
  );
};
