import { useEffect } from 'react';

const useBlockScroll = (condition: boolean) => {
  useEffect(() => {
    if (condition) {
      document.body.style.overflowY = 'hidden';

      return () => {
        document.body.style.overflowY = 'unset';
      };
    }
  }, [condition]);
};

export default useBlockScroll;
