import { z } from 'zod';

const envSchema = z.object({
  VITE_ENVIRONMENT: z.string(),
  VITE_HOST: z.string().url(),
  VITE_ABLY_HOST: z.string().url(),
  VITE_STATIC_RES: z.string().url(),
  VITE_VET_INVITATION_BASE_URL: z.string().url()
});

const parsedEnv = envSchema.safeParse(import.meta.env);

if (!parsedEnv.success) {
  // eslint-disable-next-line no-console
  console.error(
    '❌ Invalid environment variables:',
    JSON.stringify(parsedEnv.error.format(), null, 4)
  );
  process.exit(1);
}

export const env = parsedEnv.data;
