namespace Pet {
  export class Level1 {
    id: number;
    name: string;
    kind: Kind;
    age: string; // 2 years, 4 months ...
    gender: Gender; // Male(0), Female(1), Unknown(2)
    is_neutered: boolean;
    thumbnail_url?: string;

    constructor(props: Readonly<Level1>) {
      this.id = props.id;
      this.name = props.name;
      this.kind = props.kind;
      this.age = props.age;
      this.gender = props.gender;
      this.is_neutered = props.is_neutered;
      this.thumbnail_url = props.thumbnail_url;
    }
  }

  interface Kind {
    species: string;
    breed: string;
  }

  export enum Gender {
    Male,
    Female,
    Unknown
  }
}

export default Pet;
