import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';
import { liveConsultationKeys } from '~features/live-consultation/hooks/queries/query-keys';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { api } from '~utils/api';

const fetchLiveConsultationTickets = async (): Promise<
  LiveConsultationTicket[]
> => {
  return (await api.get<LiveConsultationTicket[]>(`/vet/lcts/`)).data;
};

export const useLiveConsultationTickets = () => {
  const { data: myProfile } = useMyProfile();
  return useQuery<LiveConsultationTicket[], AxiosError>({
    queryKey: liveConsultationKeys.tickets(),
    queryFn: fetchLiveConsultationTickets,
    enabled: !!myProfile?.vet.id,
    select: (res) =>
      res
        .filter(({ ticket_status }) => ticket_status !== null)
        .map((ticket) => new LiveConsultationTicket(ticket))
  });
};
