import { atom } from 'jotai';

const initialValue = {
  isOpen: false
};
export const scheduledLiveConsultationOngoingAtom = atom<{
  isOpen: boolean;
} | null>(initialValue);
scheduledLiveConsultationOngoingAtom.debugLabel =
  'scheduledLiveConsultationOngoingAtom';
