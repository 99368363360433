import { produce } from 'immer';
import { useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { useCallback, useEffect, useMemo } from 'react';
import InnerTabBar, {
  InnerTabItemType
} from '~components/@local-ui/live-consultation/inner-tab-bar';
import { ReserveTimeboxButtonGroup } from '~components/@local-ui/live-consultation/timeboxes';
import Button from '~components/@ui/button/button/component';
import Spinner from '~components/@ui/loading-indicator/spinner';
import { toast } from '~components/@ui/toast/controller';
import usePatchLiveConsultationTimeboxes from '~features/live-consultation/hooks/mutations/use-patch-live-consultation-timeboxes';
import {
  ILiveConsultationTimeBox,
  MyLiveConsultationTimeBoxesType,
  Weekday,
  useLiveConsultationTimeboxes
} from '~features/live-consultation/hooks/queries/use-live-consultation-timeboxes';

const draftLiveConsultationTimeboxesAtom =
  atomWithReset<MyLiveConsultationTimeBoxesType | null>(null);

const RenderTimeboxes = ({
  item: items
}: Readonly<InnerTabItemType<ILiveConsultationTimeBox[]>>) => {
  const [draft, setDraft] = useAtom(draftLiveConsultationTimeboxesAtom);

  const onTimeItems = useMemo(
    () =>
      items.filter((item) => {
        const minutes = parseInt(item.start_time.split(':')[1], 10);
        return minutes === 0;
      }),
    [items]
  );

  const afterNoonItems = useMemo(
    () =>
      onTimeItems.filter((item) => {
        const time = parseInt(item.start_time.split(':')[0], 10);
        return time >= 12;
      }),
    [onTimeItems]
  );

  const beforeNoonItems = useMemo(
    () =>
      onTimeItems.filter((item) => {
        const time = parseInt(item.start_time.split(':')[0], 10);
        return time < 12;
      }),
    [onTimeItems]
  );

  const onTimeboxChange = (newValue: ILiveConsultationTimeBox) => {
    if (!draft) {
      return;
    }

    const timeboxes = draft[newValue.weekday];
    const newTimeboxes = timeboxes.map((timebox) =>
      timebox.start_time === newValue.start_time
        ? {
            ...timebox,
            is_enabled: !timebox.is_enabled,
            has_changed: timebox.has_changed ? undefined : true
          }
        : timebox
    );
    setDraft(
      produce(($draft) => {
        // eslint-disable-next-line no-param-reassign
        $draft[newValue.weekday] = newTimeboxes;
      })
    );
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-y-8'>
        <ReserveTimeboxButtonGroup
          timeboxes={beforeNoonItems}
          label='AM'
          onTimeboxChange={onTimeboxChange}
        />
        <ReserveTimeboxButtonGroup
          timeboxes={afterNoonItems}
          label='PM'
          onTimeboxChange={onTimeboxChange}
        />
      </div>
    </div>
  );
};

const AvailabilitySettingsTab = () => {
  const { data, flatData, numberOfSlotsByHour, isFetching, refetch } =
    useLiveConsultationTimeboxes();
  const { mutate: patchLiveConsultationTimeboxes } =
    usePatchLiveConsultationTimeboxes({
      onSuccess: () => {
        toast.open({
          type: 'success',
          message: 'Changes saved successfully'
        });
        void refetch();
      }
    });
  const [draft, setDraft] = useAtom(draftLiveConsultationTimeboxesAtom);
  const changes = useMemo(
    () =>
      draft
        ? Object.values(draft).flatMap((timeboxes) =>
            timeboxes.filter((timebox) => timebox.has_changed)
          )
        : [],
    [draft]
  );

  const onChangesApply = useCallback(() => {
    const changesAll = changes
      .map((primaryTimebox) => {
        const changedIndex = flatData.findIndex(
          (timebox) =>
            timebox.start_time === primaryTimebox.start_time &&
            timebox.weekday === primaryTimebox.weekday
        );
        if (changedIndex !== -1) {
          return flatData
            .slice(changedIndex, changedIndex + numberOfSlotsByHour)
            .map((timebox) => ({
              ...timebox,
              is_enabled: primaryTimebox.is_enabled
            }));
        }
        return [];
      })
      .flat();

    patchLiveConsultationTimeboxes(changesAll);
  }, [changes, flatData, numberOfSlotsByHour, patchLiveConsultationTimeboxes]);

  const onChangesDiscard = () => {
    data && setDraft(data);
  };

  useEffect(() => {
    if (data) {
      setDraft(data);
    }
  }, [data, setDraft]);

  if (isFetching || !draft) {
    return (
      <div className='absolute left-1/2 top-1/2 z-floated -translate-x-1/2 -translate-y-1/2'>
        <Spinner color='gray' />
      </div>
    );
  }

  return (
    <div className='flex w-full flex-col gap-y-5'>
      <h1 className='text-gray-900 text-headline-2'>Availability Settings</h1>
      <InnerTabBar<ILiveConsultationTimeBox[]>
        data={draft}
        labelExtractor={(key) => Weekday[parseInt(key, 10)]}
        renderItem={RenderTimeboxes}
        footer={
          changes.length > 0 ? (
            <div className='flex gap-x-3'>
              <Button priority='tertiary' onClick={onChangesDiscard}>
                Discard changes
              </Button>
              <Button onClick={onChangesApply}>Save changes</Button>
            </div>
          ) : undefined
        }
      />
    </div>
  );
};

export default AvailabilitySettingsTab;
