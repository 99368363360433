import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Pagination from '~components/@local-ui/pagination';
import SideSheet from '~components/@local-ui/side-sheet/component';
import { SIDE_SHEET_PAGE_ID } from '~components/@local-ui/side-sheet/constant';
import { useSideSheet } from '~components/@local-ui/side-sheet/hook';
import Table from '~components/table';
import { TodayScheduleCard } from '~components/today-schedule-card';
import useBookedLiveConsultations from '~features/live-consultation/hooks/queries/use-live-booked-consultation-tickets';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { getGender } from '~features/pet/utils';
import useDashboard from '~features/vet/hooks/queries/use-dashboard';
import usePagination from '~hooks/use-pagination';
import { getDayjsFormat } from '~utils/format/dayjs';

const SCHEDULE_TABLE_HEADER = [
  'PET NAME',
  'CONCERN',
  'TIME SLOT',
  'REQUESTED ON'
];

interface SideSheetContentsProps {
  schedule?: LiveConsultationTicket;
}

const SideSheetContents = ({ schedule }: SideSheetContentsProps) => {
  if (!schedule) {
    return null;
  }
  return (
    <div className='flex h-full flex-col overflow-y-scroll pb-5'>
      <div className='p w-full px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Session Information</p>
      </div>
      <div className='p w-full px-5 pb-7 pt-2'>
        <div className='mb-5 rounded-xl bg-gray-50 p-3'>
          <div className='flex flex-col gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Session Type</p>
            <p className='text-gray-900 text-label-2'>Scheduled</p>
          </div>
          <div className='flex flex-col gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Reserved time slot</p>
            <p className='text-gray-900 text-label-2'>
              {getDayjsFormat(schedule.scheduled_at, 'full')}
            </p>
          </div>
          <div className='flex flex-col gap-1 p-1'>
            <p className='text-gray-700 text-body-3'>Issue</p>
            <p className='text-gray-900 text-label-2'>
              {schedule.category_name || '-'}
            </p>
          </div>
        </div>
        {/* TODO 예약 취소 API프로세스가 정립되면 주석 제거 */}
        {/* <TextButton priority='destructive' size='small'>
          Cancel this schedule
        </TextButton> */}
      </div>
      <div className='p w-full px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Pet Information</p>
      </div>
      <div className='p w-full px-5 pb-7 pt-2'>
        <div className='mb-5 rounded-xl bg-gray-50 p-3'>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Name</p>
            <p className='text-gray-900 text-label-2'>{schedule.pet?.name}</p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Species</p>
            <p className='text-gray-900 text-label-2'>
              {schedule.pet?.kind.breed}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Breed</p>
            <p className='text-gray-900 text-label-2'>
              {schedule.pet?.kind.species}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Age</p>
            <p className='text-gray-900 text-label-2'>{schedule.pet?.age}</p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Gender</p>
            <p className='text-gray-900 text-label-2'>
              {getGender(schedule.pet?.gender)}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Status</p>
            <p className='text-gray-900 text-label-2'>
              {schedule.pet?.is_neutered ? 'Neutered or spayed' : '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// TODO: fetchScheduledList API가 추가되면 로컬 Pagination 코드 제거
export const ScheduleTab = () => {
  const { data: dashboard } = useDashboard();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const { data: listData, refetch } = useBookedLiveConsultations({ page });

  useEffect(() => {
    void refetch();
  }, [refetch]);

  const { pages, onPrevClick, onNextClick, onPageNumClick } = usePagination({
    currentPage: listData?.current_page,
    totalPageNum: listData?.total_page_num
  });

  const { openSideSheet, sideSheetProps } = useSideSheet();

  const selectedScheduleId = searchParams.get(SIDE_SHEET_PAGE_ID);

  const onItemClick = (id: number) => {
    openSideSheet(id);
  };

  const selectedSchedule = useMemo(
    () =>
      listData?.results?.find((item) => item.id === Number(selectedScheduleId)),
    [listData, selectedScheduleId]
  );

  return (
    <>
      <div className='flex h-full w-full flex-col gap-5'>
        <div className='mb-5 text-gray-900 text-headline-2'>Schedule</div>
        <TodayScheduleCard
          todayScheduleList={dashboard?.today_scheduled_live_consultations}
        />
        <div className='rounded-2xl border border-gray-300 p-6'>
          <Table titleList={SCHEDULE_TABLE_HEADER}>
            {listData?.results?.length ? (
              listData.results.map(
                ({ id, pet, scheduled_at, category_name, created_at }) => (
                  <tr
                    key={id}
                    className='cursor-pointer border-b border-gray-100'
                    onClick={() => {
                      onItemClick(id);
                    }}
                  >
                    <td className='p-4'>
                      <p className='line-clamp-2  whitespace-pre-wrap text-left text-body-1'>
                        {pet?.name}
                      </p>
                    </td>
                    <td className='p-4 text-left text-body-1'>
                      {category_name || '-'}
                    </td>
                    <td className='whitespace-nowrap p-4 text-left text-body-1'>
                      {getDayjsFormat(scheduled_at, 'longWithTime')}
                    </td>
                    <td className='whitespace-nowrap p-4 text-left text-body-1'>
                      {getDayjsFormat(created_at, 'longWithTime')}
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className='px-5 py-8 text-center text-gray-700 text-headline-3'
                >
                  No schedule
                </td>
              </tr>
            )}
          </Table>
          <Pagination
            currentPageNum={listData?.current_page || 0}
            totalPageNum={listData?.total_page_num || 0}
            pageNums={pages}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageNumClick={onPageNumClick}
          />
        </div>
      </div>
      <SideSheet
        {...sideSheetProps}
        isExpandable={false}
        className='max-w-[360px]'
      >
        <SideSheetContents schedule={selectedSchedule} />
      </SideSheet>
    </>
  );
};
