import { produce } from 'immer';
import { useSearchParams } from 'react-router-dom';
import { ID } from 'src/types';
import {
  MutationObserverOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { toast } from '~components/@ui/toast/controller';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { api } from '~utils/api';

import { liveConsultationKeys } from '../queries/query-keys';

export type PatchLiveConsultationParams = {
  id: ID;
  note: string;
};
export const patchLiveConsultation = async ({
  id,
  note
}: PatchLiveConsultationParams) => {
  return (
    await api.patch<LiveConsultation.Level2>(`/lcs/${id}/`, {
      note
    })
  ).data;
};

const usePatchLiveConsultation = (
  options?: MutationObserverOptions<
    LiveConsultation.Level2,
    Error,
    PatchLiveConsultationParams
  >
) => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const queryClient = useQueryClient();

  return useMutation<
    LiveConsultation.Level2,
    Error,
    PatchLiveConsultationParams
  >({
    mutationFn: patchLiveConsultation,
    onSuccess: (res, req, ctx) => {
      options?.onSuccess?.(res, req, ctx);
      void queryClient.setQueryData(
        liveConsultationKeys.historyDetail(Number(req.id)),
        res
      );

      toast.open({
        message: 'Note saved.',
        type: 'success'
      });

      // lcs data update
      void queryClient.setQueryData<Pagination<LiveConsultation.Level1>>(
        liveConsultationKeys.historiesWithParams({ page }),
        produce((draft) => {
          if (!draft?.results) {
            return;
          }
          draft.results = draft.results.map((lc) => {
            if (lc.id === req.id) {
              return { ...lc, has_note: true };
            }
            return lc;
          });
        })
      );
    }
  });
};

export default usePatchLiveConsultation;
