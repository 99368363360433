import { Types } from 'ably';
import { AxiosError } from 'axios';
import { TwilioError } from 'twilio-video';

import { captureException, withScope } from '@sentry/react';

interface CaptureExceptionWithScopeParams {
  error?: Error | TwilioError | Types.ErrorInfo | AxiosError | null;
  tag?: string;
}

export const captureExceptionWithScope = ({
  error,
  tag = 'default'
}: CaptureExceptionWithScopeParams) => {
  if (!error) {
    return;
  }

  withScope((scope) => {
    scope.setTag('scope', tag);
    return captureException(error);
  });
};
