import { throttle } from 'radash';
import { useEffect, useMemo, useState } from 'react';

import useVideoContext from './use-video-context';

const useAudioAndVideoTracks = () => {
  const { getAudioAndVideoTracks } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();

  const getAudioAndVideoTracksThrottled = useMemo(
    () =>
      throttle({ interval: 500 }, async () => {
        await getAudioAndVideoTracks().catch((error: any) => {
          setMediaError(error);
        });
      }),
    [getAudioAndVideoTracks, setMediaError]
  );

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracksThrottled();
    }
  }, [mediaError, getAudioAndVideoTracksThrottled]);
};

export default useAudioAndVideoTracks;
