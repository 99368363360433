import { ForwardedRef, forwardRef, useCallback, useRef } from 'react';
import { cn } from '~utils/tailwind';

import Icon from '../../icon';
import TextInputHelper from '../@base/helper/component';
import { useCustomizedInputProps } from '../@base/hooks';
import TextInputLabel from '../@base/label/component';
import { TextFieldProps } from './types';

/**
 * @version v1.1.2
 * @author Danny
 */
const TextField = forwardRef(
  (
    {
      label,
      helperMessage,
      showHelper = false,
      showMaxLength = false,
      error,
      onDelete,
      inputType = 'filled',
      ...props
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const internalRef = useRef<HTMLInputElement>(null);
    const { inputProps, isFocused } = useCustomizedInputProps<
      HTMLInputElement,
      'input'
    >(props);
    const { value, disabled, readOnly, maxLength, className } = inputProps;

    const valueLength = value?.toString()?.length;
    const showDeleteIcon = !!(valueLength && isFocused && onDelete && !error);

    const _onDelete = useCallback(() => {
      if (internalRef.current) {
        internalRef.current.focus();
        internalRef.current.value = '';
      }
      onDelete?.();
    }, [onDelete]);

    return (
      <div className={cn('flex flex-col', className)}>
        {label && (
          <TextInputLabel
            isFocused={isFocused}
            isError={error}
            isDisabled={disabled}
          >
            {label}
          </TextInputLabel>
        )}
        <div className='relative w-full'>
          <input
            {...inputProps}
            ref={ref ?? internalRef}
            className={cn(
              'w-full text-gray-900 text-body-1 placeholder:text-gray-400',
              'border-solid outline-none',
              'transition-colors motion-reduce:transition-none',
              'disabled:cursor-not-allowed',
              inputType === 'filled' &&
                cn(
                  'rounded-xl border border-gray-100 bg-gray-50 py-4 pl-4 pr-10',
                  {
                    'border-gray-200 bg-gray-100': isFocused,
                    'border-red-100 bg-red-50': error,
                    'border-gray-50 bg-gray-50 text-gray-300':
                      disabled ?? readOnly
                  }
                ),
              inputType === 'underlined' &&
                cn('rounded-none border-b-2 border-gray-200 py-2 pr-7', {
                  'border-primary-500': isFocused,
                  'border-red-500': error,
                  'bg-transparent': disabled ?? readOnly
                }),
              !showDeleteIcon && 'pr-4'
            )}
          />
          <Icon
            name='close'
            className={cn(
              'absolute top-1/2 m-auto h-6 w-6 -translate-y-1/2 cursor-pointer stroke-gray-900',
              showDeleteIcon ? 'block cursor-pointer' : 'hidden cursor-default',
              {
                'right-3': inputType === 'filled',
                'bottom-1 right-0': inputType === 'underlined'
              }
            )}
            /**
             * onClick 이벤트를 사용하면, input의 onBlur 이벤트가 먼저 발생해서 콜백 함수 트리거가 되지 않습니다.
             */
            onMouseDown={_onDelete}
            onTouchStart={_onDelete}
          />
        </div>
        {showHelper && (
          <TextInputHelper
            helperMessage={helperMessage}
            maxLength={maxLength}
            showMaxLength={showMaxLength}
            valueLength={valueLength}
            isError={error}
            isDisabled={disabled}
            readOnly={readOnly}
            className={cn({
              'mt-1': inputType === 'filled',
              'mt-2': inputType === 'underlined'
            })}
          />
        )}
      </div>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
