import { ComponentProps } from 'react';
import { cn } from '~utils/tailwind';

import { DialogType, useDialogContext } from './context';

type DialogActionProps = Readonly<ComponentProps<'div'>>;
type DialogActionCss = { [key in DialogType]: string };

const DIALOG_ACTION_CSS: DialogActionCss = {
  prompt: 'flex-col-reverse gap-2 p-5 lg:p-6 lg:flex-row lg:justify-end',
  alert: 'justify-end pt-3'
};

const DialogAction = ({ children, className, ...props }: DialogActionProps) => {
  const { type } = useDialogContext();

  return (
    <div {...props} className={cn('flex', DIALOG_ACTION_CSS[type], className)}>
      {children}
    </div>
  );
};

export default DialogAction;
