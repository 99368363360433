import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Overlay from '~components/overlay';
import useBlockScroll from '~hooks/use-block-scroll';
import { cn } from '~utils/tailwind';

import Icon from '../../@ui/icon';
import { SIDE_SHEET_PAGE_ID } from './constant';
import { SideSheetHeaderProps, SideSheetProps } from './types';

const SideSheetHeader = ({
  handleWidth,
  onRequestClose,
  isExpandable,
  isExpanded
}: SideSheetHeaderProps) => {
  return (
    <div className='flex w-full justify-between border-b border-solid border-gray-300 px-7 py-5'>
      {isExpandable ? (
        <button
          type='button'
          aria-label='expand'
          className='p-2 outline-none'
          onClick={handleWidth}
        >
          <Icon
            name='expand'
            className={cn(
              isExpanded ? 'fill-primary-500' : 'fill-gray-900',
              'h-6 w-6 '
            )}
          />
        </button>
      ) : (
        <div />
      )}
      <button
        type='button'
        aria-label='close'
        className='p-2'
        onClick={() => {
          onRequestClose();
        }}
      >
        <Icon name='close' className='h-6 w-6 fill-gray-900' />
      </button>
    </div>
  );
};

const useSideSheetControl = () => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const pageId = searchParams.get(SIDE_SHEET_PAGE_ID);

  useBlockScroll(open);

  useEffect(() => {
    if (pageId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [pageId]);

  return {
    open
  };
};

const SideSheet = ({
  onRequestClose,
  children,
  className,
  width,
  initWidth,
  handleWidth,
  isExpanded,
  isExpandable
}: SideSheetProps) => {
  const { open } = useSideSheetControl();

  return (
    <Overlay
      open={open}
      className='cursor-default bg-transparent'
      onClose={onRequestClose}
    >
      <div
        role='presentation'
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        className={cn(
          'fixed bottom-0 right-0 top-0 flex h-screen flex-col',
          'border-l border-solid border-gray-300 bg-white',
          'transition-all duration-300 motion-reduce:transition-none',
          open ? 'translate-x-0' : 'translate-x-full',
          className
        )}
        style={{
          width,
          maxWidth: isExpanded ? '100%' : 960,
          minWidth: 640
        }}
      >
        <SideSheetHeader
          isExpandable={isExpandable}
          isExpanded={isExpanded}
          handleWidth={handleWidth}
          onRequestClose={() => {
            onRequestClose();
            initWidth();
          }}
        />
        {open && children}
      </div>
    </Overlay>
  );
};

export default SideSheet;

/**
 * @usage
 *
 * const {
 *  openSideSheet,
 *  sideSheetProps
 * } = useSideSheet();
 *
 *  return (
 *    <button
 *      onClick={() => {
 *        openSideSheet(1); // pageId 전달 (ex. questionId)
 *      }}
 *    >
 *      OPEN
 *    </button>
 *   <SideSheet {...sideSheetProps} />
 * )
 */
