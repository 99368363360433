import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import Consultation from '~features/consultation/models';
import { api } from '~utils/api';

import { consultationKeys } from './query-keys';

const fetchOwnersConsultations = async (id: number) =>
  (
    await api.get<Pagination<Consultation.Level1>>(
      `/owners/${id}/consultations/`
    )
  ).data;

const useOwnersConsultations = (id: number) => {
  return useQuery<Pagination<Consultation.Level1>, AxiosError>({
    queryKey: consultationKeys.ownerConsultations(id),
    queryFn: async () => await fetchOwnersConsultations(id),
    staleTime: 2 * 60 * 1000,
    enabled: !!id
  });
};

export default useOwnersConsultations;
