import { DevTools } from 'jotai-devtools';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppUpdatePrompt from '~addons/pwa/app-update-prompt';
import getQueryClient from '~addons/query-client';
import ToastComponent from '~components/@ui/toast/component';
import AblyProviderWithAuthorized from '~components/ably-provider-with-authorized';
import LoadingContainer from '~components/loading/loading-container';
import { env } from '~env';

import { usePushNotification } from './addons/firebase';
import Routes from './routes';

const queryClient = getQueryClient();

env.VITE_ENVIRONMENT === 'prod' &&
  Sentry.init({
    dsn: 'https://c24d5d8b271e0f99ad1b69094aa49ece@o476947.ingest.sentry.io/4506534050856960',

    // sentry docs default options
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', env.VITE_HOST],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

const App = () => {
  usePushNotification();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <DevTools />
      <AppUpdatePrompt />
      <AblyProviderWithAuthorized>
        <LoadingContainer>
          <Routes />
        </LoadingContainer>
      </AblyProviderWithAuthorized>
      <ToastComponent />
    </QueryClientProvider>
  );
};
export default App;
