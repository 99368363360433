import { useCallback, useEffect, useMemo, useState } from 'react';
import Calendar, { TileDisabledFunc } from 'react-calendar';
import type { LooseValue } from 'node_modules/react-calendar/dist/esm/shared/types';
import Spinner from '~components/@ui/loading-indicator/spinner';
import useUpdateLiveConsultationAvailableTimes from '~features/live-consultation/hooks/mutations/use-update-live-consultation-available-times';
import { useLiveConsultationAvailableTimes } from '~features/live-consultation/hooks/queries/use-live-consultation-available-times';

import './calendar.css';
import PeriodSelector, {
  PeriodState
} from '~features/live-consultation/components/period-selector';
import Button from '~components/@ui/button/button/component';
import { useLiveConsultationPrice } from '~features/live-consultation/hooks/queries/use-live-consultation-price';
import TextField from '~components/@ui/text-input/text-field/component';
import usePatchLiveConsultationPrice from '~features/live-consultation/hooks/mutations/use-patch-live-consultation-price';
import { toast } from '~components/@ui/toast/controller';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';
import { env } from '~env';

const PrivateInvitationSettingsTab = () => {
  const { data: myProfile } = useMyProfile();
  const {
    data: remoteLiveConsultationPriceData,
    isFetching: isRemoteLiveConsultationPriceDataFetching
  } = useLiveConsultationPrice();
  const { getDraft, isFetching } = useLiveConsultationAvailableTimes();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [liveConsultationPrice, setLiveConsultationPrice] = useState(0);
  const [draft, setDraft] = useState<
    Record<string, { state: PeriodState; to?: PeriodState }>
  >({});

  const { mutate: updateLiveConsultationAvailableTimes } =
    useUpdateLiveConsultationAvailableTimes();
  const { mutate: patchLiveConsultationPrice } = usePatchLiveConsultationPrice({
    onSuccess: (res) => {
      toast.open({
        message: `Price updated ($${res.price / 100})`,
        type: 'success'
      });
    },
    onError: () => {
      toast.open({
        message: 'Failed to update live consultation price',
        type: 'error'
      });
    }
  });

  if (
    isFetching ||
    isRemoteLiveConsultationPriceDataFetching ||
    !draft ||
    !remoteLiveConsultationPriceData
  ) {
    <div className='absolute left-1/2 top-1/2 z-floated -translate-x-1/2 -translate-y-1/2'>
      <Spinner color='gray' />
    </div>;
  }

  const mySharableLink = useMemo(() => {
    if (!myProfile) {
      return null;
    }

    const N = 1399;
    const M = 607;
    const K = N * M;
    const code = [...Array(3)].reduce((acc) => btoa(acc), K * myProfile.vet.id);
    const baseUrl = env.VITE_VET_INVITATION_BASE_URL;

    return `${baseUrl}?vid=${code}`;
  }, [myProfile]);
  const displayPrice = useMemo(
    () => liveConsultationPrice / 100,
    [liveConsultationPrice]
  );

  const canGoSave = useMemo(
    () =>
      Object.values(draft).some(
        (value) => value.to !== undefined && value.to !== value.state
      ),
    [draft]
  );

  const tileDisabled: TileDisabledFunc = ({ date }) => {
    const today = new Date();
    date.setHours(23, 59, 59, 999);

    if (date < today) {
      return true;
    }
    return false;
  };

  const onCalendarChange = (date: LooseValue) => {
    if (date === null) {
      setSelectedDate(null);
      setDraft({});
      return;
    }

    if (!Array.isArray(date)) {
      const newDate = new Date(date);
      setSelectedDate(newDate);
      setDraft(getDraft());
    }
  };

  const onPriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value === '') {
        setLiveConsultationPrice(0);
        return;
      }

      const price = parseFloat(value);
      if (Number.isNaN(price)) {
        return;
      }
      setLiveConsultationPrice(price * 100);
    },
    []
  );

  const onPriceBlur = useCallback(() => {
    if (remoteLiveConsultationPriceData?.price !== liveConsultationPrice) {
      patchLiveConsultationPrice({ price: liveConsultationPrice });
    }
  }, [
    liveConsultationPrice,
    patchLiveConsultationPrice,
    remoteLiveConsultationPriceData?.price
  ]);

  const onSharableLinkClick = useCallback(() => {
    try {
      if (mySharableLink) {
        void navigator.clipboard.writeText(mySharableLink);
        toast.open({
          message: 'Copied to clipboard',
          type: 'success'
        });
      }
    } catch (e) {
      toast.open({
        message: 'Failed to copy to clipboard',
        type: 'error'
      });
    }
  }, [mySharableLink]);

  const onSave = useCallback(() => {
    const updatedTimes = Object.entries(draft)
      .filter(([_, { state, to }]) => to !== undefined && state !== to)
      .map(([time, { to }]) => ({
        time,
        is_on: to === PeriodState.AVAILABLE
      }));

    updateLiveConsultationAvailableTimes({ updatedTimes });
  }, [draft, updateLiveConsultationAvailableTimes]);

  useEffect(() => {
    setDraft(getDraft());
  }, [getDraft]);

  useEffect(() => {
    if (remoteLiveConsultationPriceData !== undefined) {
      setLiveConsultationPrice(remoteLiveConsultationPriceData.price);
    }
  }, [remoteLiveConsultationPriceData]);

  return (
    <main>
      <div className='flex w-fit flex-col gap-5'>
        <button onClick={onSharableLinkClick}>
          <TextField
            className='text-end text-gray-500'
            label='Sharable link (for client)'
            value={mySharableLink || ''}
            readOnly
            style={{
              textAlign: 'right',
              color: 'rgb(139 149 161)'
            }}
          />
        </button>
        <TextField
          className='text-end'
          label='Price (per live consultation 15m)'
          inputType='underlined'
          style={{
            textAlign: 'right'
          }}
          type='number'
          value={displayPrice}
          onChange={onPriceChange}
          onBlur={onPriceBlur}
        />

        <section className='flex max-w-md flex-col gap-y-2 rounded-2xl border-[1px] border-gray-200'>
          <section className='flex max-w-full flex-col px-6 py-5'>
            <h1 className='text-gray-900 text-headline-2'>
              Available Time Settings
            </h1>
            <p className='text-gray-500 text-body-2'>
              Set your available time for private invitations.
            </p>
          </section>
          <div className='h-[1px] w-full bg-gray-100' />
          <section className='flex max-w-full flex-col gap-y-10 p-5'>
            <Calendar
              locale='en-US'
              tileDisabled={tileDisabled}
              onChange={onCalendarChange}
            />
            {selectedDate && (
              <PeriodSelector
                value={draft}
                onValueChanged={(newValue) => {
                  setDraft(newValue);
                }}
                selectedDate={selectedDate}
                hideDisabled
              />
            )}
            {canGoSave && (
              <section className='flex w-full gap-4'>
                <Button className='flex-1 self-start' priority='tertiary'>
                  Reset All
                </Button>
                <Button className='flex-1 self-start' onClick={onSave}>
                  Save Changes
                </Button>
              </section>
            )}
          </section>
        </section>
      </div>
    </main>
  );
};

export default PrivateInvitationSettingsTab;
