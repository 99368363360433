import { cva } from 'class-variance-authority';

export const labelStyle = cva(['w-fit rounded-2xl inline-block'], {
  variants: {
    color: {
      gray: 'bg-gray-100 text-gray-600',
      primary: 'bg-primary-50 text-primary-600',
      red: 'bg-red-50 text-red-600',
      orange: 'bg-orange-50 text-orange-600',
      green: 'bg-green-50 text-green-600',
      pink: 'bg-pink-50 text-pink-600',
      violet: 'bg-violet-50 text-violet-600',
      lime: 'bg-lime-50 text-lime-800',
      grape: 'bg-grape-50 text-grape-700',
      cyan: 'bg-cyan-50 text-cyan-600',
      indigo: 'bg-indigo-50 text-indigo-600'
    },
    size: {
      small: 'text-label-3 px-2 py-[2px]',
      large: 'text-label-1 px-[10px] py-1'
    }
  },
  defaultVariants: {
    color: 'primary',
    size: 'large'
  }
});
