import { memo } from 'react';
import { LocalAudioTrack } from 'twilio-video';
import useVideoContext from '~twilio/hooks/use-video-context';

import AudioLevelIndicator from '../audio-level-indicator';

const LocalAudioLevelIndicator = memo(() => {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioTrack;

  return <AudioLevelIndicator audioTrack={audioTrack} />;
});
LocalAudioLevelIndicator.displayName = 'LocalAudioLevelIndicator';
export default LocalAudioLevelIndicator;
