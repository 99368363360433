import { cva } from 'class-variance-authority';

import { buttonPriority } from '../@base.styles';

export const iconButtonStyle = cva(
  'inline-flex items-center justify-center outline-none',
  {
    variants: {
      priority: {
        primary: buttonPriority({ priority: 'primary' }),
        secondary: buttonPriority({ priority: 'secondary' }),
        tertiary: buttonPriority({ priority: 'tertiary' })
      },
      size: {
        large: ['rounded-xl', 'p-4', '[&>svg]:w-6 [&>svg]:h-6'],
        medium: ['rounded-lg', 'p-3', '[&>svg]:w-5 [&>svg]:h-5'],
        small: ['rounded-[4px]', 'p-2', '[&>svg]:w-5 [&>svg]:h-5']
      },
      buttonType: {
        circle: 'rounded-full',
        square: ''
      }
    },
    defaultVariants: {
      priority: 'tertiary',
      size: 'small',
      buttonType: 'circle'
    }
  }
);
