import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PortalId } from '~components/portal/types';
import useEscape from '~hooks/use-escape';

import { SIDE_SHEET_PAGE_ID } from './constant';
import { SideSheetProps } from './types';

type UseSideSheetProps = Readonly<{
  defaultWidth?: number;
  isExpandable?: boolean;
}>;

const isChildrenModalOpened = (id: Extract<PortalId, 'modal'>) => {
  return document.getElementById(id)?.hasChildNodes();
};

export const useSideSheet = ({
  defaultWidth,
  isExpandable = true
}: UseSideSheetProps = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { innerWidth } = window;
  const _defaultWidth = defaultWidth ?? innerWidth / 2;
  const [width, setWidth] = useState(_defaultWidth);
  const isExpanded = !(innerWidth > width);

  const openSideSheet = (pageId: number | string) => {
    searchParams.set(SIDE_SHEET_PAGE_ID, pageId.toString());
    setSearchParams(searchParams);
  };

  const closeSideSheet = useCallback(() => {
    searchParams.delete(SIDE_SHEET_PAGE_ID);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const initWidth = useCallback(() => {
    setWidth(_defaultWidth);
  }, [_defaultWidth]);

  const handleEscape = () => {
    if (isChildrenModalOpened('modal')) {
      return;
    }

    if (isExpanded) {
      initWidth();
      return;
    }

    closeSideSheet();
  };

  useEscape({
    callback: handleEscape
  });

  const handleWidth = useCallback(() => {
    if (innerWidth > width) {
      setWidth(innerWidth);
    } else {
      setWidth(_defaultWidth);
    }
  }, [_defaultWidth, innerWidth, width]);

  return {
    openSideSheet,
    sideSheetProps: {
      onRequestClose: closeSideSheet,
      handleWidth,
      initWidth,
      width,
      isExpandable,
      isExpanded
    } satisfies SideSheetProps
  };
};
