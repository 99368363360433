import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { api } from '~utils/api';
import { liveConsultationKeys } from './query-keys';
import { PeriodState } from '~features/live-consultation/components/period-selector';

const fetchLiveConsultationAvailableTimes = async () => {
  return (await api.get<string[]>('/lcs/available_time/')).data;
};

export const useLiveConsultationAvailableTimes = () => {
  const queryResult = useQuery<string[]>({
    queryKey: liveConsultationKeys.availableTimes(),
    queryFn: fetchLiveConsultationAvailableTimes
  });

  const { data } = queryResult;

  const getDraft = useCallback(() => {
    const draft: Record<string, { state: PeriodState; to?: PeriodState }> = {};
    return (
      data?.reduce((acc, time) => {
        // eslint-disable-next-line no-param-reassign
        acc[new Date(time).toJSON()] = {
          state: PeriodState.AVAILABLE
        };
        return acc;
      }, draft) || {}
    );
  }, [data]);

  return {
    ...queryResult,
    getDraft
  };
};
