import {
  UseMutationOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { api } from '~utils/api';
import { LiveConsultationPrice } from '../queries/use-live-consultation-price';
import { liveConsultationKeys } from '../queries/query-keys';
import { toast } from '~components/@ui/toast/controller';
import { captureExceptionWithScope } from '~utils/sentry';

interface PatchLiveConsultationTicketPriceParams {
  price: number;
}

const patchLiveConsultationPrice = async (
  params: Readonly<PatchLiveConsultationTicketPriceParams>
) => {
  return (await api.patch<LiveConsultationPrice>('/vet/lct_price/', params))
    .data;
};

const usePatchLiveConsultationPrice = (
  options?: UseMutationOptions<
    LiveConsultationPrice,
    Error,
    PatchLiveConsultationTicketPriceParams
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchLiveConsultationPrice,
    onSuccess: (res, variables, context) => {
      options?.onSuccess?.(res, variables, context);
      queryClient.setQueryData(liveConsultationKeys.price(), res);
    },
    onError: (err, variables, context) => {
      options?.onError?.(err, variables, context);
      toast.open({
        message: 'Failed to update live consultation ticket price',
        type: 'error'
      });
      captureExceptionWithScope({
        error: err,
        tag: 'patchLiveConsultationTicketPrice'
      });
    },
    ...options
  });
};

export default usePatchLiveConsultationPrice;
