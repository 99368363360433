import { ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

/**
 * tailwind.config.js 파일의 addUtilities에서 정의된 클래스네임들을 병합할 수 있도록
 * 확장된 twMerge 함수를 생성합니다.
 */
const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-style': [
        'text-display-1',
        'text-display-2',
        'text-display-3',
        'text-headline-1',
        'text-headline-2',
        'text-headline-3',
        'text-title-1',
        'text-title-2',
        'text-title-3',
        'text-body-large-1',
        'text-body-1',
        'text-body-2',
        'text-body-3',
        'text-label-1',
        'text-label-2',
        'text-label-3'
      ]
    }
  }
});

/**
 * 주어진 입력들을 병합하여 하나의 className 문자열을 반환합니다.
 * - 함수 내부의 `clsx`는 다양한 형태의 입력(문자열, 배열, 객체 등)을 처리하며, falsy 값(false, null, undefined,  0, NaN, '')을 무시합니다.
 * - 함수 내부의 `twMerge`는 유틸리티 클래스들 사이에 발생할 수 있는 충돌을 해결합니다. 예를 들어, 같은 스타일을 지정하는 서로 다른 클래스가 있다면 twMerge는 뒤에 오는 클래스로 덮어쓰는 등의 처리를 합니다.
 */
export const cn = (...classNames: ClassValue[]) => twMerge(clsx(classNames));
