import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { toast } from '~components/@ui/toast/controller';
import {
  APP_AUTH_ESCAPE_ERROR_CODE,
  __ACCESS_TOKEN__,
  __FIREBASE_TOKEN__,
  __REFRESH_TOKEN__
} from '~constants';
import { env } from '~env';

import { getLocalTimeZone } from './date';
import { captureExceptionWithScope } from './sentry';

const LOCAL_TIME_ZONE = getLocalTimeZone();

export const api = axios.create({
  baseURL: env.VITE_HOST,
  withCredentials: true,
  headers: {
    common: {
      'access-env': 'web',
      'Time-Zone': `${LOCAL_TIME_ZONE}`
    }
  }
});

export const ably_api = axios.create({
  baseURL: env.VITE_ABLY_HOST,
  withCredentials: true,
  headers: {
    common: {
      'access-env': 'web',
      'Time-Zone': `${LOCAL_TIME_ZONE}`
    }
  }
});

api.interceptors.request.use(
  (req: InternalAxiosRequestConfig) => {
    if (!req.withCredentials) {
      return req;
    }

    const token = localStorage.getItem(__ACCESS_TOKEN__);

    if (token && req.headers) {
      req.headers.Authorization = `JWT ${token}`;
    }

    return req;
  },
  async (error: AxiosError) => {
    captureExceptionWithScope({
      error,
      tag: 'axios'
    });
    await Promise.reject(error);
  }
);

ably_api.interceptors.request.use(
  (req: InternalAxiosRequestConfig) => {
    if (!req.withCredentials) {
      return req;
    }

    const token = localStorage.getItem(__ACCESS_TOKEN__);

    if (token && req.headers) {
      req.headers.Authorization = `JWT ${token}`;
    }

    return req;
  },
  async (error: AxiosError) => {
    captureExceptionWithScope({
      error,
      tag: 'axios'
    });
    await Promise.reject(error);
  }
);

const showMessage = (error: Error) => {
  if (!(error instanceof AxiosError)) {
    return;
  }

  if (error.response) {
    toast.open({
      type: 'error',
      message:
        error.response.data?.detail ||
        'Something went wrong. Please try again later.'
    });
  }
};

type PendingRequest = (accessToken: string) => void;

let isRefreshTokenBusy = false;
let pendingRequestPool = [] as PendingRequest[];

const onTokenRefreshed = async (accessToken: string) => {
  pendingRequestPool.map(async (callback) => {
    callback(accessToken);
  });
  pendingRequestPool = [];
  isRefreshTokenBusy = false;
};

const pendingRequest = (callback: PendingRequest) => {
  pendingRequestPool.push(callback);
};

const reset = () => {
  localStorage.removeItem(__ACCESS_TOKEN__);
  localStorage.removeItem(__REFRESH_TOKEN__);
  localStorage.removeItem(__FIREBASE_TOKEN__);
  document.location.href = '/';
};

api.interceptors.response.use(
  (res) => res,
  async (error) => {
    captureExceptionWithScope({
      error,
      tag: 'axios'
    });
    const {
      config,
      response: { status }
    } = error;
    const triggeredRequest = config;
    const serverErrorCode = error.response.data?.error?.code;

    if (status === 401 && serverErrorCode === APP_AUTH_ESCAPE_ERROR_CODE) {
      reset();
    }

    if (status !== 403) {
      showMessage(error);
      await Promise.reject(error);
      return;
    }

    // 리프레시 토큰 발급 중일 때
    const pendingTriggeredRequest = new Promise((resolve) => {
      pendingRequest((accessToken: string) => {
        if (!triggeredRequest) {
          return;
        }

        if (triggeredRequest.headers) {
          triggeredRequest.headers.Authorization = `JWT ${accessToken}`;
        } else {
          triggeredRequest.headers = {
            Authorization: `JWT ${accessToken}`
          };
        }

        resolve(axios(triggeredRequest));
      });
    });

    // 리프레시 토큰이 사용 중인 경우가 아닌 경우
    if (!isRefreshTokenBusy) {
      isRefreshTokenBusy = true;

      const accessToken = localStorage.getItem(__ACCESS_TOKEN__);
      const refreshToken = localStorage.getItem(__REFRESH_TOKEN__);

      if (!accessToken || !refreshToken) {
        await Promise.reject(error);
        return;
      }

      try {
        const {
          data: { refresh_token: newRefreshToken, access_token: newAccessToken }
        } = await api.post<{ refresh_token: string; access_token: string }>(
          '/account/refresh_token/',
          {
            access_token: accessToken,
            refresh_token: refreshToken
          },
          {
            withCredentials: false
          }
        );

        localStorage.setItem(__ACCESS_TOKEN__, newAccessToken);
        localStorage.setItem(__REFRESH_TOKEN__, newRefreshToken);
        await onTokenRefreshed(newAccessToken);
      } catch (refreshTokenError) {
        // 리프레시 토큰 발급에 이상이 생긴 경우
        reset();
      }
    }
    return await pendingTriggeredRequest;
  }
);
