import clsx from 'clsx';
import Icon from '~components/@ui/icon';
import useVideoContext from '~twilio/hooks/use-video-context';

export const ToggleScreenShareButton = () => {
  const { toggleScreenShare } = useVideoContext();

  const onPress = () => {
    toggleScreenShare();
  };

  return (
    <div
      className={clsx(
        'h-14 w-14 overflow-hidden rounded-full border border-white'
      )}
    >
      <button
        className='flex h-full w-full items-center justify-center'
        onClick={onPress}
        aria-label='Toggle Screen Share'
      >
        <Icon name='screen' className='h-6 w-6 fill-white' />
      </button>
    </div>
  );
};
