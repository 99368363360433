import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { loadingAtom } from '~features/app/atoms/loading';

import Loading from './loading';

/**
 * _app.tsx 컴포넌트에서 한정 사용하며, loadingAtom 상태값에 따라 라이트박스 + 스피너를 띄워줍니다.
 * React Query를 이용한 API 요청 시 onMutate, onSuccess, onSettled에서 상태값을 핸들링하여
 * 유저에게 로딩 상태를 인지시키는 데 사용됩니다.
 */
const LoadingContainer = ({ children }: { children: ReactNode }) => {
  const isLoading = useAtomValue(loadingAtom);

  return (
    <>
      <Loading isLoading={isLoading} withLightBox />
      {children}
    </>
  );
};

export default LoadingContainer;
