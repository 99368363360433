import { ComponentProps, useCallback } from 'react';
import { cn } from '~utils/tailwind';

import Icon from '../../icon';
import { useSelectBox } from './context';

type Props = ComponentProps<'div'>;

const SelectBoxDropdownSearch = ({
  placeholder = 'Search...',
  ...props
}: Props) => {
  const { className } = props;
  const { searchValue, onSearch } = useSelectBox();

  const ref = useCallback((node: HTMLInputElement | null) => {
    node?.focus();
  }, []);

  return (
    <div
      {...props}
      className={cn(
        'relative w-full border-b border-solid border-gray-100',
        className
      )}
    >
      <div className='absolute left-5 top-1/2 flex h-6 w-6 -translate-y-1/2 items-center justify-center'>
        <Icon
          name='search'
          className='h-[18px] w-[18px] fill-none stroke-gray-900 stroke-[1.5px]'
        />
      </div>
      <input
        ref={ref}
        value={searchValue}
        onChange={(e) => {
          onSearch(e.target.value);
        }}
        placeholder={placeholder}
        className='w-full py-4 pl-[52px] pr-5 text-gray-900 outline-none text-body-1 placeholder:text-gray-400 placeholder:text-body-1'
      />
    </div>
  );
};

export default SelectBoxDropdownSearch;
