import { cn } from '~utils/tailwind';

type LoaderColor = 'primary' | 'gray';
type LoaderColorCss = { [key in LoaderColor]: string };
type LoaderSize = '12px'; // 필요 시 다른 사이즈 추가, 네이밍은 추후 sm, md, lg 등으로 변경될 수 있음
type LoaderSizeCss = { [key in LoaderSize]: string };

type LoaderProps = Readonly<{
  color?: LoaderColor;
  size?: LoaderSize;
}>;

const LOADER_COLOR_CSS: LoaderColorCss = {
  primary: 'bg-primary-500',
  gray: 'bg-gray-300'
};

const LOADER_SIZE_CSS: LoaderSizeCss = {
  '12px': 'h-3 w-3'
};

/**
 * @version 1.1.0
 * @author Danny
 */
const Loader = ({ color = 'primary', size = '12px' }: LoaderProps) => {
  const loaderColor = LOADER_COLOR_CSS[color];
  const loaderSize = LOADER_SIZE_CSS[size];
  const loaderStyle = cn(
    'animate-loader rounded-full',
    loaderColor,
    loaderSize
  );

  return (
    <div className='flex items-center gap-2'>
      <div className={loaderStyle} />
      <div className={cn('animation-delay-200', loaderStyle)} />
      <div className={cn('animation-delay-[400ms]', loaderStyle)} />
    </div>
  );
};

export default Loader;
