import { ReactNode } from 'react';
import { LoadingOverlay, LoadingOverlayProps } from 'react-load-overlay';
import Spinner from '~components/@ui/loading-indicator/spinner';

interface WaitingOverlayProps extends LoadingOverlayProps {
  children: ReactNode;
  hideSpinner?: boolean;
}

export const WaitingOverlay = ({
  children,
  hideSpinner = false,
  ...props
}: Readonly<WaitingOverlayProps>) => {
  return (
    <LoadingOverlay
      containerStyle={{
        width: '100%',
        height: '100%'
      }}
      overlayStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      }}
      spinner={!hideSpinner ? <Spinner color='primary' /> : null}
      {...props}
    >
      {children}
    </LoadingOverlay>
  );
};
