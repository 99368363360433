/* eslint-disable no-console */
import { getApp, getApps, initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
  onMessage
} from 'firebase/messaging';
import { produce } from 'immer';
import { getDefaultStore } from 'jotai';
import { useEffect } from 'react';
import { __FIREBASE_TOKEN__ } from '~constants';
import {
  foregroundMessageAtom,
  notificationBadgeAtom
} from '~features/app/atoms/notification';
import App from '~features/app/models';

const firebaseConfig = {
  apiKey: 'AIzaSyAIfMLBYzNCgFUAvl6SdjVb-VLPZrIyXCI',
  authDomain: 'drtail-6c163.firebaseapp.com',
  databaseURL: 'https://drtail-6c163.firebaseio.com',
  projectId: 'drtail-6c163',
  storageBucket: 'drtail-6c163.appspot.com',
  messagingSenderId: '393254937035',
  appId: '1:393254937035:web:689ac105fcaafd404c564e',
  measurementId: 'G-ZEGBJ52R6W'
};

const store = getDefaultStore();

const setInBoxIndicatorActive = () => {
  store.set(
    notificationBadgeAtom,
    produce((draft) => {
      draft.inbox = true;
    })
  );
};

const Firebase = {
  init() {
    return initializeApp(firebaseConfig);
  },
  getApp() {
    if (!getApps().length) {
      return this.init();
    }
    const app = getApp();
    return app;
  },
  async isSupported() {
    const isSupport = await isSupported();
    if (!isSupport) {
      console.log(
        'Your browser does not support firebase push notification. Please use the latest version of the Chrome browser.'
      );

      return false;
    }

    return true;
  },
  async getToken(message: Messaging) {
    const token = await getToken(message, {
      vapidKey:
        'BAeoeg0-w-_oBgYsMcqEnEOh1_kE_xnvl1xY-GsWCP1W7sYViMzhlatN7CAdosA-rERDef45pSN7LAWQ59pVSfc'
    });
    localStorage.setItem(__FIREBASE_TOKEN__, token);
  },
  async getForegroundMessage() {
    try {
      const isPushSupported = await this.isSupported();

      if (!isPushSupported) {
        return;
      }

      const app = this.getApp();
      const message = getMessaging(app);
      await this.getToken(message);

      return { message };
    } catch (err) {
      console.error('[Error]: Failed to get foreground message', err);
    }
  },
  setForegroundMessage(message: Messaging) {
    onMessage(message, (payload) => {
      if (!payload) {
        return;
      }

      const { origin } = window.location;

      const foregroundMessage: App.Message = {
        id: parseInt(payload.messageId, 10) || 0,
        message_type_id: (payload.data?.message_type_id ||
          App.Message.Type.Consultation) as App.Message.Type,
        icon: (payload.data?.icon || 'bell') as App.Message.Icon,
        title: payload.data?.title || '',
        body: payload.data?.body,
        deeplink: payload.data?.deeplink
          ? origin + payload.data.deeplink
          : origin
      };

      store.set(foregroundMessageAtom, foregroundMessage);
      setInBoxIndicatorActive();
      console.log('[Foreground Message]: ', payload);
    });
  }
};

const useForegroundMessage = () => {
  useEffect(() => {
    Firebase.getForegroundMessage()
      .then((res) => {
        if (res?.message) {
          Firebase.setForegroundMessage(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
};

const useBackgroundMessage = () => {
  useEffect(() => {
    const backgroundMessageChannel = new BroadcastChannel(
      'backgroundMessageChannel'
    );
    backgroundMessageChannel.onmessage = (e) => {
      if (e.data.isInboxIndicatorActive === true) {
        setInBoxIndicatorActive();
      }
    };
  }, []);
};

export const usePushNotification = () => {
  useForegroundMessage();
  useBackgroundMessage();
};
