import { ComponentProps, ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useQueryErrorResetBoundary } from '@tanstack/react-query';

type Props = Readonly<{
  children: ReactNode;
  pendingFallback?: ComponentProps<typeof Suspense>['fallback'];
  rejectedFallback?: ComponentProps<typeof ErrorBoundary>['fallbackRender'];
}>;

/**
 * @see https://jbee.io/react/error-declarative-handling-1/
 * @see https://jbee.io/react/error-declarative-handling-3/
 */
const AsyncBoundary = ({
  children,
  pendingFallback = <></>,
  rejectedFallback
}: Props) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={(props) => rejectedFallback?.(props)}
    >
      <Suspense fallback={pendingFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export default AsyncBoundary;

/**
 * @usage
 * import { FallbackProps } from 'react-error-boundary';
 *
 * // ? API 요청이 pending 상태일 때 보여줄 컴포넌트
 * const PendingFallback = () => {
 *   return (
 *   	 <div>
 *     	<Spinner />
 *   	 </div>
 * 	 );
 * };
 *
 * // ? API 요청이 실패했을 때 보여줄 컴포넌트
 * const RejectedFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
 *	 return (
 *		 <div>
 *			 <div>Something went wrong</div>
 *			 <div>{error}</div>
 *			 <button onClick={resetErrorBoundary}>Retry</button>
 *		 </div>
 *	 );
 * };
 *
 * // ? API 요청이 이루어지는 컴포넌트
 * // ? tanstack-query v5로 업데이트 되면 useSuspenseQuery 사용, suspense: true 옵션 생략 가능
 * const Child = () => {
 *	 const { data } = useQuery({
 *		 queryKey: ['something']
 *		 queryFn: fetchSomething,
 *		 suspense: true,
 *	 });
 *
 *   return (
 *   	 <div>
 *       <div>{data?..}</div>
 *   	 </div>
 * 	 );
 * };

 *	// ? AsyncBoundary 사용 예시
 *	const Parents = () => {
 * 	  return (
 *   	  <AsyncBoundary
 *    		 pendingFallback={<PendingFallback />}
 *     	   rejectedFallback={RejectedFallback} // ! <RejectedFallback /> 형태로 전달하면 린트에러 발생
 *   	  >
 *     	  <Child />
 *   	  </AsyncBoundary>
 *   );
 *  };
 *
 */
