import { useCallback, useMemo, useState } from 'react';
import Button from '~components/@ui/button/button/component';
import IconButton from '~components/@ui/button/icon-button/component';
import RadioChip from '~components/@ui/chip/radio-chip';
import Avatar from '~components/@ui/image/avatar/component';
import { DEFAULT_THUMBNAIL_PET } from '~constants';
import { useAcceptLiveConsultationTicket } from '~features/live-consultation/hooks/mutations/use-accept-live-consultation-ticket';
import { useDeleteLiveConsultationTicket } from '~features/live-consultation/hooks/mutations/use-delete-live-consultation-ticket';
import { useLiveConsultation } from '~features/live-consultation/hooks/queries/use-live-consultation';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { TicketStatus, TicketType } from '~features/live-consultation/types';
import { getDayjsFormat } from '~utils/format/dayjs';
import { cn } from '~utils/tailwind';

enum RadioChipEnum {
  WalkIn,
  Scheduled
}

interface DescriptionProps {
  label: string;
  value: string;
}
const Description = ({ label, value }: DescriptionProps) => {
  return (
    <div className='flex flex-1 flex-col gap-2'>
      <p className='text-gray-500 text-label-1'>{label}</p>
      <p className='text-gray-700 text-title-3'>{value}</p>
    </div>
  );
};

interface RequestCardProps {
  ticket: LiveConsultationTicket;
  disabled?: boolean;
}

const RequestCard = ({ ticket, disabled = false }: RequestCardProps) => {
  const { mutate: acceptRequest } = useAcceptLiveConsultationTicket();
  const { mutate: denyRequest } = useDeleteLiveConsultationTicket();

  const onDeny = useCallback(() => {
    denyRequest({
      ticketId: ticket.id
    });
  }, [denyRequest, ticket]);

  const onAccept = useCallback(() => {
    acceptRequest({
      ticket
    });
  }, [acceptRequest, ticket]);

  return (
    <div
      className={cn(
        'flex w-full flex-row items-center gap-5 rounded-2xl border border-gray-300 p-4'
      )}
    >
      {/* TODO Pet.Level1 model에 썸네일 url 추가되어야함 */}
      <Avatar uri={DEFAULT_THUMBNAIL_PET} alt='vet-avatar' size='40' />
      <div className='flex flex-1 flex-row items-center justify-between'>
        <div className='flex flex-1 flex-row items-center gap-2'>
          <p className='text-gray-900 text-title-2'>{ticket.pet?.name}</p>
        </div>
        <Description label='SESSION' value={TicketType[ticket.ticket_type]} />
        {ticket.schedule_from_to_formatted && (
          <Description
            label='RESERVED TIME SLOT'
            value={ticket.schedule_from_to_formatted}
          />
        )}

        <Description label='ISSUE' value={ticket.category_name ?? '-'} />
        <Description
          label='REQUESTED ON'
          value={getDayjsFormat(ticket.created_at, 'longWithTime')}
        />
        <div className='flex flex-row items-center gap-2'>
          <Button
            className='min-w-fit rounded-2xl'
            type='submit'
            size='large'
            disabled={disabled}
            onClick={() => {
              onAccept();
            }}
          >
            {ticket.ticket_type !== TicketType.Scheduled
              ? 'Start chat'
              : 'Accept'}
          </Button>
          {ticket.ticket_type !== TicketType.Quick && (
            <IconButton
              className='w-full rounded-xl'
              name='close'
              size='large'
              onClick={onDeny}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const EmptyCase = () => {
  return (
    <div className='flex justify-center px-5 py-8'>
      <p className='text-gray-700 text-headline-3'>No requests</p>
    </div>
  );
};

interface SubTabProps {
  list?: LiveConsultationTicket[];
  disabled?: boolean;
}
const SubTab = ({ list, disabled = false }: SubTabProps) => {
  if (!list?.length) {
    return <EmptyCase />;
  }
  return list.map((ticket) => {
    return <RequestCard key={ticket.id} ticket={ticket} disabled={disabled} />;
  });
};
interface ConsultationRequestTabProps {
  waitList?: LiveConsultationTicket[];
}

export const ConsultationRequestTab = ({
  waitList
}: ConsultationRequestTabProps) => {
  const [selectedChip, setSelectedChip] = useState<RadioChipEnum>(
    RadioChipEnum.WalkIn
  );
  const { data: lc } = useLiveConsultation();
  const requestDisabled = useMemo(() => !!lc?.room_access_token, [lc]);

  const walkInList = useMemo(
    () =>
      waitList?.filter(
        (ticket) => ticket.ticket_status === TicketStatus.Waiting
      ),
    [waitList]
  );

  const scheduledList = useMemo(
    () =>
      waitList?.filter(
        (ticket) => ticket.ticket_status === TicketStatus.Booking
      ),
    [waitList]
  );

  return (
    <div className='flex h-full w-full flex-col gap-5'>
      {!walkInList?.length && !scheduledList?.length ? (
        <EmptyCase />
      ) : (
        <>
          <p className='text-gray-900 text-headline-2'>Consultation Requests</p>
          <div className='flex flex-row gap-2'>
            <RadioChip
              isSelected={selectedChip === RadioChipEnum.WalkIn}
              onClick={() => {
                setSelectedChip(RadioChipEnum.WalkIn);
              }}
            >
              Walk-in
            </RadioChip>
            <RadioChip
              isSelected={selectedChip === RadioChipEnum.Scheduled}
              onClick={() => {
                setSelectedChip(RadioChipEnum.Scheduled);
              }}
            >
              Scheduled
            </RadioChip>
          </div>
          <div className='flex flex-col gap-4 overflow-y-scroll'>
            {selectedChip === RadioChipEnum.WalkIn && (
              <SubTab list={walkInList} disabled={requestDisabled} />
            )}
            {selectedChip === RadioChipEnum.Scheduled && (
              <SubTab list={scheduledList} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
