import { useSyncExternalStore } from 'react';
import { __ACCESS_TOKEN__, __REFRESH_TOKEN__ } from '~constants';

/**
 * 로그인 성공 시 해당 함수를 호출하여 로컬스토리지에 토큰을 저장합니다.
 */
export const setToken = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(__ACCESS_TOKEN__, accessToken);
  localStorage.setItem(__REFRESH_TOKEN__, refreshToken);
  // On localStorage.setItem, the storage event is only triggered on other tabs and windows.
  // So we manually dispatch a storage event to trigger the subscribe function on the current window as well.
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: __ACCESS_TOKEN__,
      newValue: accessToken
    })
  );
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: __REFRESH_TOKEN__,
      newValue: refreshToken
    })
  );
};

const tokenStore = {
  subscribe: (listener: () => void) => {
    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  },
  getSnapshot: () =>
    typeof window !== 'undefined' ? localStorage.getItem(__ACCESS_TOKEN__) : ''
};

const useLogInStatus = () => {
  const { subscribe, getSnapshot } = tokenStore;

  const isLoggedIn = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  return !!isLoggedIn;
};

export default useLogInStatus;
