import { useEffect } from 'react';
import { captureExceptionWithScope } from '~utils/sentry';

const useCaptureError = (error: any, scope?: string) => {
  useEffect(() => {
    error &&
      captureExceptionWithScope({
        error,
        tag: scope
      });
  }, [error, scope]);
};

export default useCaptureError;
