import { ComponentProps } from 'react';
import useDelayedState from '~hooks/use-delayed-state';
import { cn } from '~utils/tailwind';

import { useSelectBox } from './context';

type Props = ComponentProps<'div'>;

const SelectBoxDropdown = ({ children, ...props }: Props) => {
  const { className } = props;
  const { open } = useSelectBox();
  const show = useDelayedState({ active: open });

  return (
    <div
      {...props}
      className={cn(
        'absolute bottom-[56px] left-[36px] z-floated h-fit w-full overflow-hidden rounded-xl border border-solid border-gray-200 bg-white shadow-elevation-1 transition-all motion-reduce:transition-none',
        open ? 'opacity-1' : 'border-none opacity-0',
        className
      )}
    >
      {show && children}
    </div>
  );
};

export default SelectBoxDropdown;
