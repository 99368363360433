import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { PortalProps } from './types';

const Portal = ({ id, children }: PortalProps) => {
  const [element, setElement] = useState<Element | null>(null);

  useEffect(() => {
    setElement(document.querySelector(`#${id}`));
  }, [id]);

  if (!element) {
    return <></>;
  }

  return createPortal(children, element);
};

export default Portal;
