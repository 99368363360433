import { useQuery } from '@tanstack/react-query';
import { api } from '~utils/api';
import { liveConsultationKeys } from './query-keys';

export type LiveConsultationPrice = {
  price: number;
};

const fetchLiveConsultationPrice = async () => {
  return (await api.get<LiveConsultationPrice>('/vet/lct_price/')).data;
};

export const useLiveConsultationPrice = () => {
  return useQuery<LiveConsultationPrice>({
    queryKey: liveConsultationKeys.price(),
    queryFn: fetchLiveConsultationPrice
  });
};
