import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';
import { liveConsultationKeys } from '~features/live-consultation/hooks/queries/query-keys';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { api } from '~utils/api';

const fetchLiveConsultation = async (): Promise<LiveConsultation.Level2> => {
  return (await api.get<LiveConsultation.Level2>(`/vet/lc/`)).data;
};

export const useLiveConsultation = () => {
  const { data: myProfile } = useMyProfile();
  return useQuery<LiveConsultation.Level2, AxiosError>({
    queryKey: liveConsultationKeys.ongoingSession(),
    queryFn: fetchLiveConsultation,
    enabled: !!myProfile?.vet.id,
    staleTime: 0,
    gcTime: 0
  });
};
