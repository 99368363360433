import { LocalVideoTrack } from 'twilio-video';
import useVideoContext from '~twilio/hooks/use-video-context';

import { VideoTrack } from '../video-track';

export const LocalVideo = () => {
  const { localTracks } = useVideoContext();
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  return videoTrack ? (
    <VideoTrack track={videoTrack} isLocal />
  ) : (
    <p className='text-white text-title-1'>Your camera is muted</p>
  );
};
