import { useEffect, useState } from 'react';

type Props = Readonly<{
  active: boolean;
  delay?: number;
}>;

const useDelayedState = ({ active, delay = 150 }: Props) => {
  const [show, setShow] = useState(active);

  useEffect(() => {
    if (active) {
      setShow(true);
    } else {
      const timer = setTimeout(() => {
        setShow(false);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [delay, active]);

  return show;
};

export default useDelayedState;
