import {
  MutationObserverOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { api } from '~utils/api';
import { liveConsultationKeys } from '../queries/query-keys';
import { toast } from '~components/@ui/toast/controller';
import { captureExceptionWithScope } from '~utils/sentry';

export type UpdateAvailableTime = {
  time: string; // e.g. '2019-08-24T14:15:00Z'
  is_on: boolean; // true if the time is available
};

interface UpdateLiveConsultationAvailableTimesParams {
  updatedTimes: UpdateAvailableTime[];
}

const updateLiveConsultationAvailableTimes = async ({
  updatedTimes
}: Readonly<UpdateLiveConsultationAvailableTimesParams>) => {
  return (await api.patch<string[]>('/lcs/available_time/', updatedTimes)).data;
};

const useUpdateLiveConsultationAvailableTimes = (
  options?: MutationObserverOptions<
    string[],
    Error,
    UpdateLiveConsultationAvailableTimesParams
  >
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateLiveConsultationAvailableTimes,
    onSuccess: (res, variables, context) => {
      options?.onSuccess?.(res, variables, context);
      queryClient.setQueryData(liveConsultationKeys.availableTimes(), res);
    },
    onError: (err, variables, context) => {
      options?.onError?.(err, variables, context);
      toast.open({
        message: 'Failed to update available times',
        type: 'error'
      });
      captureExceptionWithScope({
        error: err,
        tag: 'updateLiveConsultationAvailableTimes'
      });
    },
    ...options
  });
};

export default useUpdateLiveConsultationAvailableTimes;
