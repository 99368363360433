import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import Loading from '~components/loading/loading';
import { getMediaType } from '~utils/media';
import { cn } from '~utils/tailwind';

import Media from '../media/component';
import { MediaSize } from '../media/types';

const MediaModal = lazy(async () => await import('./media-modal'));

type MediaListProps = Readonly<{
  photoUrls?: string[];
  videoUrls?: string[];
  size?: MediaSize;
}>;

const useMedia = ({ photoUrls, videoUrls }: MediaListProps) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const mediaUrls = videoUrls?.concat(photoUrls ?? []) || [];
  const focusedMediaUrl = focusedIndex !== null ? mediaUrls[focusedIndex] : '';

  const onMediaClick = useCallback((index: number) => {
    setFocusedIndex(index);
  }, []);

  const onPrevClick = useCallback(() => {
    setFocusedIndex((prev) => {
      if (prev === null) {
        return prev;
      }
      return prev > 0 ? prev - 1 : prev;
    });
  }, []);

  const onNextClick = useCallback(() => {
    setFocusedIndex((prev) => {
      if (prev === null) {
        return prev;
      }
      return mediaUrls.length - 1 > prev ? prev + 1 : prev;
    });
  }, [mediaUrls.length]);

  const onRequestClose = useCallback(() => {
    setFocusedIndex(null);
  }, []);

  const onKeyPress = useCallback(
    (event: globalThis.KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onPrevClick();
      }

      if (event.key === 'ArrowRight') {
        onNextClick();
      }
    },
    [onNextClick, onPrevClick]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);

  return {
    mediaUrls,
    onMediaClick,
    mediaModalProps: {
      url: focusedMediaUrl,
      totalMediaCount: mediaUrls.length,
      focusedIndex,
      onPrevClick,
      onNextClick,
      onRequestClose
    }
  };
};

const MediaList = ({ photoUrls, videoUrls, size }: MediaListProps) => {
  const { mediaUrls, onMediaClick, mediaModalProps } = useMedia({
    photoUrls,
    videoUrls
  });

  return (
    <>
      <div className='no-scrollbar flex gap-2 overflow-x-scroll'>
        {mediaUrls.map((url, index) => (
          <Media
            key={url}
            url={url}
            alt='thumbnail'
            size={size}
            className={cn(getMediaType(url) === 'photo' && 'last:mr-5')}
            onClick={() => {
              onMediaClick(index);
            }}
          />
        ))}
      </div>
      {mediaModalProps.focusedIndex !== null && (
        <Suspense fallback={<Loading isLoading />}>
          <MediaModal {...mediaModalProps} />
        </Suspense>
      )}
    </>
  );
};

export default MediaList;
