import { memo } from 'react';
import Button from '~components/@ui/button/button/component';
import Icon from '~components/@ui/dialog/icon';
import { useCloseLiveConsultationTicket } from '~features/live-consultation/hooks/mutations/use-close-live-consultation-ticket';
import { useLiveConsultation } from '~features/live-consultation/hooks/queries/use-live-consultation';
import { useSpentTime } from '~twilio/hooks/use-spent-time';
import { cn } from '~utils/tailwind';

interface TimerButtonProps {
  className?: string;
}

const TimerButton = memo(({ className }: Readonly<TimerButtonProps>) => {
  const { data: lc } = useLiveConsultation();
  const { spentTime } = useSpentTime();
  const { mutate: liveConsultationClose } = useCloseLiveConsultationTicket();

  return (
    <Button
      priority='destructive'
      className={cn('flex h-[44px] w-[200px] flex-row gap-4', className)}
      onClick={() => {
        if (!lc?.id) {
          return;
        }
        liveConsultationClose(lc?.id);
      }}
    >
      <Icon name='call' className='mt-6 h-5 w-5' />
      <p className='text-white text-title-3'>{spentTime}</p>
    </Button>
  );
});
TimerButton.displayName = 'TimerButton';
export default TimerButton;
