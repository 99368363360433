import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import Consultation from '~features/consultation/models';
import { api } from '~utils/api';

import { consultationKeys } from './query-keys';

const fetchConsultationDetail = async (id: number) =>
  (await api.get<Consultation.Level2>(`/consultations/${id}/`)).data;

const useConsultationDetail = (id: number) => {
  return useQuery<Consultation.Level2, AxiosError>({
    queryKey: consultationKeys.consultationDetail(id),
    queryFn: async () => await fetchConsultationDetail(id),
    enabled: !!id && id > 0
  });
};

export default useConsultationDetail;
