import { cn } from '~utils/tailwind';

type SpinnerColor = 'primary' | 'gray';
type SpinnerColorCss = { [key in SpinnerColor]: string };
type SpinnerSize = '32px'; // 필요 시 다른 사이즈 추가, 네이밍은 추후 sm, md, lg 등으로 변경될 수 있음
type SpinnerSizeCss = { [key in SpinnerSize]: string };

type SpinnerProps = Readonly<{
  color?: SpinnerColor;
  size?: SpinnerSize;
}>;

const SPINNER_COLOR_CSS: SpinnerColorCss = {
  primary: 'border-primary-500',
  gray: 'border-gray-500'
};

const SPINNER_SIZE_CSS: SpinnerSizeCss = {
  '32px': 'h-8 w-8'
};

/**
 * @version 1.1.0
 * @author Danny
 */
const Spinner = ({ color = 'primary', size = '32px' }: SpinnerProps) => {
  return (
    <div
      className={cn(
        'relative flex animate-spin rounded-full',
        SPINNER_SIZE_CSS[size]
      )}
    >
      <div
        className={cn(
          'h-full w-1/2 rounded-l-full border-b-2 border-l-2 border-t-2 border-solid ',
          SPINNER_COLOR_CSS[color]
        )}
      />
      <div className='h-full w-1/2 rounded-r-full border-b-2 border-r-2 border-t-2 border-solid border-gray-300' />
    </div>
  );
};

export default Spinner;
