import { cn } from '~utils/tailwind';

import { TitleProps } from './types';

/**
 * @version v1.0.1
 * @author Danny
 * @description leadingElements, trailingElements prop 제거
 */
const Title = ({ size = 'large', children, className }: TitleProps) => {
  return (
    <div
      className={cn(
        'flex w-full items-center justify-between gap-4 text-gray-900',
        size === 'small' ? 'text-headline-3' : 'text-headline-1',
        size === 'large' && 'h-20',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Title;
