import { cn } from '~utils/tailwind';

import { TextInputHelperProps } from './types';

const TextInputHelper = ({
  helperMessage,
  showMaxLength,
  maxLength,
  valueLength,
  isError,
  isDisabled,
  readOnly,
  className
}: TextInputHelperProps) => {
  const render = !!helperMessage?.length || (!!maxLength && showMaxLength);

  if (!render) {
    return <></>;
  }

  return (
    <div className={cn('flex items-center justify-between', className)}>
      {helperMessage && (
        <div
          className={cn('text-gray-500 text-body-2', {
            'text-red-500': isError,
            'text-gray-300': isDisabled ?? readOnly
          })}
        >
          {helperMessage}
        </div>
      )}
      {maxLength && showMaxLength && (
        <div className='text-gray-500 text-body-2'>{`${valueLength}/${maxLength}`}</div>
      )}
    </div>
  );
};

export default TextInputHelper;
