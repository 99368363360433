import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const RANGE = 10;

const getRange = (start = 1, end = 10): number[] => {
  return Array(end)
    .fill(0)
    .map((_, index) => index + start);
};

interface PaginationProps {
  currentPage?: number;
  totalPageNum?: number;
}

const usePagination = ({ currentPage, totalPageNum }: PaginationProps) => {
  const pageRange = totalPageNum && totalPageNum < RANGE ? totalPageNum : RANGE;

  const [searchParams, setSearchParams] = useSearchParams();
  const [last_page, setLastPage] = useState(pageRange);
  const firstPageNum = useMemo(
    () => (last_page <= RANGE ? 1 : last_page - RANGE + 1),
    [last_page]
  );

  const pages = useMemo(
    () => getRange(firstPageNum, pageRange),
    [firstPageNum, pageRange]
  );

  useEffect(() => {
    setLastPage(() => pageRange);
  }, [pageRange]);

  const onPrevClick = (firstPage?: number) => {
    if (!currentPage) {
      return;
    }

    if (firstPage) {
      searchParams.set('page', '1');
      setLastPage(RANGE);
      setSearchParams(searchParams);
      return;
    }

    if (firstPageNum === currentPage) {
      setLastPage(() => last_page - 1);
    }

    searchParams.set('page', (currentPage - 1).toString());
    setSearchParams(searchParams);
  };

  const onNextClick = (totalPage?: number) => {
    if (!currentPage) {
      return;
    }

    if (totalPage) {
      searchParams.set('page', totalPage.toString());
      setLastPage(totalPage);
      setSearchParams(searchParams);
      return;
    }

    if (last_page === currentPage) {
      setLastPage(last_page + 1);
    }

    searchParams.set('page', (currentPage + 1).toString());
    setSearchParams(searchParams);
  };

  const onPageNumClick = (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  return {
    pages,
    onPrevClick,
    onNextClick,
    onPageNumClick
  };
};

export default usePagination;
