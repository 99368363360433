import React, { createContext, useMemo } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useGalleryViewParticipants from '~twilio/hooks/use-gallery-view-participants';
import useSpeakerViewParticipants from '~twilio/hooks/use-speaker-view-participants';

/**
 * The purpose of the ParticipantProvider component is to ensure that the hooks useGalleryViewParticipants
 * and useSpeakerViewParticipants are not unmounted as users switch between Gallery View and Speaker View.
 * This will make sure that the ordering of the participants on the screen will remain so that the most
 * recent dominant speakers are always at the front of the list.
 */

export interface IParticipantContext {
  mobileGalleryViewParticipants: RemoteParticipant[];
  galleryViewParticipants: RemoteParticipant[];
  speakerViewParticipants: RemoteParticipant[];
}

export const ParticipantContext = createContext<IParticipantContext | null>(
  null
);

interface Props {
  children: React.ReactNode;
}

export const ParticipantProvider = ({ children }: Props) => {
  const mobileGalleryViewParticipants = useGalleryViewParticipants(true);
  const galleryViewParticipants = useGalleryViewParticipants();
  const speakerViewParticipants = useSpeakerViewParticipants();

  const providerValue = useMemo(
    () => ({
      mobileGalleryViewParticipants,
      galleryViewParticipants,
      speakerViewParticipants
    }),
    [
      galleryViewParticipants,
      mobileGalleryViewParticipants,
      speakerViewParticipants
    ]
  );

  return (
    <ParticipantContext.Provider value={providerValue}>
      {children}
    </ParticipantContext.Provider>
  );
};
