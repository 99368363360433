import { useSetAtom } from 'jotai';
import { useMutation } from '@tanstack/react-query';
import { __FIREBASE_TOKEN__ } from '~constants';
import { loadingAtom } from '~features/app/atoms/loading';
import { setToken } from '~hooks/use-log-in-status';
import { api } from '~utils/api';

export type LogInParams = Readonly<{
  email: string;
  password: string;
}>;

type Response = Readonly<{
  access_token: string;
  refresh_token: string;
}>;

const logIn = async (params: LogInParams) => {
  const firebase_token = localStorage.getItem(__FIREBASE_TOKEN__);

  return (
    await api.post('/account/signin/', {
      ...params,
      firebase_token
    })
  ).data;
};

const useLogIn = () => {
  const setLoading = useSetAtom(loadingAtom);

  return useMutation<Response, Error, LogInParams>({
    mutationFn: logIn,
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: ({ access_token, refresh_token }) => {
      setToken(access_token, refresh_token);
    },
    onSettled: () => {
      setLoading(false);
    }
  });
};

export default useLogIn;
