import useBlockScroll from '~hooks/use-block-scroll';
import { cn } from '~utils/tailwind';

import Loader from '../@ui/loading-indicator/loader';
import Spinner from '../@ui/loading-indicator/spinner';

interface LoadingProps {
  isLoading: boolean;
  withLightBox?: boolean;
  type?: 'spinner' | 'loader';
}

/**
 * 특정 요청이 진행중임을 유저에게 알리기 위해 사용되는 컴포넌트
 * 화면 전체에 z-index를 가지며, 유저의 이탈을 방지하는 데 사용됩니다.
 * 선택적으로 라이트박스를 띄울 수 있습니다.
 */
const Loading = ({
  isLoading,
  withLightBox = true,
  type = 'spinner'
}: Readonly<LoadingProps>) => {
  useBlockScroll(isLoading);

  if (!isLoading) {
    return <></>;
  }

  return (
    <div
      className={cn(
        'fixed bottom-0 left-0 right-0 top-0 z-lightBox flex items-center justify-center',
        withLightBox && 'bg-white/80'
      )}
    >
      {type === 'spinner' ? <Spinner /> : <Loader />}
    </div>
  );
};

export default Loading;
