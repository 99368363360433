import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState
} from 'react';
import { RoomType } from 'src/types';
import { TwilioError } from 'twilio-video';
import { useLocalStorageState } from '~twilio/hooks/use-local-storage-state';

import {
  initialSettings,
  Settings,
  SettingsAction,
  settingsReducer
} from './settings/settings-reducer';
import useActiveSinkId from './use-active-sink-id/useActiveSinkId';

export interface TwilioRoomStateContextType {
  activeSinkId: string;
  setActiveSinkId: (sinkId: string) => void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;

  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  isKrispEnabled: boolean;
  setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKrispInstalled: boolean;
  setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  error?: TwilioError | Error | null;
  setError?: (error: TwilioError | Error | null) => void;
  user?: null | {
    displayName: undefined;
    photoURL: undefined;
    passcode?: string;
  };
  signIn?: (passcode?: string) => Promise<void>;
  signOut?: () => Promise<void>;
  isAuthReady?: boolean;
  roomType?: RoomType;
}

export const TwilioRoomStateContext = createContext<TwilioRoomStateContextType>(
  null!
);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
// eslint-disable-next-line react/function-component-definition, @typescript-eslint/ban-types
export default function TwilioRoomStateProvider({
  children // eslint-disable-next-line @typescript-eslint/ban-types
}: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | Error | null>(null);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState(
    'gallery-view-active-key',
    true
  );
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [roomType] = useState<RoomType>();
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] =
    useLocalStorageState('max-gallery-participants-key', 6);

  const [isKrispEnabled, setIsKrispEnabled] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);

  const providerValue: TwilioRoomStateContextType = useMemo(
    () => ({
      error,
      setError,
      activeSinkId,
      setActiveSinkId,
      settings,
      dispatchSetting,
      roomType,
      isGalleryViewActive,
      setIsGalleryViewActive,
      maxGalleryViewParticipants,
      setMaxGalleryViewParticipants,
      isKrispEnabled,
      setIsKrispEnabled,
      isKrispInstalled,
      setIsKrispInstalled
    }),
    [
      activeSinkId,
      error,
      isGalleryViewActive,
      isKrispEnabled,
      isKrispInstalled,
      maxGalleryViewParticipants,
      roomType,
      setActiveSinkId,
      setIsGalleryViewActive,
      setMaxGalleryViewParticipants,
      settings
    ]
  );

  return (
    <TwilioRoomStateContext.Provider value={providerValue}>
      {children}
    </TwilioRoomStateContext.Provider>
  );
}

export function useTwilioRoomState() {
  const context = useContext(TwilioRoomStateContext);
  if (!context) {
    throw new Error(
      'useTwilioRoomState must be used within the AppStateProvider'
    );
  }
  return context;
}
