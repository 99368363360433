import { useAtom } from 'jotai';

import { scheduledLiveConsultationOngoingAtom } from '../atoms/scheduled-live-consultation-ongoing';

export const useScheduledLiveConsultationOngoingDialog = () => {
  const [scheduleDialog, setScheduleDialog] = useAtom(
    scheduledLiveConsultationOngoingAtom
  );

  const close = () => {
    setScheduleDialog({
      isOpen: false
    });
  };

  return {
    isOpen: !!scheduleDialog?.isOpen,
    close
  };
};
