import { ComponentProps, ReactNode } from 'react';
import { cn } from '~utils/tailwind';

type DialogContentProps = Readonly<
  ComponentProps<'div'> & {
    children: ReactNode;
  }
>;

const DialogContent = ({
  children,
  className,
  ...props
}: DialogContentProps) => {
  return (
    <div
      {...props}
      className={cn('px-6 py-2 text-gray-700 text-body-1', className)}
    >
      {children}
    </div>
  );
};

export default DialogContent;
