import { Participant } from 'twilio-video';
import { MainParticipant } from '~twilio/components/main-participant';
import { Participant as ParticipantTrack } from '~twilio/components/participant';
import TimerButton from '~twilio/components/timer-button';
import { ToggleAudioButton } from '~twilio/components/toggle-audio-button';
import { ToggleScreenShareButton } from '~twilio/components/toggle-screen-share-button';
import { ToggleVideoButton } from '~twilio/components/toggle-video-button';
import useSelectedParticipant from '~twilio/components/video-provider/use-selected-participant';
import useDominantSpeaker from '~twilio/hooks/use-dominant-speaker';
import useMainParticipant from '~twilio/hooks/use-main-participant';
import useParticipantsContext from '~twilio/hooks/use-participants-context';
import useVideoContext from '~twilio/hooks/use-video-context';
import { cn } from '~utils/tailwind';

const MobileSignature = '#mobile';

const ParticipantsRoom = () => {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker(true);
  const { galleryViewParticipants } = useParticipantsContext();
  const [selectedParticipant, setSelectedParticipant] =
    useSelectedParticipant();

  const localParticipant = room?.localParticipant as Participant;
  const counterParticipant = galleryViewParticipants.find(
    (participant) => !participant.identity.includes(MobileSignature)
  );

  const mobileParticipant = galleryViewParticipants.find((participant) =>
    participant.identity.includes(MobileSignature)
  );
  const mainParticipant = useMainParticipant();

  return (
    <div className='relative flex h-full w-full flex-col items-center justify-center'>
      <div className='absolute right-0 top-0 z-20 flex h-full flex-col gap-4 p-8 align-baseline'>
        {/* 보호자가 접속하지 않았으면 우측 썸네일 화면들 전부 안보임  */}
        {!counterParticipant ? null : (
          <>
            {/* 메인 화면이 수의사가 아닐 때 우측상단에 수의사 화면  */}
            {mainParticipant?.sid !== localParticipant?.sid && (
              <div className='lg-w-[320px] lg-h-[320px] flex h-[240px] w-[240px] items-center justify-center rounded-[18px] bg-black'>
                <ParticipantTrack
                  participant={localParticipant}
                  isLocalParticipant
                  isDominantSpeaker={dominantSpeaker === localParticipant}
                  isSelected={selectedParticipant === localParticipant}
                  enableScreenShare
                  onClick={() => {
                    setSelectedParticipant(localParticipant);
                  }}
                />
              </div>
            )}

            {/* 메인 화면이 보호자가 아닐 때 우측상단에 보호자 화면  */}
            {mainParticipant?.sid !== counterParticipant?.sid && (
              <div className='lg-w-[320px] lg-h-[320px] flex h-[240px] w-[240px] items-center justify-center rounded-[18px] bg-black'>
                {counterParticipant ? (
                  <ParticipantTrack
                    participant={counterParticipant}
                    isDominantSpeaker={dominantSpeaker === counterParticipant}
                    isSelected={selectedParticipant === counterParticipant}
                    onClick={() => {
                      setSelectedParticipant(counterParticipant);
                    }}
                  />
                ) : (
                  <p className='text-white text-body-1'>
                    Waiting for the client...
                  </p>
                )}
              </div>
            )}

            {/* 메인 화면이 모바일이 아닐 때 우측상단에 모바일 화면  */}
            {mainParticipant?.sid !== mobileParticipant?.sid && (
              <div className='lg-w-[320px] lg-h-[320px] flex h-[240px] w-[240px] items-center justify-center rounded-[18px] bg-black'>
                {mobileParticipant ? (
                  <ParticipantTrack
                    participant={mobileParticipant}
                    isDominantSpeaker={dominantSpeaker === mobileParticipant}
                    isSelected={selectedParticipant === mobileParticipant}
                    onClick={() => {
                      setSelectedParticipant(mobileParticipant);
                    }}
                  />
                ) : (
                  <p className='text-white text-body-1'>
                    Waiting for the pet...
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={cn(
          'flex h-full w-full flex-row items-center justify-center ',
          counterParticipant
            ? 'bg-black'
            : 'bg-gradient-to-t from-[#111419] to-[#12336B]'
        )}
      >
        {counterParticipant ? (
          <MainParticipant />
        ) : (
          <p className=' text-white text-headline-1'>Please wait...</p>
        )}
      </div>
      <div className='absolute bottom-[5%] left-0 z-30 flex w-full flex-row justify-center'>
        <div className='flex flex-row items-center justify-center rounded-2xl bg-black bg-opacity-80 p-3'>
          <div className='flex flex-row items-center justify-center gap-[48px]'>
            <div className='relative flex flex-row gap-[16px] overflow-hidden'>
              <ToggleAudioButton />
              <ToggleVideoButton />
              <ToggleScreenShareButton />
            </div>
            <TimerButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsRoom;
