import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { NoShowPractice } from '~features/no-shows/types';
import { api } from '~utils/api';
import noShowKeys from './query-keys';

interface FetchNoShowTimesPracticeParams {
  hospital_name: string;
}
const fetchNoShowTimesPractice = async ({
  hospital_name
}: Readonly<FetchNoShowTimesPracticeParams>) => {
  return (
    await api.get<NoShowPractice[]>('/no_show_times/booking/', {
      baseURL: api.defaults.baseURL?.replace('vapp', 'tips'),
      params: {
        hospital_name
      }
    })
  ).data;
};

const useNoShowTimesPractice = (
  hospital_name: string,
  options?: UseQueryOptions<NoShowPractice[], Error>
) => {
  const queryResults = useQuery({
    ...options,
    queryKey: noShowKeys.practice(hospital_name),
    queryFn: async () =>
      await fetchNoShowTimesPractice({
        hospital_name
      }),
    enabled: !!hospital_name
  });

  const records =
    queryResults.data?.map((data) => {
      const date = new Date(data.no_show_time.time);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const time = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`; // HH:MM
      return {
        date: date.toLocaleDateString('en-US'),
        time,
        pet_name: data.pet_name,
        checkup: JSON.stringify(data.consultation_checkup),
        severity: data.consultation_severity,
        medical_records: (data.pet_medical_records ?? []).map(
          (record) => record.processed_url
        )
      };
    }) || [];

  return {
    ...queryResults,
    records
  };
};

export default useNoShowTimesPractice;
