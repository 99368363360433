import { ButtonHTMLAttributes } from 'react';
import { CheckBox } from '~components/@ui/control/check-box/component';
import { ILiveConsultationTimeBox } from '~features/live-consultation/hooks/queries/use-live-consultation-timeboxes';
import { cn } from '~utils/tailwind';

interface ReserveTimeboxButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  checked?: boolean;
}
const ReserveTimeboxButton = ({
  label,
  checked,
  ...buttonProps
}: Readonly<ReserveTimeboxButtonProps>) => {
  return (
    <button
      className={cn(
        'flex items-center gap-x-2 rounded-[8px] border-[1px] px-4 py-2',
        checked
          ? 'border-primary-500 bg-white'
          : 'border-transparent bg-gray-100'
      )}
      {...buttonProps}
    >
      <CheckBox checked={checked} />
      <span
        className={cn(
          checked
            ? 'text-primary-500 text-label-1'
            : 'text-gray-700 text-body-2'
        )}
      >
        {label}
      </span>
    </button>
  );
};

interface ReserveTimeboxButtonGroupProps {
  timeboxes: ILiveConsultationTimeBox[];
  onTimeboxChange: (timebox: ILiveConsultationTimeBox) => void;
  label?: string;
}
export const ReserveTimeboxButtonGroup = ({
  label,
  timeboxes,
  onTimeboxChange
}: Readonly<ReserveTimeboxButtonGroupProps>) => {
  const onClick = (trigger: ILiveConsultationTimeBox) => {
    onTimeboxChange(trigger);
  };

  return (
    <div className='flex flex-col'>
      {label && <p className='mb-4 text-gray-900 text-title-3'>{label}</p>}
      <div className='flex flex-row flex-wrap gap-2'>
        {timeboxes.map((timebox) => (
          <ReserveTimeboxButton
            key={timebox.start_time}
            label={timebox.start_time}
            checked={timebox.is_enabled}
            onClick={() => {
              onClick(timebox);
            }}
          />
        ))}
      </div>
    </div>
  );
};
