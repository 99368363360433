import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { Resolver, useForm, UseFormRegister } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  CheckBox,
  CheckBoxContainer
} from '~components/@ui/control/check-box/component';
import Button from '~components/@ui/dialog/button';
import { Label } from '~components/@ui/label/component';
import TextArea from '~components/@ui/text-input/text-area';
import { closedLiveConsultationAtom } from '~features/live-consultation/atoms/closed-live-consultation';
import usePatchLiveConsultation, {
  PatchLiveConsultationParams
} from '~features/live-consultation/hooks/mutations/use-patch-live-consultation';
import { liveConsultationKeys } from '~features/live-consultation/hooks/queries/query-keys';
import { useLiveConsultation } from '~features/live-consultation/hooks/queries/use-live-consultation';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { TicketType } from '~features/live-consultation/types';
import useUpdateStatus from '~features/vet/hooks/mutations/use-update-status';
import Vet from '~features/vet/models';
import { ParticipantAudioTracks } from '~twilio/components/participant-audio-tracks';
import { getDayjsFormat } from '~utils/format/dayjs';

import ParticipantsRoom from './participants-room';
import { getGender } from '~features/pet/utils';

const NOTE_FORM_ID = 'note';

interface SideSheetContentsProps {
  register: UseFormRegister<PatchLiveConsultationParams>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  checked: boolean;
  onSwitchPress: () => void;
  live_consultation?: LiveConsultation.Level2;
}
const SideSheetContents = ({
  register,
  onSubmit,
  checked,
  onSwitchPress,
  live_consultation
}: SideSheetContentsProps) => {
  const [disabled, setDisabled] = useState(false);

  if (!live_consultation) {
    return null;
  }

  return (
    <div className='h-screen min-w-[430px] overflow-y-scroll pb-5'>
      {/* Header */}
      <div className='flex w-full flex-row justify-between border-b border-solid border-gray-300 px-7 py-5'>
        <CheckBoxContainer
          disabled
          className='w-fit rounded-lg border border-solid border-transparent'
        >
          <CheckBox checked={checked} onChange={onSwitchPress} />
          <span className='ml-2 text-gray-900 text-body-1'>
            Set status as away after this session
          </span>
        </CheckBoxContainer>
        <Label color='orange'>
          <div className='h-3 w-3 rounded-full bg-orange-500' />
          BUSY
        </Label>
      </div>
      {/* Body */}
      <div className='w-[480px] px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Session Information</p>
      </div>
      <div className='p w-full px-5 pb-5 pt-2'>
        <div className='mb-5 rounded-xl bg-gray-50 p-3'>
          <div className='flex flex-col gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Session Type</p>
            <p className='text-gray-900 text-label-2'>
              {TicketType[live_consultation.ticket_type || TicketType.General]}
            </p>
          </div>
          <div className='flex flex-col gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Reserved time slot</p>
            <p className='text-gray-900 text-label-2'>
              {getDayjsFormat(live_consultation.created_at, 'full')}
            </p>
          </div>
          <div className='flex flex-col gap-1 p-1'>
            <p className='text-gray-700 text-body-3'>Issue</p>
            <p className='text-gray-900 text-label-2'>
              {live_consultation.category_name || '-'}
            </p>
          </div>
        </div>
      </div>
      <div className='p w-full px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Pet Information</p>
      </div>
      <div className='p w-full px-5 pb-5 pt-2'>
        <div className='mb-5 rounded-xl bg-gray-50 p-3'>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Name</p>
            <p className='text-gray-900 text-label-2'>
              {live_consultation.pet.name}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Species</p>
            <p className='text-gray-900 text-label-2'>
              {live_consultation.pet.kind.breed}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Breed</p>
            <p className='text-gray-900 text-label-2'>
              {live_consultation.pet.kind.species}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Age</p>
            <p className='text-gray-900 text-label-2'>
              {live_consultation.pet.age}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Gender</p>
            <p className='text-gray-900 text-label-2'>
              {getGender(live_consultation.pet.gender)}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Status</p>
            <p className='text-gray-900 text-label-2'>
              {live_consultation.pet.is_neutered ? 'Neutered or spayed' : '-'}
            </p>
          </div>
        </div>
      </div>
      <div className='p w-full px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Note</p>
      </div>
      <form onSubmit={onSubmit}>
        <div className='flex flex-col gap-3 px-7 pb-5 pt-2'>
          <TextArea
            isAutoResizeMode
            {...register(NOTE_FORM_ID, {
              required: true,
              onChange: (e) => {
                setDisabled(!e.target.value.length);
              }
            })}
          />
        </div>
        <div className='px-7'>
          <Button
            priority='primary'
            size='medium'
            type='submit'
            disabled={disabled}
          >
            Save note
          </Button>
        </div>
      </form>
    </div>
  );
};

const resolver: Resolver<PatchLiveConsultationParams> = async (values) => ({
  values,
  errors: {}
});

const Room = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const queryClient = useQueryClient();
  const { data: lc } = useLiveConsultation();
  const [closedLiveConsultation, setClosedLiveConsultation] = useAtom(
    closedLiveConsultationAtom
  );

  const { mutate: patchStatus } = useUpdateStatus();
  const { mutateAsync: patchLiveConsultation } = usePatchLiveConsultation({
    onSuccess: (res) => {
      lc &&
        queryClient.setQueryData(liveConsultationKeys.ongoingSession(), res);
    }
  });

  const live_consultation = useMemo(() => {
    return lc || closedLiveConsultation || undefined;
  }, [closedLiveConsultation, lc]);

  const { register, handleSubmit, getValues } =
    useForm<PatchLiveConsultationParams>({
      resolver,
      defaultValues: {
        note: live_consultation?.note
      }
    });

  const onSubmit = useCallback(async () => {
    const values = getValues();
    if (!live_consultation?.id) {
      return;
    }
    if (values.note?.length) {
      await patchLiveConsultation({
        id: live_consultation.id,
        note: values.note
      });
    }
  }, [getValues, live_consultation?.id, patchLiveConsultation]);

  const saveNoteAndLeave = useCallback(
    async (isAwayCheckboxChecked: boolean) => {
      if (!live_consultation?.id) {
        return;
      }
      await onSubmit();
      if (isAwayCheckboxChecked) {
        patchStatus({
          consultation_status: Vet.ConsultationStatus.Away
        });
      }
      setClosedLiveConsultation(RESET);

      navigate('/live-consultation');
    },
    [
      live_consultation?.id,
      navigate,
      onSubmit,
      patchStatus,
      setClosedLiveConsultation
    ]
  );

  const saveNote = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      void handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  const onSwitchPress = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      <div className='flex h-full w-full'>
        {!lc ? (
          <div className='flex flex-1 flex-col items-center justify-center gap-5'>
            <p className='text-gray-700 text-headline-3'>
              Session is finished.
            </p>
            <Button
              priority='primary'
              size='large'
              type='submit'
              onClick={() => {
                void saveNoteAndLeave(checked);
              }}
            >
              Save note and leave
            </Button>
          </div>
        ) : (
          <>
            <ParticipantAudioTracks />
            <ParticipantsRoom />
          </>
        )}
        <SideSheetContents
          register={register}
          onSubmit={saveNote}
          checked={checked}
          onSwitchPress={onSwitchPress}
          live_consultation={live_consultation}
        />
      </div>
    </>
  );
};

export default Room;
