import { cn } from '~utils/tailwind';

import { CheckBoxContainerProps, CheckBoxProps } from './types';

const CheckBoxSvg = ({
  type = 'default',
  checked
}: Pick<CheckBoxProps, 'type' | 'checked'>) => {
  return (
    <svg
      className={`h-4 w-4 stroke-white stroke-[3px] ${
        checked ? 'visible' : 'invisible'
      }`}
      style={{ strokeLinecap: 'round' }}
      viewBox='0 0 24 24'
    >
      {type === 'indetermined' ? (
        <line x1={5} y1={12} x2={19} y2={12} />
      ) : (
        <>
          <line x1={5} y1={11} x2={11} y2={17} />
          <line x1={11} y1={17} x2={18} y2={7} />
        </>
      )}
    </svg>
  );
};

export const CheckBoxContainer = ({
  disabled,
  htmlFor,
  children,
  className,
  ...props
}: CheckBoxContainerProps) => {
  return (
    <label
      {...props}
      className={cn(
        'group flex w-full items-center border',
        !disabled && 'cursor-pointer',
        className
      )}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};

/**
 * @version v1.0.1
 * @author Danny
 * @description
 * - checked 상태값 핸들링은 해당 컴포넌트 단독 사용 시 onClick으로,
 * - CheckBoxContainer와 함께 사용 시 onChange를 사용합니다.
 */
export const CheckBox = ({
  id,
  type = 'default',
  checked,
  disabled,
  onClick,
  ...props
}: CheckBoxProps) => {
  return (
    <div
      role='presentation'
      className='relative flex cursor-pointer items-center'
      onClick={onClick}
    >
      <input
        {...props}
        id={id}
        type='checkbox'
        className='invisible absolute h-4 w-4'
      />
      <div
        className={cn('inline-block rounded-sm border border-solid', {
          'border-primary-100 bg-primary-100': checked && disabled,
          'border-primary-500 bg-primary-500 active:border-primary-600 active:bg-primary-600 group-hover:border-primary-400 group-hover:bg-primary-400':
            checked && !disabled,
          'border-gray-100 bg-gray-100': !checked && disabled,
          'border-gray-400 bg-white': !checked && !disabled
        })}
      >
        <CheckBoxSvg type={type} checked={checked} />
      </div>
    </div>
  );
};

/**
 * @usage
 * type CheckBoxItemProps = Readonly<
    CheckBoxProps & {
      label: string;
    }
  >;

  const CheckBoxItem = ({
    disabled,
    checked,
    label,
    onChange,
  }: CheckBoxItemProps) => {
    const id = uid(10);

    return (
      <CheckBoxContainer
        disabled={disabled}
        htmlFor={id}
        className={cn(
          'w-full rounded-lg border border-solid border-transparent px-4 py-3',
          checked && 'border-primary-500',
        )}
      >
        <CheckBox
          disabled={disabled}
          checked={checked}
          id={id}
          onChange={onChange}
        />
        <span className="ml-4 text-gray-900 text-body-1">{label}</span>
      </CheckBoxContainer>
    );
  };
 */
