import { useQuery } from '@tanstack/react-query';
import Vet from '~features/vet/models';
import useLogInStatus from '~hooks/use-log-in-status';
import { api } from '~utils/api';

import { authKeys } from './query-keys';

export type ProfileResponse = Readonly<{
  email: string;
  vet: Vet.Level1;
}>;

const fetchMyProfile = async () =>
  (await api.get<ProfileResponse>('/vet/me/')).data;

const useMyProfile = () => {
  const isLoggedIn = useLogInStatus();

  return useQuery({
    queryKey: authKeys.myProfile(),
    queryFn: fetchMyProfile,
    enabled: isLoggedIn
  });
};

export default useMyProfile;
