import { ComponentProps, ReactNode } from 'react';
import { cn } from '~utils/tailwind';

import Title from './@local-ui/title/component';

type InformationCardProps = Readonly<
  Pick<ComponentProps<'div'>, 'className' | 'children'>
>;

type InformationCardTitleProps = Readonly<{
  children: ReactNode;
}>;

export const InformationCard = ({
  className,
  children
}: InformationCardProps) => (
  <div
    className={cn(
      'self-stretch rounded-2xl border border-solid border-gray-300 bg-white',
      className
    )}
  >
    {children}
  </div>
);

export const InformationCardTitle = ({
  children
}: InformationCardTitleProps) => (
  <Title size='small' className='border-b border-solid border-gray-100 p-6'>
    {children}
  </Title>
);
