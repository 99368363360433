import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import Consultation from '~features/consultation/models';
import { api } from '~utils/api';

import { consultationKeys } from './query-keys';

const fetchMyConsultations = async (page: number) =>
  (
    await api.get<Pagination<Consultation.Level1>>(
      `/vet/consultations/?page=${page}`
    )
  ).data;

const useMyConsultations = (page: number) => {
  return useQuery<Pagination<Consultation.Level1>, AxiosError>({
    queryKey: consultationKeys.myConsultations(page),
    queryFn: async () => await fetchMyConsultations(page),
    staleTime: 2 * 60 * 1000,
    enabled: !!page && page > 0
  });
};

export default useMyConsultations;
