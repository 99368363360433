namespace Vet {
  export class Level1 {
    id: number;
    first_name: string;
    last_name: string;
    title: string;
    photo_url: string | null;
    consultation_status: ConsultationStatus;
    role: number;

    constructor(json: Level1) {
      this.id = json.id;
      this.first_name = json.first_name;
      this.last_name = json.last_name;
      this.title = json.title;
      this.photo_url = json.photo_url;
      this.consultation_status = json.consultation_status;
      this.role = json.role;
    }
  }

  export class Level2 extends Level1 {
    introduction: string;

    constructor(json: Level2) {
      super(json);
      this.introduction = json.introduction;
    }
  }
  export enum ConsultationStatus {
    Available, // 상담 가능
    Consulting, // 상담 중
    Away // 자리비움
  }
}

export default Vet;
