import { produce } from 'immer';
import { useAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';
import Badge from '~components/@ui/badge';
import Icon from '~components/@ui/icon';
import Avatar from '~components/@ui/image/avatar/component';
import { useLayoutContext } from '~components/layout/context';
import {
  __ACCESS_TOKEN__,
  __REFRESH_TOKEN__,
  DEFAULT_THUMBNAIL_VET
} from '~constants';
import { notificationBadgeAtom } from '~features/app/atoms/notification';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';
import { cn } from '~utils/tailwind';

type MenuItemProps = Readonly<{
  name:
    | 'Home'
    | 'Inbox'
    | 'Consultation'
    | 'Live Consultation'
    | 'No shows'
    | 'Practice';
  path:
    | '/'
    | '/inbox'
    | '/consultation'
    | '/live-consultation'
    | '/no-shows'
    | '/practice';
  icon:
    | 'home'
    | 'bell'
    | 'question_circle_outline'
    | 'video_outline'
    | 'close'
    | 'user';
  showNotification?: boolean;
  onClick?: () => void;
  iconNoFill?: boolean;
}>;

const MENU_ICON_CSS = 'fill-gray-700 w-6 h-6 shrink-0';

const Profile = () => {
  const { data: myProfile } = useMyProfile();
  const { isCollapsed, handleCollapse } = useLayoutContext();

  const thumbnail = myProfile?.vet.photo_url || DEFAULT_THUMBNAIL_VET;
  const name = myProfile ? myProfile?.vet.title : 'Unknown';

  return (
    <div className='flex items-center justify-between px-7 py-5'>
      <div
        className={cn(
          'flex min-w-0 items-center',
          'transition-all duration-200 motion-reduce:transition-none',
          isCollapsed ? 'mr-0 w-0 opacity-0' : 'mr-3 w-fit opacity-100'
        )}
      >
        <div
          className={cn(
            'relative w-fit transition-opacity motion-reduce:transition-none',
            isCollapsed ? 'opacity-0' : 'opacity-100'
          )}
        >
          <Avatar uri={thumbnail} alt='vet-avatar' size='40' />
          <div className='absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-solid border-white bg-green-500' />
        </div>
        <div
          className={cn(
            'min-w-0 truncate text-gray-900 text-title-3',
            'transition-all motion-reduce:transition-none',
            isCollapsed ? 'ml-0' : 'ml-3'
          )}
        >
          {name}
        </div>
      </div>
      <button
        type='button'
        className={cn(
          'p-2 duration-200 fill-mode-both motion-reduce:animate-none',
          isCollapsed
            ? 'animate-out slide-out-to-left-0'
            : 'animate-in slide-in-from-left'
        )}
        aria-label='close'
        onClick={handleCollapse}
      >
        <Icon
          name={isCollapsed ? 'open' : 'collapse'}
          className='h-6 w-6 fill-gray-900'
        />
      </button>
    </div>
  );
};

/**
 * @version v1.0.3
 * @author Danny
 * @description onClick prop 추가
 */
const MenuItem = ({
  name,
  path,
  icon,
  showNotification,
  onClick,
  iconNoFill
}: MenuItemProps) => {
  const navigate = useNavigate();
  const { isCollapsed } = useLayoutContext();
  const { pathname } = useLocation();

  const isActive = pathname === path;

  const onNavigate = () => {
    onClick?.();
    navigate(path);
  };

  return (
    <li
      role='menuitem'
      className={cn(
        'relative flex cursor-pointer items-center whitespace-nowrap rounded-lg px-4 py-3 text-gray-700 text-title-3 hover:bg-gray-50',
        isActive && 'bg-gray-50 text-gray-900 [&>svg]:fill-gray-900',
        'transition-all duration-200 motion-reduce:transition-none',
        isCollapsed ? 'w-14' : 'w-[240px]'
      )}
      onClick={onNavigate}
      onKeyDown={onNavigate}
    >
      <div className='relative'>
        {showNotification && (
          <div className='absolute right-0 top-0 rounded-full border border-solid border-white'>
            <Badge />
          </div>
        )}
        <Icon name={icon} className={cn(MENU_ICON_CSS, iconNoFill)} />
      </div>
      <div
        className={cn(
          'transition-all motion-reduce:transition-none',
          isCollapsed ? 'ml-0 w-0 opacity-0' : 'ml-3 w-fit opacity-100'
        )}
      >
        {name}
      </div>
    </li>
  );
};

const onLogOut = () => {
  localStorage.removeItem(__ACCESS_TOKEN__);
  localStorage.removeItem(__REFRESH_TOKEN__);
  document.location.reload();
};

const LogOutButton = () => {
  const { isCollapsed } = useLayoutContext();

  return (
    <button
      className='flex w-full cursor-pointer items-center rounded-lg px-4 py-3 hover:bg-gray-50'
      onClick={onLogOut}
    >
      <Icon name='power' className={MENU_ICON_CSS} />
      <div
        className={cn(
          'whitespace-nowrap text-gray-700 text-title-3',
          'transition-all motion-reduce:transition-none',
          isCollapsed ? 'ml-0 w-0 opacity-0' : 'ml-3 w-fit opacity-100'
        )}
      >
        Log out
      </div>
    </button>
  );
};

const InboxMenuItem = () => {
  const [{ inbox: isInboxIndicatorActive }, setInboxIndicatorActive] = useAtom(
    notificationBadgeAtom
  );

  const offInboxIndicator = () => {
    setInboxIndicatorActive(
      produce((draft) => {
        draft.inbox = false;
      })
    );
  };

  return (
    <MenuItem
      name='Inbox'
      path='/inbox'
      icon='bell'
      showNotification={isInboxIndicatorActive}
      onClick={offInboxIndicator}
    />
  );
};

const LCMenuItem = () => {
  const [{ live_consultation_request: isLCIndicatorActive }] = useAtom(
    notificationBadgeAtom
  );

  return (
    <MenuItem
      name='Live Consultation'
      path='/live-consultation'
      icon='video_outline'
      showNotification={isLCIndicatorActive}
    />
  );
};

const NoShowMenuItem = () => {
  return <MenuItem name='No shows' path='/no-shows' icon='close' iconNoFill />;
};

const PracticeMenuItem = () => {
  return <MenuItem name='Practice' path='/practice' icon='user' iconNoFill />;
};

const Sidebar = () => {
  const { data: profile } = useMyProfile();

  return (
    <div className='relative z-sidebar h-full w-full'>
      <div
        className={cn(
          'fixed flex h-screen flex-col justify-between border-r border-solid border-gray-300 bg-white'
        )}
      >
        <div>
          <Profile />
          <ul className='flex flex-col gap-1 p-5'>
            <MenuItem name='Home' path='/' icon='home' />
            <InboxMenuItem />
            <MenuItem
              name='Consultation'
              path='/consultation'
              icon='question_circle_outline'
            />
            <LCMenuItem />
            {profile?.vet.role === 12 && (
              <>
                <NoShowMenuItem />
                <PracticeMenuItem />
              </>
            )}
          </ul>
        </div>
        <div className='p-5'>
          <LogOutButton />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
