import { produce } from 'immer';
import { useSetAtom } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from '~components/@ui/toast/controller';
import { scheduledLiveConsultationOngoingAtom } from '~features/live-consultation/atoms/scheduled-live-consultation-ongoing';
import { liveConsultationKeys } from '~features/live-consultation/hooks/queries/query-keys';
import { LiveConsultationTicket } from '~features/live-consultation/model/live-consultation-ticket';
import { TicketType } from '~features/live-consultation/types';
import { vetKeys } from '~features/vet/hooks/queries/query-keys';

import { MessageType } from './types';

export const useAblyMessageHandler = () => {
  const queryClient = useQueryClient();
  const setScheduleDialog = useSetAtom(scheduledLiveConsultationOngoingAtom);
  const handleJsonMessage = (jsonMessage: MessageType) => {
    if (!jsonMessage) {
      return;
    }

    if (jsonMessage.name === 'VET_LC_STARTED') {
      if (jsonMessage.data?.ticket_type === TicketType.Scheduled) {
        // dialog open
        setScheduleDialog({
          isOpen: true
        });
      }
      void queryClient.setQueryData(
        liveConsultationKeys.ongoingSession(),
        jsonMessage.data
      );
    }

    if (
      jsonMessage.name === 'VET_LCQ_PUSH' ||
      jsonMessage.name === 'VET_BOOKING_LCQ_PUSH'
    ) {
      void queryClient.setQueryData<LiveConsultationTicket[]>(
        liveConsultationKeys.tickets(),
        produce((draft) => {
          draft?.unshift(jsonMessage.data);
        })
      );
    }

    if (jsonMessage.name === 'VET_LCQ_OWNER_CANCELED') {
      void queryClient.setQueryData<LiveConsultationTicket[]>(
        liveConsultationKeys.tickets(),
        produce(
          (draft) =>
            draft?.filter((item) => item.id !== jsonMessage.data.lct_id)
        )
      );
    }

    if (jsonMessage.name === 'VET_SCHEDULE_LCQ_OWNER_CANCELED') {
      toast.open({
        message: 'canceled schedule by owner',
        type: 'default'
      });
      void queryClient.setQueryData<LiveConsultationTicket[]>(
        liveConsultationKeys.tickets(),
        produce(
          (draft) =>
            draft?.filter((item) => item.id !== jsonMessage.data.lct_id)
        )
      );
      void queryClient.invalidateQueries({
        queryKey: liveConsultationKeys.scheduledTickets(),
        exact: false
      });
      void queryClient.invalidateQueries({
        queryKey: vetKeys.dashboard()
      });
    }

    if (jsonMessage.name === 'VET_LC_TIME_OVER') {
      queryClient.removeQueries({
        queryKey: liveConsultationKeys.ongoingSession()
      });
    }

    if (jsonMessage.name === 'VET_QUICK_LCQ_PUSH') {
      void queryClient.setQueryData<LiveConsultationTicket[]>(
        liveConsultationKeys.tickets(),
        produce((draft) => {
          draft?.unshift(jsonMessage.data);
        })
      );
    }

    if (jsonMessage.name === 'VET_QUICK_LCQ_REMOVED') {
      void queryClient.setQueryData<LiveConsultationTicket[]>(
        liveConsultationKeys.tickets(),
        produce(
          (draft) =>
            draft?.filter((item) => item.id !== jsonMessage.data.lct_id)
        )
      );
    }
  };

  return {
    handleJsonMessage
  };
};
