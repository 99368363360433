export type LiveConsultationParams = {
  page: number;
};

export const liveConsultationKeys = {
  all: ['live-consultation'],
  availableTimes: () => [...liveConsultationKeys.all, 'available-times'],
  ongoingSession: () => [...liveConsultationKeys.all, 'ongoing-session'],
  tickets: () => [...liveConsultationKeys.all, 'tickets'],
  scheduledTickets: () => [...liveConsultationKeys.all, 'scheduled-tickets'],
  scheduledTicketsWithParams: (params?: LiveConsultationParams) => [
    ...liveConsultationKeys.scheduledTickets(),
    params
  ],
  histories: () => [...liveConsultationKeys.all, 'histories'],
  historiesWithParams: (params: LiveConsultationParams) => [
    ...liveConsultationKeys.histories(),
    params
  ],
  historyDetail: (id: number) => [
    ...liveConsultationKeys.histories(),
    'detail',
    id
  ],
  timeboxes: () => [...liveConsultationKeys.all, 'timeboxes'],
  price: () => [...liveConsultationKeys.all, 'price']
} as const;
