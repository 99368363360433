import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Participant, Room } from 'twilio-video';
import { useTwilioRoomState } from '~twilio/state';

type selectedParticipantContextType = [
  Participant | null,
  (participant: Participant) => void
];

export const selectedParticipantContext =
  createContext<selectedParticipantContextType>(null!);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(
    selectedParticipantContext
  );
  return [selectedParticipant, setSelectedParticipant] as const;
}

type SelectedParticipantProviderProps = {
  room: Room | null;
  children: React.ReactNode;
};

export const SelectedParticipantProvider = ({
  room,
  children
}: SelectedParticipantProviderProps) => {
  const { isGalleryViewActive } = useTwilioRoomState();
  const [selectedParticipant, _setSelectedParticipant] =
    useState<Participant | null>(null);
  const setSelectedParticipant = (participant: Participant) => {
    _setSelectedParticipant((prevParticipant) =>
      prevParticipant === participant ? null : participant
    );
  };

  useEffect(() => {
    if (isGalleryViewActive) {
      _setSelectedParticipant(null);
    }
  }, [isGalleryViewActive]);

  useEffect(() => {
    if (room) {
      const onDisconnect = () => {
        _setSelectedParticipant(null);
      };
      const handleParticipantDisconnected = (participant: Participant) => {
        _setSelectedParticipant((prevParticipant) =>
          prevParticipant === participant ? null : prevParticipant
        );
      };

      room.on('disconnected', onDisconnect);
      room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('disconnected', onDisconnect);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room]);

  const providerValue = useMemo(
    () =>
      [
        selectedParticipant,
        setSelectedParticipant
      ] as selectedParticipantContextType,
    [selectedParticipant]
  );

  return (
    <selectedParticipantContext.Provider value={providerValue}>
      {children}
    </selectedParticipantContext.Provider>
  );
};
