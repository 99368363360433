import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '~utils/api';

import { consultationKeys } from './query-keys';

type Response = Readonly<{
  content: string;
}>;

const fetchAIReference = async (id: number) =>
  (await api.get<Response>(`/consultations/${id}/ref/`)).data;

const useAIReference = (id: number) => {
  return useQuery<Response, AxiosError>({
    queryKey: consultationKeys.aiReference(id),
    queryFn: async () => await fetchAIReference(id),
    staleTime: 2 * 60 * 1000,
    enabled: !!id && id > 0
  });
};

export default useAIReference;
