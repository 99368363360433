import { useCallback, useMemo, useState } from 'react';
import Calendar, { TileDisabledFunc } from 'react-calendar';
import type { LooseValue } from 'node_modules/react-calendar/dist/esm/shared/types';
import { Header, HeaderTitle } from '~components/@local-ui/header';
import PeriodSelector, {
  PeriodState
} from '~features/live-consultation/components/period-selector';

import './calendar.css';
import Button from '~components/@ui/button/button/component';
import useUpdateNoShowTimes from '~features/no-shows/hooks/mutations/use-update-no-show-times';
import { toast } from '~components/@ui/toast/controller';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';

const NoShows = () => {
  const { data: myProfile } = useMyProfile();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [draft, setDraft] = useState<
    Record<string, { state: PeriodState; to?: PeriodState }>
  >({});

  const { mutate: updateNoShowTimes } = useUpdateNoShowTimes({
    onSuccess: () => {
      toast.open({
        message: 'No show times updated',
        type: 'success'
      });
    },
    onError: () => {
      toast.open({
        message: 'Failed to update no show times',
        type: 'error'
      });
    }
  });

  const canGoSave = useMemo(
    () =>
      Object.values(draft).some(
        (value) => value.to !== undefined && value.to !== value.state
      ),
    [draft]
  );

  const tileDisabled: TileDisabledFunc = ({ date }) => {
    const today = new Date();
    date.setHours(23, 59, 59, 999);

    if (date < today) {
      return true;
    }
    return false;
  };

  const onCalendarChange = (date: LooseValue) => {
    if (date === null) {
      setSelectedDate(null);
      setDraft({});
      return;
    }

    if (!Array.isArray(date)) {
      const newDate = new Date(date);
      setSelectedDate(newDate);
      setDraft({});
    }
  };

  const onSave = useCallback(() => {
    if (!myProfile) {
      return;
    }

    const updatedTimes = Object.entries(draft)
      .filter(([_, { state, to }]) => to !== undefined && state !== to)
      .map(([time, { to }]) => ({
        time,
        hospital_name: myProfile.vet.title,
        is_on: to === PeriodState.AVAILABLE
      }));

    updateNoShowTimes({ updatedTimes });
  }, [draft, myProfile, updateNoShowTimes]);

  return (
    <main className='w-full'>
      <Header className='border-b border-solid border-gray-200'>
        <HeaderTitle>No shows</HeaderTitle>
      </Header>
      <section className='mx-auto w-full max-w-screen-md p-5'>
        <div className='flex w-full flex-col gap-4'>
          <Calendar
            locale='en-US'
            tileDisabled={tileDisabled}
            onChange={onCalendarChange}
          />
          {selectedDate && (
            <PeriodSelector
              value={draft}
              onValueChanged={(newValue) => {
                setDraft(newValue);
              }}
              selectedDate={selectedDate}
              hideDisabled
              fulfill
            />
          )}
          {canGoSave && (
            <div className='flex w-full gap-4 p-3'>
              <Button priority='tertiary' className='flex-1'>
                Reset All
              </Button>
              <Button
                priority='primary'
                className='flex-1'
                onClick={() => {
                  onSave();
                }}
              >
                Save All
              </Button>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default NoShows;
