import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { cn } from '~utils/tailwind';

import Portal from '../../portal/component';
import Icon from '../icon';
import { toastAtom } from './atom';
import { toast } from './controller';
import { TOAST_ICON_COLOR_TYPE_CSS } from './styles';
import { ToastProps } from './types';

const ToastIcon = ({
  type = 'default',
  iconColor = ''
}: Pick<ToastProps, 'type' | 'iconColor'>) => {
  const _iconColor = iconColor || TOAST_ICON_COLOR_TYPE_CSS[type];
  const iconStyle = cn(_iconColor, 'h-5 w-5');

  switch (type) {
    case 'success':
      return <Icon name='check_circle_solid' className={iconStyle} />;
    case 'caution':
      return <Icon name='circle_exclamation' className={iconStyle} />;
    case 'error':
      return <Icon name='remove_circle_solid' className={iconStyle} />;
    default:
      return null;
  }
};

/**
 * @version v1.0.1
 * @author Danny
 */
const ToastComponent = () => {
  const props = useAtomValue(toastAtom);

  useEffect(() => {
    if (!props) {
      return;
    }

    const { isOpen, autoClosingTime } = props;

    if (isOpen && autoClosingTime) {
      const close = setTimeout(toast.close.bind(toast), autoClosingTime);
      const destroy = setTimeout(
        toast.destroy.bind(toast),
        autoClosingTime + 1000
      );

      return () => {
        clearTimeout(close);
        clearTimeout(destroy);
      };
    }
  }, [props]);

  return (
    <Portal id='toast'>
      <div className='relative flex w-full justify-center'>
        <div
          className={cn(
            'fixed top-10 z-toast w-fit px-6',
            props?.isOpen ? 'visible' : 'invisible'
          )}
        >
          <div
            role='none'
            className={cn(
              'h-fit w-fit cursor-pointer items-center justify-between rounded-2xl bg-gray-900/80 px-4 py-3 transition-all duration-500',
              props?.isOpen
                ? 'translate-y-0 ease-out'
                : '-translate-y-[300%] opacity-0 ease-in'
            )}
            onClick={toast.close.bind(toast)}
            onKeyDown={toast.close.bind(toast)}
            onTouchStart={toast.close.bind(toast)}
          >
            <div className='flex w-fit items-start'>
              {props?.type !== 'default' && (
                <div className='mr-2 [&>svg]:h-5 [&>svg]:w-5'>
                  <ToastIcon type={props?.type} iconColor={props?.iconColor} />
                </div>
              )}
              <div className='flex w-fit flex-col'>
                <span className='w-fit whitespace-pre-line text-white text-title-3'>
                  {props?.message}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default ToastComponent;
