import { ReactNode } from 'react';

interface TableHeaderProps {
  titleList: string[];
}

const TableHeader = ({ titleList }: TableHeaderProps) => {
  return (
    <thead>
      <tr className='border-b border-gray-900'>
        {titleList.map((title) => (
          <th scope='col' className='p-4 text-left text-label-2' key={title}>
            {title}
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface TableProps {
  titleList: string[];
  children: ReactNode;
}

const Table = ({ titleList, children }: TableProps) => {
  return (
    <table className='w-full'>
      <TableHeader titleList={titleList} />
      <tbody className='hover:[&>tr]:bg-gray-50 active:[&>tr]:bg-primary-50'>
        {children}
      </tbody>
    </table>
  );
};

export default Table;

/**
 * @usage
 * @description titleList의 string length와 children에서 <tr />태그 내부의 <th />태그의 수가 동일해야 한다.
  <Table titleList={Question_Header_List}>
    {Question_Mock_Data.map((data) => (
      <tr
        onClick={onDataClick}
        key={key}
        className='cursor-pointer border-b border-gray-100'
      >
        <th className='p-4'>
          <p className='line-clamp-2  whitespace-pre-wrap text-left text-body-1'>
            {data.details}
          </p>
        </th>
        <th className='p-4 text-body-1'>{data.concern}</th>
        <th className='p-4 text-body-1'>
          <Label>{data.status}</Label>
        </th>
        <th className='whitespace-nowrap p-4 text-body-1'>
          {data.species_breed}
        </th>
        <th className='whitespace-nowrap p-4 text-body-1'>
          {data.age}
        </th>
        <th className='p-4 text-body-1'>{getGender(data.gender)}</th>
        <th className='whitespace-nowrap p-4 text-body-1'>
          {data.date}
        </th>
      </tr>
    ))}
  </Table>
 */
