import { ReactNode, useMemo } from 'react';
import { Label } from '~components/@ui/label/component';
import { LabelColor } from '~components/@ui/label/types';
import Switch from '~components/@ui/switch';
import { TabItem } from '~components/@ui/tab/tab-bar/types';
import useMyProfile from '~features/auth/hooks/queries/use-my-profile';
import useUpdateStatus from '~features/vet/hooks/mutations/use-update-status';
import Vet from '~features/vet/models';
import { cn } from '~utils/tailwind';

import TabBar from '../@ui/tab/tab-bar/component';
import Title from './title/component';
import { TitleProps } from './title/types';

type HeaderProps = Readonly<{
  children: ReactNode;
  tabs?: TabItem[];
  className?: string;
}>;

type LabelColorCss = {
  [key in Vet.ConsultationStatus]: {
    label: LabelColor;
    dot: string;
  };
};

const LABEL_COLOR_CSS: LabelColorCss = {
  [Vet.ConsultationStatus.Away]: {
    label: 'red',
    dot: 'bg-red-500'
  },
  [Vet.ConsultationStatus.Available]: {
    label: 'green',
    dot: 'bg-green-500'
  },
  [Vet.ConsultationStatus.Consulting]: {
    label: 'orange',
    dot: 'bg-orange-500'
  }
};

const LABEL_STATUS = {
  [Vet.ConsultationStatus.Away]: 'AWAY',
  [Vet.ConsultationStatus.Available]: 'ONLINE',
  [Vet.ConsultationStatus.Consulting]: 'BUSY'
};

export const Header = ({ children, tabs, className }: HeaderProps) => {
  const showTab = tabs && tabs.length > 0;

  return (
    <header>
      <div className={cn('flex justify-between px-9', className)}>
        {children}
      </div>
      {showTab && <TabBar items={tabs} />}
    </header>
  );
};

export const HeaderTitle = ({ ...props }: TitleProps) => <Title {...props} />;

export const HeaderStatusSwitch = () => {
  const { mutate: patchStatus } = useUpdateStatus();

  const { data: myProfile } = useMyProfile();

  const consultationStatus =
    myProfile?.vet.consultation_status || Vet.ConsultationStatus.Available;

  const isActive = useMemo(
    () => consultationStatus === Vet.ConsultationStatus.Away,
    [consultationStatus]
  );

  return (
    <div className='flex shrink-0 items-center'>
      <div className='mr-3 flex flex-row items-center gap-4 p-1'>
        <p className='w-[195px] text-right text-gray-900 text-label-1'>
          Set status as away
        </p>
        <Switch
          className='pt-2'
          checked={isActive}
          onChange={() => {
            if (!myProfile?.vet.id) {
              return;
            }
            patchStatus({
              consultation_status: isActive
                ? Vet.ConsultationStatus.Available
                : Vet.ConsultationStatus.Away
            });
          }}
        />
      </div>
      <div className='w-24'>
        <Label color={LABEL_COLOR_CSS[consultationStatus].label}>
          <div
            className={cn(
              'h-3 w-3 rounded-full',
              LABEL_COLOR_CSS[consultationStatus].dot
            )}
          />
          {LABEL_STATUS[consultationStatus]}
        </Label>
      </div>
    </div>
  );
};

/**
 * @usage
 * <Header tabs={TABS}>
 *  <HeaderTitle>Live Consultation</HeaderTitle>
 *  <HeaderStatusSwitch />
 * </Header>
 */
