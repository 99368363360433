import { produce } from 'immer';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { PetCard } from '~components/@local-ui/live-consultation/pet-card';
import Sidebar from '~components/@local-ui/side-bar';
import Dialog from '~components/@ui/dialog';
import Portal from '~components/portal/component';
import { notificationBadgeAtom } from '~features/app/atoms/notification';
import { useLiveConsultation } from '~features/live-consultation/hooks/queries/use-live-consultation';
import { useLiveConsultationTickets } from '~features/live-consultation/hooks/queries/use-live-consultation-tickets';
import { useScheduledLiveConsultationOngoingDialog } from '~features/live-consultation/hooks/use-scheduled-live-consultation-ongoing-dialog';
import { TicketStatus } from '~features/live-consultation/types';
import { cn } from '~utils/tailwind';

import { LayoutContext } from './context';
import ForegroundMessage from './foreground-message';
import OngoingSession from './ongoing-session';

const NotificationProvider = () => {
  return (
    <Portal id='notification'>
      <div className='fixed right-6 top-24 z-notification flex flex-col gap-2'>
        <OngoingSession />
        <ForegroundMessage />
      </div>
    </Portal>
  );
};

type ConsultationRequestTabBadgeProviderProps = Readonly<{
  children: React.ReactNode;
}>;
const NotificationBadgeAtomProvider = ({
  children
}: ConsultationRequestTabBadgeProviderProps) => {
  const { data: lcts } = useLiveConsultationTickets();
  const setNotificationBadge = useSetAtom(notificationBadgeAtom);
  useEffect(() => {
    const consultation_requests = lcts?.filter(
      (item) => item.ticket_status !== TicketStatus.Booked
    );
    setNotificationBadge(
      produce((draft) => {
        draft.live_consultation_request = !!consultation_requests?.length;
      })
    );
  }, [lcts, setNotificationBadge]);
  return <>{children}</>;
};

interface ScheduledLiveConsultationOngoingDialogProps {
  isOpen: boolean;
  close: () => void;
}
const ScheduledLiveConsultationOngoingDialog = ({
  isOpen,
  close
}: ScheduledLiveConsultationOngoingDialogProps) => {
  const navigate = useNavigate();
  const { data: lc } = useLiveConsultation();

  return (
    <Dialog
      className='w-[580px] lg:p-0'
      open={isOpen}
      type='prompt'
      onClose={close}
    >
      <Dialog.Title>Scheduled Consultation</Dialog.Title>
      <div className='px-6'>
        {lc?.pet && (
          <PetCard
            pet={lc.pet}
            category_name={lc?.category_name}
            scheduled_at={lc?.created_at}
          />
        )}
      </div>
      <Dialog.Action className='flex gap-4'>
        <Dialog.TextButton priority='tertiary' onClick={close}>
          Join later
        </Dialog.TextButton>
        <Dialog.Button
          type='submit'
          priority='primary'
          onClick={() => {
            close();
            navigate('/live-consultation/video-chat');
          }}
        >
          Enter chat now
        </Dialog.Button>
      </Dialog.Action>
    </Dialog>
  );
};

const Layout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const contexts = useMemo(
    () => ({
      isCollapsed,
      handleCollapse
    }),
    [isCollapsed, handleCollapse]
  );

  const { isOpen, close } = useScheduledLiveConsultationOngoingDialog();

  return (
    <LayoutContext.Provider value={contexts}>
      <NotificationBadgeAtomProvider>
        <NotificationProvider />
        <ScheduledLiveConsultationOngoingDialog isOpen={isOpen} close={close} />
        <div
          className={cn(
            'grid min-h-screen bg-white',
            'transition-[grid-template-columns] duration-300 motion-reduce:transition-none',
            isCollapsed ? 'grid-cols-sidebar-collapsed' : 'grid-cols-sidebar'
          )}
        >
          <Sidebar />
          <div className='w-full overflow-hidden'>
            <Outlet />
          </div>
        </div>
      </NotificationBadgeAtomProvider>
    </LayoutContext.Provider>
  );
};

export default Layout;
