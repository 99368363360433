import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Pagination from '~components/@local-ui/pagination';
import SideSheet from '~components/@local-ui/side-sheet/component';
import { SIDE_SHEET_PAGE_ID } from '~components/@local-ui/side-sheet/constant';
import { useSideSheet } from '~components/@local-ui/side-sheet/hook';
import Button from '~components/@ui/button/button/component';
import Dialog from '~components/@ui/dialog';
import TextButton from '~components/@ui/dialog/text-button';
import TextArea from '~components/@ui/text-input/text-area';
import Table from '~components/table';
import usePatchLiveConsultation, {
  PatchLiveConsultationParams
} from '~features/live-consultation/hooks/mutations/use-patch-live-consultation';
import useLiveConsultationHistories from '~features/live-consultation/hooks/queries/use-live-consultation-histories';
import useLiveConsultationDetail from '~features/live-consultation/hooks/queries/use-live-consultation-history-detail';
import { LiveConsultation } from '~features/live-consultation/model/live-consultation';
import { getGender } from '~features/pet/utils';
import usePagination from '~hooks/use-pagination';
import { getDayjsFormat } from '~utils/format/dayjs';
import { formatLiveConsultationDuration } from '~utils/format/live-consultation-duration';
import { cn } from '~utils/tailwind';

const HISTORY_TABLE_HEADER = ['PET NAME', 'CONCERN', 'DATE', 'NOTE'];

interface SideSheetContentsProps {
  open: () => void;
  history?: LiveConsultation.Level2;
}
const SideSheetContents = ({ history, open }: SideSheetContentsProps) => {
  if (!history) {
    return null;
  }
  return (
    <div className='flex h-full flex-col overflow-y-scroll pb-5'>
      <div className='p w-full px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Session Information</p>
      </div>
      <div className='p w-full px-5 pb-5 pt-2'>
        <div className='mb-5 rounded-xl bg-gray-50 p-3'>
          <div className='flex flex-col gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Status</p>
            <p className='text-gray-900 text-label-2'>Done</p>
          </div>
          <div className='flex flex-col gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Duration</p>
            <p className='text-gray-900 text-label-2'>
              {formatLiveConsultationDuration(
                history?.created_at,
                history?.closed_at
              )}
            </p>
          </div>
          <div className='flex flex-col gap-1 p-1'>
            <p className='text-gray-700 text-body-3'>Issue</p>
            <p className='text-gray-900 text-label-2'>
              {history?.category_name || '-'}
            </p>
          </div>
        </div>
      </div>
      <div className='p w-full px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Pet Information</p>
      </div>
      <div className='p w-full px-5 pb-5 pt-2'>
        <div className='mb-5 rounded-xl bg-gray-50 p-3'>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Name</p>
            <p className='text-gray-900 text-label-2'>{history?.pet.name}</p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Species</p>
            <p className='text-gray-900 text-label-2'>
              {history?.pet.kind.breed}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Breed</p>
            <p className='text-gray-900 text-label-2'>
              {history?.pet.kind.species}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Age</p>
            <p className='text-gray-900 text-label-2'>{history?.pet.age}</p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Gender</p>
            <p className='text-gray-900 text-label-2'>
              {getGender(history.pet.gender)}
            </p>
          </div>
          <div className='flex flex-row justify-between gap-1 border-b border-gray-200 p-1'>
            <p className='text-gray-700 text-body-3'>Status</p>
            <p className='text-gray-900 text-label-2'>
              {history?.pet.is_neutered ? 'Neutered or spayed' : '-'}
            </p>
          </div>
        </div>
      </div>
      <div className='p w-full px-7 py-5'>
        <p className='text-gray-900 text-title-3'>Note</p>
      </div>
      {history?.has_note ? (
        <>
          <div className='flex flex-col gap-5 px-7 pb-5 pt-2'>
            <p className='max-h-[200px] overflow-y-scroll whitespace-pre-wrap break-words text-gray-900 text-body-large-1'>
              {history?.note}
            </p>
            <p className='text-gray-500 text-body-2'>
              Last Updated : {getDayjsFormat(history.updated_at || null)}
            </p>
          </div>

          <div className='p-7'>
            <TextButton priority='primary' size='small' onClick={open}>
              Edit note
            </TextButton>
          </div>
        </>
      ) : (
        <>
          <div className='flex flex-col gap-3 px-7 pb-5 pt-2'>
            <p className='text-gray-700 text-title-2'>
              You haven&apos;t written any notes yet.
            </p>
            <p className='text-gray-500 text-body-1'>
              Leave notes to help the owner understand what is going on.
            </p>
          </div>
          <div className='px-7'>
            <Button priority='primary' size='medium' onClick={open}>
              Write a note
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const resolver: Resolver<PatchLiveConsultationParams> = async (values) => ({
  values,
  errors: {}
});

export const ConsultationHistoryTab = () => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const { data: listData, refetch } = useLiveConsultationHistories({ page });

  useEffect(() => {
    void refetch();
  }, [refetch]);

  const { pages, onPrevClick, onNextClick, onPageNumClick } = usePagination({
    currentPage: listData?.current_page,
    totalPageNum: listData?.total_page_num
  });

  const { openSideSheet, sideSheetProps } = useSideSheet();

  const selectedHistoryId = searchParams.get(SIDE_SHEET_PAGE_ID);
  const { data: selectedHistory } = useLiveConsultationDetail(
    Number(selectedHistoryId)
  );
  const onItemClick = (id: number) => {
    openSideSheet(id);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState(!!selectedHistory?.has_note);

  const open = () => {
    setDialogOpen(true);
  };

  const close = () => {
    setDialogOpen(false);
  };

  const { register, handleSubmit, setValue } =
    useForm<PatchLiveConsultationParams>({
      resolver
    });

  // const note = useWatch({
  //   name: 'note',
  //   control
  // });

  const { mutate: patchLiveConsultation } = usePatchLiveConsultation({
    onSuccess: () => {
      setValue('note', '');
    }
  });

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      void handleSubmit((values) => {
        if (!selectedHistoryId) {
          return;
        }
        patchLiveConsultation({
          id: Number(selectedHistoryId),
          note: values.note
        });
        close();
      })(e);
    },
    [handleSubmit, patchLiveConsultation, selectedHistoryId]
  );

  return (
    <>
      <div className='flex h-full w-full flex-col gap-5'>
        <div className='mb-5 text-gray-900 text-headline-2'>
          Consultation History
        </div>
        <div
          className={cn(
            'min-w-[1000px] rounded-2xl border border-gray-300 p-6',
            listData?.results?.length && 'min-h-[670px]'
          )}
        >
          <Table titleList={HISTORY_TABLE_HEADER}>
            {listData?.results?.length ? (
              listData.results.map(
                ({ id, pet_name, category_name, created_at, has_note }) => (
                  <tr
                    key={id}
                    className='cursor-pointer border-b border-gray-100'
                    onClick={() => {
                      onItemClick(id);
                    }}
                  >
                    <td className='p-4'>
                      <p className='line-clamp-2 w-[450px] whitespace-pre-wrap text-left text-body-1'>
                        {pet_name}
                      </p>
                    </td>
                    <td className='whitespace-nowrap p-4 text-body-1'>
                      {category_name || '-'}
                    </td>
                    <td className='whitespace-nowrap p-4 text-body-1'>
                      {getDayjsFormat(created_at, 'longWithTime')}
                    </td>
                    <td
                      className={cn(
                        'whitespace-nowrap p-4 text-title-3',
                        has_note ? 'text-gray-900' : 'text-red-500'
                      )}
                    >
                      {has_note ? 'Y' : 'N'}
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className='px-5 py-8 text-center text-gray-700 text-headline-3'
                >
                  No history
                </td>
              </tr>
            )}
          </Table>
          <Pagination
            currentPageNum={listData?.current_page || 0}
            totalPageNum={listData?.total_page_num || 0}
            pageNums={pages}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageNumClick={onPageNumClick}
          />
        </div>
      </div>
      <SideSheet
        {...sideSheetProps}
        isExpandable={false}
        className='max-w-[480px]'
      >
        <SideSheetContents open={open} history={selectedHistory} />
      </SideSheet>
      <Dialog
        className='w-[580px] lg:p-0'
        open={dialogOpen}
        onClose={close}
        type='prompt'
      >
        <Dialog.Title>
          {selectedHistory?.has_note ? 'Edit note' : 'Write note'}
        </Dialog.Title>
        <form onSubmit={onSubmit}>
          <div className='px-6'>
            <TextArea
              className='min-h-[200px]'
              {...register('note', {
                onChange: (e) => {
                  setDisabled(!e.target.value.length);
                }
              })}
            />
          </div>
          <Dialog.Action className='flex gap-4'>
            <Dialog.TextButton onClick={close} priority='tertiary'>
              {selectedHistory?.has_note ? 'Cancel' : 'Close'}
            </Dialog.TextButton>
            <Dialog.Button type='submit' priority='primary' disabled={disabled}>
              {selectedHistory?.has_note ? 'Save changes' : 'Save'}
            </Dialog.Button>
          </Dialog.Action>
        </form>
      </Dialog>
    </>
  );
};
