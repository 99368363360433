import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import Icon from '~components/@ui/icon';
import * as icons from '~icons';
import { cn } from '~utils/tailwind';

import { foregroundMessageAtom } from '../../features/app/atoms/notification';
import App from '../../features/app/models';

const DEFAULT_CLOSING_TIME = 5000;

const useForegroundMessage = () => {
  const [foregroundMessage, setForegroundMessage] = useAtom(
    foregroundMessageAtom
  );
  const [open, setOpen] = useState(!!foregroundMessage);

  const closeMessage = () => {
    setOpen(false);
  };

  const onAnimationEnd = () => {
    if (!open) {
      setForegroundMessage(null);
    }
  };

  useEffect(() => {
    if (foregroundMessage) {
      setOpen(true);
    }

    const timer = setTimeout(() => {
      closeMessage();
    }, DEFAULT_CLOSING_TIME);

    return () => {
      clearTimeout(timer);
    };
  }, [foregroundMessage, setForegroundMessage]);

  return {
    foregroundMessage,
    open,
    closeMessage,
    onAnimationEnd
  };
};

const getMessageType = (type: App.Message.Type) =>
  ({
    [App.Message.Type.Consultation]: 'CONSULTATION',
    [App.Message.Type.LiveConsultation]: 'LIVE_CONSULTATION'
  })[type];

const ForegroundMessage = () => {
  const { foregroundMessage, open, closeMessage, onAnimationEnd } =
    useForegroundMessage();

  if (!foregroundMessage) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex w-fit max-w-2xl items-center justify-between gap-10 rounded-2xl border border-solid border-gray-300 bg-white p-5 shadow-elevation-3',
        open ? 'animate-right-slide-in' : 'animate-right-slide-out'
      )}
      onAnimationEnd={onAnimationEnd}
    >
      <div className='flex gap-5'>
        <div className='h-fit w-fit rounded-xl bg-gray-100 p-2'>
          <Icon
            name={foregroundMessage.icon as keyof typeof icons}
            className='h-6 w-6 fill-gray-900'
          />
        </div>
        <div className='flex flex-col flex-wrap'>
          <h3 className='text-gray-500 text-label-1'>
            {getMessageType(foregroundMessage.message_type_id)}
          </h3>
          <p className='break-all text-gray-900 text-title-2'>
            {foregroundMessage.title}
          </p>
        </div>
      </div>
      <button
        type='button'
        aria-label='close'
        className='p-2'
        onClick={closeMessage}
      >
        <Icon name='close' className='h-6 w-6 fill-gray-900' />
      </button>
    </div>
  );
};

export default ForegroundMessage;
