import { useSetAtom } from 'jotai';
import { useSearchParams } from 'react-router-dom';
import {
  MutationObserverOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { loadingAtom } from '~features/app/atoms/loading';
import Consultation from '~features/consultation/models';
import { api } from '~utils/api';

import { consultationKeys } from '../queries/query-keys';

export type MessageParams = Readonly<{
  id: number;
  text: string;
}>;

const sendMessage = async (params: MessageParams) =>
  (
    await api.post(`/consultations/${params.id}/message/`, {
      text: params.text
    })
  ).data;

const useConsultationMessage = (
  options?: MutationObserverOptions<Response, Error, MessageParams>
) => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const setLoading = useSetAtom(loadingAtom);
  const queryClient = useQueryClient();

  return useMutation<Response, Error, MessageParams>({
    mutationFn: sendMessage,
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (res, req, ctx) => {
      options?.onSuccess?.(res, req, ctx);

      void queryClient.invalidateQueries({
        queryKey: consultationKeys.consultationDetail(req.id)
      });

      queryClient.setQueryData(
        consultationKeys.consultations({ page }),
        (previous: Pagination<Consultation.Level1>) => ({
          ...previous,
          results: previous?.results?.map((consultation) => {
            if (consultation.id === req.id) {
              return { ...consultation, status: Consultation.State.Answered };
            }
            return consultation;
          })
        })
      );
    },
    onSettled: () => {
      setLoading(false);
    }
  });
};

export default useConsultationMessage;
