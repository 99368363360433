import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY
} from '~constants';
import { noiseCancellationOptions } from '~twilio/components/video-provider/use-local-tracks';

import useDevices from './use-devices';
import useMediaStreamTrack from './use-media-stream-track';
import useVideoContext from './use-video-context';

const disPlayValueAtom = atom<{
  audio?: string;
  video?: string;
}>({});

const useMediaDevices = (mediaType: 'audio' | 'video') => {
  const SELECTED_MEDIA_INPUT_KEY =
    mediaType === 'audio' ? SELECTED_AUDIO_INPUT_KEY : SELECTED_VIDEO_INPUT_KEY;
  const { audioInputDevices, videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const [storedLocalMediaDeviceId, setStoredLocalMediaDeviceId] = useState(
    window.localStorage.getItem(SELECTED_MEDIA_INPUT_KEY)
  );

  useEffect(() => {
    setStoredLocalMediaDeviceId(
      window.localStorage.getItem(SELECTED_MEDIA_INPUT_KEY)
    );
  }, [SELECTED_MEDIA_INPUT_KEY, setStoredLocalMediaDeviceId]);

  const mediaTrackSelectOptions = useMemo(() => {
    if (mediaType === 'audio') {
      return audioInputDevices.map((device) => ({
        value: device.deviceId,
        label: device.label
      }));
    }
    return videoInputDevices.map((device) => ({
      value: device.deviceId,
      label: device.label
    }));
  }, [audioInputDevices, mediaType, videoInputDevices]);

  const localMediaTrack = localTracks.find((track) => track.kind === mediaType);
  const localMediaStreamTrack = useMediaStreamTrack(localMediaTrack);

  // 🚨 storedLocalMediaDeviceId와 localMediaStreamTrack?.getSettings().deviceId의 서순이 바뀌었습니다. 캐리 체크 필요
  const localMediaInputDeviceId =
    (storedLocalMediaDeviceId ||
      localMediaStreamTrack?.getSettings().deviceId) ??
    undefined;

  const currentMediaTrackValue = useMemo(
    () => localMediaInputDeviceId || mediaTrackSelectOptions[0]?.value || null,
    [localMediaInputDeviceId, mediaTrackSelectOptions]
  );

  const currentMediaItem = useMemo(
    () =>
      mediaTrackSelectOptions.find(
        (item) => item.value === currentMediaTrackValue
      ),
    [currentMediaTrackValue, mediaTrackSelectOptions]
  );

  const [displayValue, setDisplayValue] = useAtom(disPlayValueAtom);

  useEffect(() => {
    if (currentMediaItem) {
      setDisplayValue(
        produce((draft) => {
          if (mediaType === 'audio') {
            draft.audio = currentMediaItem.label;
          } else {
            draft.video = currentMediaItem.label;
          }
        })
      );
    }
  }, [currentMediaItem, setDisplayValue, mediaType]);

  const onDeviceChange = useCallback(
    async (newDeviceId: string) => {
      localStorage.setItem(SELECTED_MEDIA_INPUT_KEY, newDeviceId);
      setStoredLocalMediaDeviceId(() => newDeviceId);
      const restartOption =
        mediaType === 'audio'
          ? {
              ...noiseCancellationOptions,
              deviceId: { exact: newDeviceId }
            }
          : {
              ...DEFAULT_VIDEO_CONSTRAINTS,
              deviceId: { exact: newDeviceId }
            };
      await localMediaTrack?.restart(restartOption);
    },
    [mediaType, SELECTED_MEDIA_INPUT_KEY, localMediaTrack]
  );

  return {
    storedLocalMediaDeviceId,
    currentMediaTrackValue,
    displayValue,
    mediaTrackSelectOptions,
    onDeviceChange
  };
};

export default useMediaDevices;
