import clsx from 'clsx';
import { ReactNode, useCallback, useState } from 'react';
import { Participant } from 'twilio-video';
import Icon from '~components/@ui/icon';
import useMainParticipant from '~twilio/hooks/use-main-participant';
import useScreenShareParticipant from '~twilio/hooks/use-screen-share-participant';
import useVideoContext from '~twilio/hooks/use-video-context';

import { ParticipantTracks } from './participant-tracks';
import useSelectedParticipant from './video-provider/use-selected-participant';

let lockAlertDisplayKey = 0;

interface LockAlertDisplayProps {
  paused: boolean;
}
const LockAlertDisplay = ({ paused }: Readonly<LockAlertDisplayProps>) => {
  return (
    <div className='pointer-events-none absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center'>
      {paused ? (
        <div className='animate-fade-in-out flex h-[60px] flex-row items-center justify-center gap-[12px] rounded-[36px] bg-black bg-opacity-60 px-[32px] opacity-0'>
          <Icon name='lock' width={36} height={36} />
          <p className='text-white text-title-1'>Screen Locked</p>
        </div>
      ) : (
        <div className='animate-fade-in-out flex h-[60px] flex-row items-center justify-center gap-[12px] rounded-[36px] bg-black bg-opacity-60 px-[32px] opacity-0'>
          <Icon name='unlock' width={36} height={36} />
          <p className='text-white text-title-1'>Screen Unlocked</p>
        </div>
      )}
    </div>
  );
};
interface MainParticipantInfoProps {
  participant: Participant;
  children: ReactNode;
  onPress?: () => void;
}

const MainParticipantInfo = ({
  onPress,
  children
}: Readonly<MainParticipantInfoProps>) => {
  // const { room } = useVideoContext();
  // const localParticipant = room!.localParticipant;
  // const isLocal = localParticipant === participant;
  // const screenShareParticipant = useScreenShareParticipant();
  // const isRemoteParticipantScreenSharing =
  //   screenShareParticipant && screenShareParticipant !== localParticipant;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        'relative flex h-full w-full items-center justify-center overflow-hidden [&>video]:absolute'
      )}
      onClick={onPress}
    >
      {children}
    </div>
  );
};
MainParticipantInfo.displayName = 'MainParticipantInfo';

export const MainParticipant = () => {
  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const { localParticipant } = room!;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const [paused, setPaused] = useState(false);
  const onPress = useCallback(() => {
    setPaused((prev) => !prev);
    // eslint-disable-next-line no-plusplus
    lockAlertDisplayKey++;
  }, []);

  const videoPriority =
    (mainParticipant === selectedParticipant ||
      mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? 'high'
      : null;

  return (
    <MainParticipantInfo participant={mainParticipant} onPress={onPress}>
      {/* audio is disabled for this participant component because this
        participant's audio is already being rendered in the <ParticipantStrip />{' '}
        component. */}
      <LockAlertDisplay key={lockAlertDisplayKey} paused={paused} />
      <ParticipantTracks
        paused={paused}
        participant={mainParticipant}
        // videoOnly
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  );
};
