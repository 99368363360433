import { cn } from '~utils/tailwind';

type BadgeProps = Readonly<{
  value?: number;
}>;

const isDoubleDigit = (value?: number) => !!value && value >= 10;

/**
 * @version v1.0.2
 * @author Danny
 * @description min-width, min-height 수정
 */
const Badge = ({ value }: Readonly<BadgeProps>) => {
  return (
    <div
      className={cn(
        'flex h-fit w-fit items-center justify-center rounded-[50%] bg-red-600 text-white text-label-3',
        typeof value === 'number'
          ? 'min-h-[16px] min-w-[16px]'
          : 'min-h-[8px] min-w-[8px]',
        isDoubleDigit(value) && 'rounded-[100px] px-1'
      )}
    >
      {value && value >= 100 ? '99+' : value}
    </div>
  );
};

export default Badge;
