import { ComponentProps } from 'react';
import { cn } from '~utils/tailwind';

import Icon from '../icon';

type Props = Readonly<
  ComponentProps<'button'> & {
    onClose: () => void;
  }
>;

const DialogCloseIcon = ({ className, onClose }: Props) => {
  return (
    <button
      type='button'
      aria-label='close'
      className={cn('absolute right-4 top-4 p-2', className)}
      onClick={onClose}
    >
      <Icon name='close' className='h-6 w-6 fill-gray-900' />
    </button>
  );
};

export default DialogCloseIcon;
