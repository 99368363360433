import LocalAudioLevelIndicator from './local-audio-level-indicator';
import { LocalVideo } from './local-video';
import { LocalVideoInfo } from './local-video-info';

export const LocalVideoPreview = () => {
  return (
    <LocalVideoInfo>
      <LocalVideo />
      <div className='absolute right-6 top-6'>
        <LocalAudioLevelIndicator />
      </div>
    </LocalVideoInfo>
  );
};
